import { Paragraph } from "@frontend/lyng/typography";
import { useObjectRef } from "@react-aria/utils";
import { useNumberFormatter } from "../../../utils/hooks/useNumberFormatter";
import classNames from "classnames";
import { AriaAttributes } from "react";
import { AriaNumberFieldProps, useLocale, useNumberField } from "react-aria";
import {
  FieldValues,
  UseControllerProps,
  useController,
} from "react-hook-form";
import { useNumberFieldState } from "react-stately";

type Props<FormValues extends FieldValues> = UseControllerProps<FormValues> &
  AriaAttributes & {
    id?: string;
    placeholder?: string;
    editView?: boolean;
    alignRight?: boolean;
  };

export function CurrencyInput<FormValues extends FieldValues>({
  id,
  name,
  placeholder,
  control,
  editView = true,
  alignRight,
  ...ariaProps
}: Props<FormValues>) {
  const { locale } = useLocale();
  const { currencySymbol, currencySide } = useNumberFormatter();

  const {
    field,
    fieldState: { invalid },
  } = useController({
    name,
    control,
  });
  const { ref, value, ...rest } = field;

  const innerProps: AriaNumberFieldProps = {
    ...rest,
    ...ariaProps,
    id,
    isReadOnly: !editView,
    isInvalid: invalid,
    value: value ?? undefined,
    placeholder,
  };

  const objectRef = useObjectRef(ref);

  const state = useNumberFieldState({ ...innerProps, locale });
  const { groupProps, inputProps } = useNumberField(
    innerProps,
    state,
    objectRef
  );

  return (
    <div
      {...groupProps}
      className={classNames("sm:mx-w-xs relative mx-1 max-w-lg rounded-xl", {
        "shadow-sm": editView,
      })}
    >
      {currencySymbol && currencySide === "left" && (
        <div className="lefe-0 pointer-events-none absolute inset-y-0 flex items-center pl-4">
          <Paragraph size="m">{currencySymbol}</Paragraph>
        </div>
      )}
      <input
        {...inputProps}
        ref={objectRef}
        type="text"
        className={classNames(
          "flex h-12 w-full items-center rounded-xl text-lg placeholder:text-gray-400",
          {
            "border-gray-300 focus:border-primary-500 focus:ring-primary-500":
              editView && !invalid,
            "border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500":
              editView && invalid,
            "hover:border:transparent border-transparent bg-transparent":
              !editView,
            "text-right": !!alignRight,
            "pl-4 pr-11": currencySymbol && currencySide === "right",
            "pl-11 pr-4": currencySymbol && currencySide === "left",
            "px-4": !currencySymbol,
          }
        )}
      />
      {currencySymbol && currencySide === "right" && (
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
          <Paragraph size="m">{currencySymbol}</Paragraph>
        </div>
      )}
    </div>
  );
}
