import { FormGroup, Input, Select } from "@frontend/lyng/forms";
import { Label, RoleType } from "../../../../api/generated/graphql";
import { Form } from "../../../../components/formfields";

import { useCareContext } from "../../../../providers/CareProvider";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { useTranslate } from "@tolgee/react";
import { z } from "zod";
import { Button } from "@frontend/lyng/button";
import { useMemo } from "react";
import { useSortingOptions } from "../../../../utils/hooks/useSortingOptions";

const validationSchema = z.object({
  name: z
    .string({
      required_error: "labels.validation.nameRequired",
    })
    .min(1, { message: "labels.validation.nameRequired" }),
  officeId: z
    .string({
      required_error: "labels.validation.officeRequired",
    })
    .min(1),
});

export const officeOptionSchema = z.object({
  id: z.string(),
  name: z.string(),
});
export type OfficeOption = z.infer<typeof officeOptionSchema>;

type FormInput = z.infer<typeof validationSchema>;
export type FormOutput = {
  name: string;
  officeId: string;
};

const allowedOfficeRoles = [
  RoleType.Admin,
  RoleType.SuccessManager,
  RoleType.SchedulingManager,
];

type Props = {
  label?: Label;
  onSubmit: (data: FormOutput) => Promise<void>;
  onDelete?: (id: string) => void;
};
export const LabelForm = ({ label, onSubmit, onDelete }: Props) => {
  const { t } = useTranslate();
  const { collator } = useSortingOptions();

  const {
    state: { viewer },
  } = useCareContext();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormInput>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      name: label && label?.name,
      officeId: label && label.officeId,
    },
  });

  const options: OfficeOption[] = useMemo(() => {
    return (
      viewer?.tenantAccess.offices.filter((office) => {
        return office.roles.some((role) =>
          allowedOfficeRoles.includes(role.roleType),
        );
      }) || []
    );
  }, [viewer]);

  const sortedOptions = [...(options ?? [])].sort((a, b) =>
    collator.compare(a.name, b.name),
  );

  const submitHandler = async (data: FormInput) => {
    const output: FormOutput = {
      name: data.name,
      officeId: data.officeId,
    };

    await onSubmit(output);
  };

  return (
    <div className="h-full">
      <Form onSubmit={handleSubmit(submitHandler)} className="h-full">
        <div className="ml-8 h-full">
          <FormGroup label={t("labels.name")}>
            <Input
              {...register("name")}
              id="name"
              type="text"
              placeholder={t("name") || ""}
              errorMessage={errors.name && t(errors.name.message ?? "")}
            />
          </FormGroup>

          <Controller
            name="officeId"
            control={control}
            render={({ field }) => (
              <FormGroup label={t("labels.office")}>
                <Select
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isMulti={false}
                  options={sortedOptions}
                  placeholder={t("placeholder.select")}
                  isDisabled={!!label}
                  displayView={!!label}
                  value={options.find((option) => option.id === field.value)}
                  onChange={(newValue) => {
                    field.onChange(newValue ? newValue.id : "");
                  }}
                  id="officeId"
                  errorMessage={
                    errors.officeId && t(errors.officeId.message ?? "")
                  }
                />
              </FormGroup>
            )}
          />
        </div>
        <Form.StickyFooter>
          {label && onDelete && (
            <Button
              variant="critical"
              text={t("delete") ?? ""}
              onClick={() => onDelete(label.id)}
            />
          )}
          <Button
            className="ml-auto"
            variant="primary"
            text={t("save") ?? ""}
            type="submit"
            disabled={isSubmitting}
            loading={isSubmitting}
          />
        </Form.StickyFooter>
      </Form>
    </div>
  );
};
