import {
  AccessLogsQuery,
  ResourceType,
  RoleType,
  useAccessLogsQuery,
} from "../../../api/generated/graphql";
import { Button, Table } from "../../../components/common";
import { Headline, HeadlineContainer } from "@frontend/lyng/typography";
import { useCareContext } from "../../../providers/CareProvider";
import { ColumnDef } from "@tanstack/react-table";
import { useDateFormatter } from "../../../utils/dateUtils";
import { useSortingOptions } from "../../../utils/hooks/useSortingOptions";
import { useCallback, useMemo } from "react";
import { useTranslate } from "@tolgee/react";

type AccessLogRow = AccessLogsQuery["accessLogs"]["logs"][number];

export const AccessLogs = () => {
  const { t } = useTranslate();
  const {
    state: { viewer },
  } = useCareContext();
  const { formatDate, formatTime } = useDateFormatter();
  const { nameOrderFn } = useSortingOptions();

  const { data, previousData, loading, error, fetchMore } = useAccessLogsQuery({
    variables: {
      cursor: null,
      limit: 100,
    },
    notifyOnNetworkStatusChange: true,
  });

  const fetchMoreLogs = useCallback(() => {
    if (
      !loading &&
      !!data?.accessLogs.cursor &&
      data?.accessLogs.cursor !== previousData?.accessLogs.cursor
    ) {
      fetchMore({
        variables: {
          cursor: data.accessLogs.cursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            ...prev,
            accessLogs: {
              cursor: fetchMoreResult.accessLogs.cursor,
              logs: [
                ...prev.accessLogs.logs,
                ...fetchMoreResult.accessLogs.logs,
              ],
            },
          };
        },
      });
    }
  }, [
    data?.accessLogs.cursor,
    fetchMore,
    loading,
    previousData?.accessLogs.cursor,
  ]);

  const isAdmin = viewer?.roles.some(
    ({ deactivateAt, roleType, resourceType }) =>
      !deactivateAt &&
      roleType === RoleType.Admin &&
      resourceType === ResourceType.Tenant,
  );

  const columns = useMemo<ColumnDef<AccessLogRow>[]>(() => {
    return [
      {
        id: "message",
        header: t("accessLogs.message") ?? "",
        accessorFn: (row) => row.message,
        enableSorting: false,
      },
      {
        id: "user",
        header: t("accessLogs.user") ?? "",
        accessorFn: (row) => nameOrderFn(row.user),
        enableSorting: false,
      },
      {
        header: t("accessLogs.timestamp") ?? "",
        accessorFn: (row) =>
          `${formatDate(row.timestamp)} - ${formatTime(row.timestamp)}`,
      },
    ];
  }, [formatDate, formatTime, nameOrderFn, t]);

  if (!isAdmin) return <div />;

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div className="p-5 md:p-0">
      <HeadlineContainer>
        <Headline size="l">{t("accessLogs.header")}</Headline>
      </HeadlineContainer>

      <div className="flex flex-col">
        <Table
          columns={columns}
          data={data?.accessLogs.logs ?? []}
          loading={loading}
        />
        <Button
          className="mt-4 max-w-sm self-center"
          variant="primary"
          onClick={() => fetchMoreLogs()}
          disabled={loading || !data?.accessLogs.cursor}
          text={t("accessLogs.loadMore") ?? ""}
        />
      </div>
    </div>
  );
};
