import { Select } from "../../../components/formfields";
import currencies from "./currencies.json";
import { useMemo } from "react";
import { useTranslate } from "@tolgee/react";

type Props = {
  value: string | null;
  name?: string | undefined;
  onChange: (value: string | null) => void;
  onBlur: () => void;
  disabled?: boolean;
  "aria-labelledby"?: string;
};

export const CurrencySelect = ({
  value,
  name,
  onChange,
  onBlur,
  disabled,
  "aria-labelledby": ariaLabelledBy,
}: Props) => {
  const { t } = useTranslate();
  const options = useMemo(() => {
    return currencies.map((c) => ({
      value: c.cc,
      label: `${c.symbol} - ${c.name}`,
    }));
  }, []);

  const selectedOption = useMemo(() => {
    return value ? options.find((option) => option.value === value) : null;
  }, [value, options]);

  return (
    <Select
      name={name}
      aria-label={t("placeholder.select").toString()}
      placeholder={t("placeholder.select")}
      options={options}
      value={selectedOption}
      isMulti={false}
      isClearable={true}
      onChange={(value) => onChange(value?.value || null)}
      onBlur={onBlur}
      isDisabled={disabled}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.value}
      dataTestId="currency-select"
      aria-labelledby={ariaLabelledBy}
    />
  );
};
