import { useCallback } from "react";
import {
  useCareTeamMemberCreateMutation,
  useCareTeamMemberUpdateMutation,
  useCareTeamMemberDeactivateMutation,
  CareTeamMemberCreateInput,
  CareTeamMemberUpdateInput,
  CareTeamMemberDeactivateInput,
  CareTeamMemberRoleType,
} from "../../../api/generated/graphql";
import { UseTranslateResult } from "@tolgee/react";
import { match } from "ts-pattern";

export type UsersFilterTabs =
  | "all"
  | "contacts"
  | "caregivers"
  | "office-users"
  | "no-role";

export const useTeamMemberMutations = () => {
  const [createTeamMember, { loading: createLoading }] =
    useCareTeamMemberCreateMutation({
      refetchQueries: ["CareTeamMembers"],
    });
  const create = (input: CareTeamMemberCreateInput) =>
    createTeamMember({
      variables: { input },
    });

  const [updateTeamMember, { loading: updateLoading }] =
    useCareTeamMemberUpdateMutation({
      refetchQueries: ["CareTeamMembers"],
    });
  const update = (id: string, input: CareTeamMemberUpdateInput) =>
    updateTeamMember({
      variables: { id, input },
    });

  const [deactivateTeamMember] = useCareTeamMemberDeactivateMutation({
    refetchQueries: ["CareTeamMembers"],
  });
  const deactivate = (id: string, input: CareTeamMemberDeactivateInput) =>
    deactivateTeamMember({
      variables: { id, input },
    });

  return {
    create,
    createLoading,
    update,
    updateLoading,
    deactivate,
  };
};

export const useTranslateCareTeamMemberRole = (t: UseTranslateResult["t"]) => {
  return useCallback(
    (roleType: CareTeamMemberRoleType) => {
      return match(roleType)
        .with(CareTeamMemberRoleType.SchedulingManager, () =>
          t("teamMembers.role.SCHEDULING_MANAGER"),
        )
        .with(CareTeamMemberRoleType.SuccessManager, () =>
          t("teamMembers.role.SUCCESS_MANAGER"),
        )
        .with(CareTeamMemberRoleType.Admin, () => t("teamMembers.role.ADMIN"))
        .exhaustive();
    },
    [t],
  );
};
