import { useMemo, useState } from "react";
import { z } from "zod";
import { Select } from "../select/select";
import { useTranslate } from "@tolgee/react";
import { useSortingOptions } from "../../../utils/hooks/useSortingOptions";
import { SelectGroupLabel } from "../../../components/common";

import {
  useCaregiverAvailabilitiesByOfficeIdsQuery,
  VisitorAvailabilityInput,
  VisitorAvailability,
  NameOrder,
} from "../../../api/generated/graphql";

import { RecursivePartial } from "../../../utils/typeUtils";
import { Link, Paragraph } from "@frontend/lyng/typography";
import Modal from "@frontend/lyng/modal/Modal";
import { Button } from "@frontend/lyng/button";

export const optionSchema = z.object({
  id: z.string(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  isDisabled: z.boolean().optional(),
  conflictFirstVisit: z.boolean().optional(),
  conflictInSeries: z.boolean().optional(),
});
export type Option = z.infer<typeof optionSchema>;

type Props = {
  value: Option | null;
  name?: string | undefined;
  onChange: (value: Option | null) => void;
  onBlur: () => void;
  input: VisitorAvailabilityInput;
  officeId: string;
  editView?: boolean;
};

type Availability = RecursivePartial<VisitorAvailability>;

const sortAvailabilityArraysByName = (
  array: Option[],
  nameOrder: NameOrder,
  collator: Intl.Collator,
) => {
  return array.slice().sort((a: Option, b: Option) => {
    const nameA = getName(a, nameOrder);
    const nameB = getName(b, nameOrder);
    if (nameA !== null && nameB !== null) {
      return collator.compare(nameA, nameB);
    } else return 0;
  });
};

const getName = (name: Option, nameOrder: NameOrder): string | null => {
  if (!name.firstName || !name.lastName) return null;
  return nameOrder === "FIRST_LAST"
    ? name.firstName.toLowerCase()
    : name.lastName.toLocaleLowerCase();
};

const CaregiverSelectGrouped = ({
  value,
  name,
  onChange,
  onBlur,
  input,
  officeId,
  editView = true,
}: Props) => {
  const { t } = useTranslate();
  const { nameOrderFn, nameOrder, collator } = useSortingOptions();

  const [conflictModal, setConflictModal] = useState<Option | null>(null);

  const { data, error, loading } = useCaregiverAvailabilitiesByOfficeIdsQuery({
    variables: {
      input: {
        durationMinutes: input.durationMinutes,
        start: input.start,
        end: input.end,
        rRule: input.rRule,
      },
      officeIds: [officeId],
    },
  });

  const getOption = (availability: Availability): Option => ({
    id: availability?.visitor?.id ?? "",
    firstName: availability?.visitor?.firstName ?? "",
    lastName: availability?.visitor?.lastName ?? "",
  });

  const options = useMemo(() => {
    const availabilities: Availability[] =
      data?.caregiverAvailabilitiesByOfficeIds || [];
    if (!availabilities) return [];

    const all: Option[] = [];
    const first: Option[] = [];
    const conflictFirst: Option[] = [];
    const conflictInSeries: Option[] = [];
    const none: Option[] = [];

    availabilities.forEach((availability) => {
      if (availability.conflictFirstVisit) {
        return conflictFirst.push({
          ...getOption(availability),
          conflictFirstVisit: true,
        });
      }

      if (availability.conflictInSeries) {
        return conflictInSeries.push({
          ...getOption(availability),
          conflictInSeries: true,
        });
      }

      if (!availability.firstVisit && !availability.allVisits) {
        return none.push({ ...getOption(availability), isDisabled: true });
      }

      if (availability.allVisits && input.rRule) {
        return all.push(getOption(availability));
      }

      if (availability.firstVisit) first.push(getOption(availability));
    });

    const ret = [];
    if (all.length)
      ret.push({
        label: t("caregiverSelect.availableSeries") ?? "",
        options: all,
      });
    if (first.length)
      ret.push({
        label: t("caregiverSelect.availableThisVisit") ?? "",
        options: first,
      });
    if (conflictFirst.length)
      ret.push({
        label: t("caregiverSelect.conflictFirstVisit") ?? "",
        options: conflictFirst,
      });
    if (conflictInSeries.length)
      ret.push({
        label: t("caregiverSelect.conflictInSeries") ?? "",
        options: conflictInSeries,
      });
    if (none.length)
      ret.push({
        label: t("caregiverSelect.notAvailable") ?? "",
        disabled: true,
        options: none,
      });

    if (ret.length > 0) {
      return ret.map((item) => {
        item.options = sortAvailabilityArraysByName(
          item.options,
          nameOrder,
          collator,
        );
        return item;
      });
    }
    return ret;
  }, [data, t, input.rRule, nameOrder, collator]);

  const handleChange = (option: Option | null) => {
    onChange(option);
    if (option?.conflictFirstVisit || option?.conflictInSeries) {
      setConflictModal(option);
    }
  };

  if (error) <p>{error.message}</p>;

  if (!editView) {
    return (
      <div className="flex h-12 items-center px-5">
        {value ? (
          <Link size="m" to={`/caregivers/${value.id}`}>
            {`${value.firstName} ${value.lastName}`}
          </Link>
        ) : (
          <Paragraph size="m">-</Paragraph>
        )}
      </div>
    );
  }

  return (
    <>
      <div
        data-testid="caregiver-selector"
        className="relative mt-1 max-w-lg rounded-md shadow-sm sm:max-w-xs flex-grow"
      >
        <Select
          name={name}
          isLoading={loading}
          options={options}
          aria-label={t("placeholder.chooseCaregiver")}
          placeholder={t("placeholder.chooseCaregiver")}
          value={value}
          isMulti={false}
          isClearable={true}
          onChange={handleChange}
          onBlur={onBlur}
          getOptionLabel={(option) => nameOrderFn(option)}
          getOptionValue={(option) => option.id}
          formatGroupLabel={(data) => <SelectGroupLabel data={data} />}
        />
      </div>
      {conflictModal && (
        <Modal
          onClose={() => {
            onChange(null);
            setConflictModal(null);
          }}
          show={true}
        >
          <Modal.Title>
            {t("caregiverSelect.conflictModalTitle") ?? ""}
          </Modal.Title>
          <Paragraph>
            {conflictModal.conflictFirstVisit &&
              (t("caregiverSelect.conflictModalTextFirstVisit") ?? "")}
            {conflictModal.conflictInSeries &&
              (t("caregiverSelect.conflictModalTextInSeries") ?? "")}
          </Paragraph>
          <Modal.Footer>
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                onChange(null);
                setConflictModal(null);
              }}
              text={t("cancel") ?? ""}
            />
            <Button
              type="button"
              variant="primary"
              onClick={() => {
                setConflictModal(null);
              }}
              text={t("continue") ?? ""}
            />
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default CaregiverSelectGrouped;
