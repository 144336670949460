import { useEffect, useState } from "react";
import { useTranslate } from "@tolgee/react";
import { Checkbox } from "../../../components/formfields/checkbox/Checkbox";
import Label from "../../../components/formfields/label/Label";
import Button from "../button";
import Modal from "../modal/Modal";

export type Props = {
  show: boolean;
  onConfirmThisVisit: () => void;
  onConfirmFollowingVisits: () => void;
  loading: boolean;
  onCancel: () => void;
  recurrent?: boolean;
};
type Selection = "this-event" | "following-events";
export const ConfirmVisitDeleteModal = ({
  show,
  onCancel,
  onConfirmThisVisit,
  onConfirmFollowingVisits,
  loading,
  recurrent,
}: Props) => {
  const [selection, setSelection] = useState<Selection>("this-event");
  const { t } = useTranslate();

  const handleConfirm = async () => {
    if (selection === "this-event" || !recurrent) {
      onConfirmThisVisit();
    } else {
      onConfirmFollowingVisits();
    }
  };

  useEffect(() => {
    if (!show) {
      setSelection("this-event");
    }
  }, [show]);

  const options: { value: Selection; label: string }[] = [
    { value: "this-event", label: t("confirmVisitDeleteModal.this") },
    {
      value: "following-events",
      label: t("confirmVisitDeleteModal.following"),
    },
  ];

  const title = recurrent
    ? t("confirmVisitDeleteModal.recurrentTitle")
    : t("confirmVisitDeleteModal.singleTitle");

  return (
    <Modal show={show} onClose={onCancel}>
      <Modal.Title>{title}</Modal.Title>
      {recurrent && (
        <fieldset className="mt-4">
          <div className="space-y-4">
            {options.map((option) => (
              <div key={option.value} className="flex items-center gap-2">
                <Checkbox
                  id={option.value}
                  name="notification-method"
                  type="radio"
                  checked={option.value === selection}
                  onBlur={() => Promise.resolve()}
                  onChange={() => Promise.resolve(setSelection(option.value))}
                />
                <Label htmlFor={option.value}>{option.label}</Label>
              </div>
            ))}
          </div>
        </fieldset>
      )}
      <Modal.Footer>
        <Button
          variant="primary"
          text={t("ok").toString()}
          className="col-start-2"
          onClick={handleConfirm}
        />
        <Button
          variant="secondary"
          text={t("cancel").toString()}
          className="col-start-1"
          onClick={onCancel}
          disabled={loading}
        />
      </Modal.Footer>
    </Modal>
  );
};
