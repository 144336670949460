import { zodResolver } from "@hookform/resolvers/zod";
import { AnimatePresence, motion } from "framer-motion";
import { DateTime } from "luxon";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  Controller,
  DefaultValues,
  FieldNamesMarkedBoolean,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslate } from "@tolgee/react";
import { z } from "zod";

import {
  CancelledReason,
  Frequency,
  RRuleInput,
  ViewerQuery,
  VisitCreateInput,
  VisitLogCreateInput,
  VisitorAvailabilityInput,
  Weekday,
} from "../../api/generated/graphql";
import {
  Button,
  ConfirmVisitDeleteModal,
  UpdateRecurringModal,
} from "../../components/common";

import {
  CaregiverSelectGrouped,
  Checkbox,
  CheckboxGroup,
  Form,
  FormSection,
  FormTable,
  LabelsSelect,
  ValidationMessage,
  VisitRecurrencePicker,
  optionSchema as caregiverSelectOptionSchema,
} from "../../components/formfields";

import {
  dateTimeToZonedDateTime,
  datedayToWeekday,
  formatDuration,
  isAllWeekDays,
} from "@frontend/lyng/utils/dateUtils";
import { errorToToastMessage } from "../../utils/toastUtils";
import { PartialBy } from "../../utils/typeUtils";

import { Label, Link, Paragraph } from "@frontend/lyng/typography";
import { Headline } from "@frontend/lyng/typography/headline/Headline";
import { startOfWeek } from "@internationalized/date";
import { useLocale } from "@react-aria/i18n";
import { useLocation, useNavigate } from "react-router-dom";
import { P, match } from "ts-pattern";
import { VisitTypeSelect } from "../../components/formfields/visitTypeSelect/visitTypeSelect";
import { useCareContext } from "../../providers/CareProvider";
import { CancelVisitModal } from "./cancelVisitModal/CancelVisitModal";
import { CancelledVisitCard } from "./cancelledVisitCard/CancelledVisitCard";
import { useVisitMutations } from "./hooks";
import {
  VisitCreatedRecurrence,
  VisitDeletedRecurrence,
  VisitUpdatedSource,
  visitCancelled,
  visitCreated,
  visitDeleted,
  visitLogCreated,
  visitUpdated,
} from "../../typewriter/segment";
import {
  DatePicker,
  TimeInput,
  TimePicker,
  timeSchemaShape,
} from "@frontend/lyng/forms";
import { VisitOfficeSelect } from "../../components/common/visitOfficeSelect/visitOfficeSelect";

const ACCESS_LOGS_TENANT_IDS =
  import.meta.env.VITE_ACCESS_LOGS_TENANT_IDS ?? "";

export type CareRecipient = {
  id: string;
  firstName: string | null;
  lastName: string | null;
  careRecipientRoles: {
    office: {
      id: string;
      name: string;
    };
    deactivatedAt: string | null;
  }[];
};

export type VisitInstanceDetails = {
  id: string;
  officeId: string;
  careRecipient: CareRecipient;
  start: string;
  durationMinutes: number;
  visitors: { id: string; firstName: string | null; lastName: string | null }[];
  recurrences: {
    frequency: Frequency;
    weekdays: Weekday[] | null;
    weekStart: Weekday;
    interval: number | null;
  } | null;
  recurrenceEnd: string | null;
  exceptionId: string | null;
  residentialCare: boolean | null;
  visitType: { id: string } | null;
  labelIds: string[];
  cancelledAt: string | null;
  cancelledReason: CancelledReason | null;
};

const validationSchema = z
  .object({
    id: z.string().optional(),
    start: z.custom<DateTime>((value) => DateTime.isDateTime(value)),
    timeStart: z.object(timeSchemaShape, {
      invalid_type_error: "visitDetailsForm.validation.timeStart",
      required_error: "visitDetailsForm.validation.timeStart",
    }),
    timeEnd: z.object(timeSchemaShape, {
      invalid_type_error: "visitDetailsForm.validation.timeEnd",
      required_error: "visitDetailsForm.validation.timeEnd",
    }),
    visitor: caregiverSelectOptionSchema.nullable(),
    recurrences: z
      .object({
        frequency: z.nativeEnum(Frequency),
        weekdays: z.array(z.nativeEnum(Weekday)).nullable(),
        interval: z.number().nullable(),
        weekStart: z.nativeEnum(Weekday),
      })
      .nullable(),
    recurrenceEnd: z
      .custom<DateTime>((value) => DateTime.isDateTime(value))
      .nullable(),
    officeId: z.string(),
    residentialCare: z.boolean().nullish(),

    // Internal field for form validation
    isCancelled: z.boolean(),
    cancelledReason: z.nativeEnum(CancelledReason).nullable(),
    cancelledOnDate: z
      .custom<DateTime>(
        (value) => DateTime.isDateTime(value),
        "Invalid date  format.",
      )
      .nullable(),
    labelIds: z.array(z.string()).optional(),
    visitType: z
      .object({
        id: z.string(),
      })
      .nullable(),
  })
  .refine(
    (obj) =>
      !obj.recurrenceEnd || obj.recurrenceEnd >= obj.start.plus({ minutes: 1 }),
    {
      message: "visitDetailsForm.validation.endNotBeforeStartDate",
      path: ["recurrenceEnd"],
    },
  )
  .refine((obj) => !obj.isCancelled || !!obj.cancelledOnDate, {
    message: "visitDetailsForm.validation.cancelledOnDateRequired",
    path: ["cancelledOnDate"],
  })
  .refine((obj) => !obj.isCancelled || !!obj.cancelledReason, {
    message: "visitDetailsForm.validation.cancelledReasonRequired",
    path: ["cancelledReason"],
  });
type FormInput = z.infer<typeof validationSchema>;

const formRecurrencetoRRule = (
  recurrences: FormInput["recurrences"],
): RRuleInput | null => {
  return match(recurrences)
    .with(P.nullish, () => null)
    .with({ frequency: P.nullish }, () => null)
    .with({ frequency: Frequency.Daily }, (r) => ({
      frequency: Frequency.Daily,
      interval: recurrences?.interval ?? 0,
      weekStart: r.weekStart,
    }))
    .with({ frequency: Frequency.Weekly }, (r) => ({
      frequency: Frequency.Weekly,
      weekdays: r.weekdays,
      interval: recurrences?.interval ?? 0,
      weekStart: r.weekStart,
    }))
    .with({ frequency: Frequency.Monthly }, (r) => ({
      frequency: Frequency.Monthly,
      interval: recurrences?.interval ?? 0,
      weekStart: r.weekStart,
    }))
    .exhaustive();
};

// // the different type of responses to the Save button
type SaveAction =
  | "CREATE"
  | "CREATE_VISIT_LOG"
  | "UPDATE_SINGLE"
  | "UPDATE_FOLLOWING"
  | "UPDATE_MODAL"
  | "CANCEL";

export const getSaveAction = (
  input: FormInput,
  visit: {
    exceptionId?: string | null;
    frequency?: Frequency | null;
  },
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<FormInput>>>,
): SaveAction => {
  // if no id, create
  if (!input.id) {
    return input.start > DateTime.now() ? "CREATE" : "CREATE_VISIT_LOG";
  }

  if (input.isCancelled) {
    return "CANCEL";
  }

  // if visit does not repeat, update single
  if (!input.recurrences?.frequency && !dirtyFields.recurrences) {
    return "UPDATE_SINGLE";
  }
  // If a recurring visit is updated but not the recurrence rules, show confirmation modal
  if (
    input.recurrences?.frequency &&
    !dirtyFields.recurrences &&
    !dirtyFields.recurrenceEnd
  ) {
    return "UPDATE_MODAL";
  }

  // a recurring visit that is an exception
  if (visit.exceptionId && visit.frequency) {
    return "UPDATE_MODAL";
  }

  // a recurring visit that has changed recurrence rules
  return "UPDATE_FOLLOWING";
};

const getDuration = (start: TimeInput, end: TimeInput): number => {
  const dtStart = DateTime.fromObject(start);
  let dtEnd = DateTime.fromObject(end);
  if (dtEnd < dtStart) {
    dtEnd = dtEnd.plus({ days: 1 });
  }

  return dtEnd.diff(dtStart, "minutes").minutes;
};

type availableOfficesProps = {
  options: {
    office: {
      id: string;
      name: string;
    };
    deactivatedAt: string | null;
  }[];
  viewer: ViewerQuery["viewer"] | undefined;
};
const getAllAvailableOffices = ({ options, viewer }: availableOfficesProps) => {
  const AllowedRoles = ["ADMIN", "SUCCESS_MANAGER", "SCHEDULING_MANAGER"];
  const viewerAvailableOffices = viewer?.tenantAccess.offices.filter((office) =>
    office.roles.some((office) => AllowedRoles.includes(office.roleType)),
  );
  const available = options.filter(
    (office) =>
      !office.deactivatedAt &&
      viewerAvailableOffices?.some(
        (viewerOffice) => viewerOffice.id === office.office.id,
      ),
  );
  return available;
};

const defaultValues = (
  visit:
    | VisitInstanceDetails
    | PartialBy<VisitInstanceDetails, "id" | "officeId">,
  defaultOfficeId: string | undefined,
): DefaultValues<FormInput> => ({
  id: visit.id,
  start: DateTime.fromISO(visit.start),
  timeStart: (({ hour, minute }) => ({ hour, minute }))(
    DateTime.fromISO(visit.start).toObject(),
  ),
  timeEnd: (({ hour, minute }) => ({ hour, minute }))(
    DateTime.fromISO(visit.start)
      .plus({ minutes: visit.durationMinutes })
      .toObject(),
  ),
  labelIds: visit.labelIds ?? [],
  visitor: visit.visitors[0] ?? null,
  recurrences: visit.recurrences,
  recurrenceEnd: visit.recurrenceEnd
    ? DateTime.fromISO(visit.recurrenceEnd)
    : null,
  officeId: visit.officeId || defaultOfficeId,
  isCancelled: !!visit.cancelledAt, // internal field for form validation, not displayed and does not change
  cancelledReason: visit.cancelledReason,
  cancelledOnDate: visit.cancelledAt
    ? DateTime.fromISO(visit.cancelledAt)
    : null,
  visitType: visit.visitType ?? null,
});

type Props = {
  visit:
    | VisitInstanceDetails
    | PartialBy<VisitInstanceDetails, "id" | "officeId">;
  onClose: () => void;
};
export const VisitDetailForm = ({ visit, onClose }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    state: { viewer },
  } = useCareContext();
  const { locale } = useLocale();

  const timezone = viewer?.tenantSettings.timezone ?? "UTC";
  const now = DateTime.now().setZone(timezone);
  const weekStart = startOfWeek(dateTimeToZonedDateTime(now), locale);

  const availabeOffices = useMemo(
    () =>
      getAllAvailableOffices({
        viewer: viewer,
        options: visit.careRecipient.careRecipientRoles,
      }),
    [viewer, visit.careRecipient.careRecipientRoles],
  );

  const formMethods = useForm<FormInput>({
    resolver: zodResolver(validationSchema),
    defaultValues: defaultValues(visit, availabeOffices.at(0)?.office.id),
  });
  const {
    control,
    register,
    setValue,
    watch,
    reset,
    trigger,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = formMethods;

  useEffect(() => {
    reset(defaultValues(visit, availabeOffices.at(0)?.office.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visit, reset]);

  const watchOfficeId = watch("officeId");
  const watchStart = watch("start") as DateTime | null;
  const watchRecurrences = watch("recurrences");
  const watchTimeStart = watch("timeStart") as TimeInput | null;
  const watchTimeEnd = watch("timeEnd") as TimeInput | null;
  const watchRecurrenceEnd = watch("recurrenceEnd");
  const watchStartWithTime = watchStart?.set({
    hour: watchTimeStart?.hour,
    minute: watchTimeStart?.minute,
  });

  const prevStart = useRef(watchStart);
  const prevRecurrenceEnd = useRef(watchRecurrenceEnd);

  useEffect(() => {
    if (prevStart.current !== watchStart) {
      if (
        watchStart &&
        watchRecurrenceEnd &&
        watchStart >= watchRecurrenceEnd
      ) {
        setValue("recurrenceEnd", watchStart.plus({ minutes: 1 }), {
          shouldValidate: false,
        });
        trigger("recurrenceEnd");
      }
      prevStart.current = watchStart;
    }

    if (prevRecurrenceEnd.current !== watchRecurrenceEnd) {
      if (
        watchRecurrenceEnd &&
        watchStart &&
        watchRecurrenceEnd <= watchStart
      ) {
        setValue("start", watchRecurrenceEnd.minus({ minutes: 1 }), {
          shouldValidate: false,
        });
        trigger("start");
      }
      prevRecurrenceEnd.current = watchRecurrenceEnd;
    }
  }, [watchStart, watchRecurrenceEnd, setValue, trigger]);

  const { t } = useTranslate();

  const groupSelectInput = useMemo<VisitorAvailabilityInput>(() => {
    const start =
      watchStart
        ?.set({
          hour: watchTimeStart?.hour,
          minute: watchTimeStart?.minute,
        })
        .toUTC()
        .toISO() ?? "";

    const rRule = formRecurrencetoRRule(watchRecurrences);

    return {
      start,
      rRule,
      durationMinutes:
        watchTimeStart && watchTimeEnd
          ? getDuration(watchTimeStart, watchTimeEnd)
          : 0,
      end: watchRecurrenceEnd?.toISO() || undefined,
    };
  }, [
    watchRecurrenceEnd,
    watchRecurrences,
    watchStart,
    watchTimeEnd,
    watchTimeStart,
  ]);

  useEffect(() => {
    if (!watchRecurrences?.frequency) {
      setValue("recurrenceEnd", null);
    }
  }, [watchRecurrences, setValue]);

  const [updateReccurringVisit, setUpdateRecurringVisit] = useState<
    VisitInstanceDetails | undefined
  >(undefined);

  const [showDeleteVisitModal, setShowDeleteVisitModal] = useState(false);
  const [showCancelVisitModal, setShowCancelVisitModal] = useState(false);

  const {
    visitCreateMutation,
    visitCreateLoading,

    visitLogCreateMutation,
    visitLogCreateLoading,

    visitUpdateMutation,
    visitUpdateLoading,

    visitUpdateFollowingMutation,
    visitUpdateFollowingLoading,
    visitUpdateFollowingReset,

    visitDeleteMutation,
    deleteVisitLoading,
    deleteVisitReset,

    visitCancelMutation,
    visitCancelLoading,
    visitCancelReset,

    visitReactivateMutation,
    visitReactivateLoading,
  } = useVisitMutations();

  const mutationLoading =
    visitCreateLoading ||
    visitLogCreateLoading ||
    visitUpdateLoading ||
    visitUpdateFollowingLoading ||
    deleteVisitLoading ||
    visitCancelLoading ||
    visitReactivateLoading;

  const handleUpdateThisVisit = (
    visitUpdate: Pick<
      VisitInstanceDetails,
      "id" | "durationMinutes" | "start" | "visitors" | "visitType" | "labelIds"
    >,
  ) => {
    const promise = visitUpdateMutation({
      variables: {
        input: {
          visitInstanceId: visitUpdate.id,
          durationMinutes: visitUpdate.durationMinutes,
          start: visitUpdate.start,
          visitorIds: visitUpdate.visitors.map((visitor) => visitor.id),
          visitTypeId: visitUpdate.visitType?.id,
          labelIds: visitUpdate.labelIds,
        },
      },
    }).then((res) => {
      reset({}, { keepValues: true });
      setUpdateRecurringVisit(undefined);

      visitUpdated({
        care_recipient_id: visit.careRecipient.id,
        recurrence: VisitDeletedRecurrence.This,
        source: VisitUpdatedSource.Form,
      });
      if (res.data?.visitUpdate) {
        navigate(
          {
            pathname: `../visits/${res.data?.visitUpdate.id}`,
            search: location.search,
          },
          {
            preventScrollReset: true,
          },
        );
      } else {
        onClose();
      }
    });
    return toast.promise(promise, {
      loading: t("visitDetailsForm.updating"),
      success: t("visitDetailsForm.successUpdate"),
      error: (err) => errorToToastMessage(err),
    });
  };

  const deleteVisit = (params: { deleteFollowing: boolean }) => {
    const { deleteFollowing } = params;
    const promise = visitDeleteMutation({
      variables: {
        deleteFollowing,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        visitId: visit.id!,
      },
    }).then(() => {
      onClose();
      visitDeleted({
        care_recipient_id: visit.careRecipient.id,
        recurrence: deleteFollowing
          ? VisitDeletedRecurrence.Following
          : VisitDeletedRecurrence.This,
      });
    });

    toast.promise(promise, {
      loading: t("visitDetailsForm.deleting"),
      success: t("visitDetailsForm.successDelete"),
      error: (err) => errorToToastMessage(err),
    });
  };

  const handleConfirmUpdateRecurringVisit = (
    visitUpdate: VisitInstanceDetails,
  ) => {
    const dtStart = DateTime.fromISO(visitUpdate.start);
    const recurrenceEnd = visitUpdate.recurrenceEnd
      ? DateTime.fromISO(visitUpdate.recurrenceEnd).endOf("day")
      : null;

    const promise = visitUpdateFollowingMutation({
      variables: {
        input: {
          visitInstanceId: visitUpdate.id,
          start: dtStart.toUTC().toISO() ?? "",
          end: recurrenceEnd?.toUTC()?.toISO(),
          durationMinutes: visitUpdate.durationMinutes,
          visitorIds: visitUpdate.visitors.map((visitor) => visitor.id),
          rRule: formRecurrencetoRRule(visitUpdate.recurrences),
          visitTypeId: visitUpdate.visitType?.id,
          labelIds: visitUpdate.labelIds,
        },
      },
    }).then((res) => {
      reset({}, { keepValues: true });
      setUpdateRecurringVisit(undefined);
      visitUpdated({
        care_recipient_id: visit.careRecipient.id,
        recurrence: VisitDeletedRecurrence.Following,
        source: VisitUpdatedSource.Form,
      });
      if (res.data?.visitUpdateFollowing) {
        const unixDate = dtStart.toMillis();
        navigate(
          {
            pathname: `../visits/${res.data?.visitUpdateFollowing.id}|${unixDate}`,
            search: location.search,
          },

          {
            preventScrollReset: true,
          },
        );
      }
    });

    return toast.promise(promise, {
      loading: t("visitDetailsForm.updating"),
      success: t("visitDetailsForm.successSave"),
      error: (err) => errorToToastMessage(err),
    });
  };

  const handleCreateVisit = (visitCreate: VisitCreateInput): Promise<void> => {
    const promise = visitCreateMutation({
      variables: {
        input: {
          ...visitCreate,
          rRule: visitCreate.rRule
            ? {
                frequency: visitCreate.rRule.frequency,
                interval: visitCreate.rRule.interval,
                weekdays: visitCreate.rRule.weekdays,
                weekStart: datedayToWeekday(weekStart.toDate().getDay()),
              }
            : null,
        },
      },
    }).then(({ data }) => {
      // We don't have the full visit instance id so we create it with the unix date
      const unixDate = DateTime.fromISO(visitCreate.start).toMillis();
      navigate(
        {
          pathname: `../visits/${data?.visitCreate.id}|${unixDate}`,
          search: location.search,
        },
        {
          preventScrollReset: true,
        },
      );

      const getRecurrence = () => {
        const { rRule } = visitCreate;

        if (!rRule) {
          return VisitCreatedRecurrence.DoesNotRepeat;
        }

        const { frequency, weekdays } = rRule;

        if (!frequency) {
          return VisitCreatedRecurrence.Custom;
        }

        if (frequency === Frequency.Daily) {
          return VisitCreatedRecurrence.Daily;
        }

        if (frequency === Frequency.Weekly) {
          if (!weekdays) {
            return VisitCreatedRecurrence.Weekly;
          }

          if (isAllWeekDays(weekdays)) {
            return VisitCreatedRecurrence.EveryWeekday;
          }
        }

        if (frequency === Frequency.Monthly) {
          return VisitCreatedRecurrence.Monthly;
        }

        return VisitCreatedRecurrence.Custom;
      };

      visitCreated({
        care_recipient_id: data?.visitCreate.careRecipientId ?? "",
        has_caregiver: visitCreate.visitorIds.length > 0,
        recurrence: getRecurrence(),
      });
    });

    return toast.promise(promise, {
      loading: t("visitDetailsForm.creating"),
      success: t("visitDetailsForm.successSave"),
      error: (err) => errorToToastMessage(err),
    });
  };
  const handleCreateVisitLog = (
    visitCreate: VisitLogCreateInput,
  ): Promise<void> => {
    const promise = visitLogCreateMutation({
      variables: {
        input: visitCreate,
      },
    }).then(({ data }) => {
      navigate(
        {
          pathname: `../visits/${data?.visitLogCreate.id}`,
          search: location.search,
        },
        {
          preventScrollReset: true,
        },
      );
      visitLogCreated({
        care_recipient_id: data?.visitLogCreate.careRecipientId ?? "",
      });
    });

    return toast.promise(promise, {
      loading: t("visitDetailsForm.creating"),
      success: t("visitDetailsForm.successSave"),
      error: (err) => errorToToastMessage(err),
    });
  };

  const handleCancelVisit = (values: {
    cancelledOnDate: DateTime;
    reason: CancelledReason;
  }) => {
    const promise = visitCancelMutation({
      variables: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        visitInstanceId: visit.id!,
        cancelledAt: values.cancelledOnDate.toUTC().toISO() ?? "",
        cancelledReason: values.reason,
      },
    }).then(({ data }) => {
      visitCancelled({
        reason: data?.visitCancel.cancelledReason ?? "",
      });
    });

    return toast.promise(promise, {
      loading: t("visitDetails.cancellingVisit"),
      success: t("visitDetails.successCancel"),
      error: (err) => errorToToastMessage(err),
    });
  };

  const handleReactivateVisit = (visitInstanceId: string) => {
    const promise = visitReactivateMutation({
      variables: {
        visitInstanceId,
      },
    });

    return toast.promise(promise, {
      loading: t("visitDetails.reactivatingVisit"),
      success: t("visitDetails.successReactivate"),
      error: (err) => errorToToastMessage(err),
    });
  };

  const onSubmit: SubmitHandler<FormInput> = async (input) => {
    const startWithTime = input.start
      .set({
        hour: input.timeStart.hour,
        minute: input.timeStart.minute,
      })
      .toUTC();

    input.start = startWithTime;
    const saveAction = getSaveAction(input, visit, dirtyFields);

    match(saveAction)
      .with("CREATE", () =>
        handleCreateVisit({
          officeId: input.officeId,
          careRecipientId: visit.careRecipient.id,
          durationMinutes: getDuration(input.timeStart, input.timeEnd),
          start: startWithTime.toISO() ?? "",
          visitorIds: input.visitor ? [input.visitor.id] : [],
          rRule: formRecurrencetoRRule(input.recurrences),
          labelIds: input.labelIds,
          activityIds: [],
          end: input.recurrenceEnd ? input.recurrenceEnd.toUTC().toISO() : null,
          residentialCare: input.residentialCare,
          visitTypeId: input.visitType?.id,
        }),
      )
      .with("CREATE_VISIT_LOG", () =>
        handleCreateVisitLog({
          careRecipientId: visit.careRecipient.id,
          officeId: input.officeId,
          durationMinutes: getDuration(input.timeStart, input.timeEnd),
          startDate: startWithTime.toISO() ?? "",
          visitorIds: input.visitor ? [input.visitor.id] : [],
          activityIds: [],
          labelIds: input.labelIds,
          visitTypeId: input.visitType?.id,
        }),
      )
      .with("UPDATE_SINGLE", () =>
        handleUpdateThisVisit({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: input.id!,
          start: startWithTime.toISO() ?? "",
          durationMinutes: getDuration(input.timeStart, input.timeEnd),
          visitors: input.visitor ? [input.visitor] : [],
          visitType: input.visitType,
          labelIds: input.labelIds ?? [],
        }),
      )
      .with("UPDATE_FOLLOWING", () =>
        handleConfirmUpdateRecurringVisit({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: input.id!,
          officeId: input.officeId,
          careRecipient: visit.careRecipient,
          start: startWithTime.toISO() ?? "",
          durationMinutes: getDuration(input.timeStart, input.timeEnd),
          visitors: input.visitor ? [input.visitor] : [],
          recurrences: input.recurrences,
          recurrenceEnd: input.recurrenceEnd
            ? input.recurrenceEnd.toUTC().toISO()
            : null,
          labelIds: input.labelIds ?? [],
          exceptionId: visit.exceptionId,
          residentialCare: visit.residentialCare,
          visitType: input.visitType,
          cancelledAt: input.cancelledOnDate?.toUTC().toISO() ?? null,
          cancelledReason: input.cancelledReason,
        }),
      )
      .with("UPDATE_MODAL", () =>
        setUpdateRecurringVisit({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: input.id!,
          officeId: input.officeId,
          careRecipient: visit.careRecipient,
          start: startWithTime.toISO() ?? "",
          durationMinutes: getDuration(input.timeStart, input.timeEnd),
          visitors: input.visitor ? [input.visitor] : [],
          recurrences: input.recurrences,
          recurrenceEnd: input.recurrenceEnd
            ? input.recurrenceEnd.toUTC().toISO()
            : null,
          exceptionId: visit.exceptionId,
          residentialCare: visit.residentialCare,
          visitType: input.visitType,
          labelIds: input.labelIds ?? [],
          cancelledAt: input.cancelledOnDate?.toUTC().toISO() ?? null,
          cancelledReason: input.cancelledReason,
        }),
      )
      .with(
        "CANCEL",
        () =>
          input.isCancelled &&
          handleCancelVisit({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            cancelledOnDate: input.cancelledOnDate!,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            reason: input.cancelledReason!,
          }),
      )
      .exhaustive();
  };

  const showRecurrencePicker =
    watchStartWithTime && watchStartWithTime > DateTime.now();
  const showRecurrenceEnd = watchRecurrences?.frequency && showRecurrencePicker;

  const animateHeightConfig = useMemo(
    () => ({
      initial: { height: 0, overflow: "hidden" },
      animate: { height: "auto", overflow: "visible" },
      exit: { height: 0, overflow: "hidden" },
    }),
    [],
  );

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)} data-testid="visitlog-form">
          {visit.cancelledAt && <CancelledVisitCard editView />}

          <FormSection>
            <FormSection.Label>{t("careRecipient")}</FormSection.Label>
            <div className="flex h-12 items-center px-5">
              <Link
                size="m"
                to={`/care-recipients/${visit.careRecipient.id}`}
              >{`${visit.careRecipient.firstName} ${visit.careRecipient.lastName}`}</Link>
            </div>

            <FormSection.Label htmlFor="office">
              {t("visitDetailsForm.office")}
            </FormSection.Label>
            <Controller
              control={control}
              name="officeId"
              render={({ field }) => (
                <VisitOfficeSelect
                  options={getAllAvailableOffices({
                    viewer: viewer,
                    options: visit.careRecipient.careRecipientRoles,
                  })}
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value);
                    setValue("visitor", null);
                    setValue("labelIds", undefined);
                  }}
                  onBlur={field.onBlur}
                  displayView={!!visit.id}
                />
              )}
            />

            <FormSection.Label htmlFor="visitor">
              {t("visitDetailsForm.assignedCaregiver")}
            </FormSection.Label>
            <Controller
              control={control}
              name="visitor"
              render={({ field }) => (
                <CaregiverSelectGrouped
                  name={field.name}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  value={field.value}
                  input={groupSelectInput}
                  officeId={watchOfficeId}
                  editView={!visit.cancelledAt}
                />
              )}
            />

            <FormSection.Label htmlFor="start">
              {t("visitDetailsForm.startingDate")}
            </FormSection.Label>
            <div>
              <Controller
                control={control}
                name="start"
                render={({ field }) => (
                  <DatePicker
                    dateSettings={viewer?.tenantSettings}
                    name={field.name}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    value={field.value}
                    isInvalid={!!errors.start}
                    aria-label={t("visitDetailsForm.startingDate").toString()}
                    editView={!visit.cancelledAt}
                  />
                )}
              />
              {errors.start && (
                <ValidationMessage>
                  {t(errors.start.message ?? "")}
                </ValidationMessage>
              )}
            </div>

            <AnimatePresence initial={false}>
              {showRecurrencePicker && (
                <motion.div key="recurrencesLabel" {...animateHeightConfig}>
                  <FormSection.Label htmlFor="recurrences">
                    {t("visitDetailsForm.recurrences")}
                  </FormSection.Label>
                </motion.div>
              )}
              {showRecurrencePicker && (
                <motion.div key="recurrences" {...animateHeightConfig}>
                  <Controller
                    control={control}
                    name="recurrences"
                    render={({ field }) => (
                      <VisitRecurrencePicker
                        // showRecurrencePicker is true, so watchStart is not null
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        baseDate={watchStart!.toJSDate()} // TODO convert to luxon
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        editView={!visit.cancelledAt}
                      />
                    )}
                  />
                </motion.div>
              )}
            </AnimatePresence>
            <AnimatePresence initial={visit.recurrences?.frequency === null}>
              {showRecurrenceEnd && (
                <motion.div
                  key="recurrenceEndFieldLabel"
                  {...animateHeightConfig}
                >
                  <FormSection.Label htmlFor="recurrenceEnd">
                    {t("visitDetailsForm.endDate")}
                  </FormSection.Label>
                </motion.div>
              )}
              {showRecurrenceEnd && (
                <motion.div key="recurrenceEndField" {...animateHeightConfig}>
                  <Controller
                    control={control}
                    name="recurrenceEnd"
                    render={({ field }) => (
                      <DatePicker
                        dateSettings={viewer?.tenantSettings}
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        minValue={watchStartWithTime}
                        isInvalid={!!errors.recurrenceEnd}
                        aria-label={t("visitDetailsForm.endDate").toString()}
                        editView={!visit.cancelledAt}
                      />
                    )}
                  />
                  {errors.recurrenceEnd && (
                    <ValidationMessage>
                      {t(errors.recurrenceEnd.message ?? "")}
                    </ValidationMessage>
                  )}
                </motion.div>
              )}
            </AnimatePresence>
            {viewer?.tenantSettings.enableLabels && (
              <>
                <FormSection.Label htmlFor="labels">
                  {t("visitDetailsForm.label")}
                </FormSection.Label>
                <Controller
                  control={control}
                  name="labelIds"
                  render={({ field }) => (
                    <LabelsSelect
                      value={field.value ?? []}
                      onChange={field.onChange}
                      officeId={watchOfficeId}
                      onBlur={field.onBlur}
                      displayView={!!visit.cancelledAt}
                    />
                  )}
                />
              </>
            )}

            {viewer?.tenantSettings.enableVisitBilling && (
              <>
                <FormSection.Label htmlFor="visitType">
                  {t("visitDetailsForm.visitType")}
                </FormSection.Label>
                <Controller
                  control={control}
                  name="visitType"
                  render={({ field }) => (
                    <VisitTypeSelect
                      name={field.name}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      value={field.value}
                      activeOn={watchStart?.toISO() ?? null}
                      editView={!visit.cancelledAt}
                    />
                  )}
                />
              </>
            )}

            {ACCESS_LOGS_TENANT_IDS.split(",").includes(
              viewer?.tenantAccess.id,
            ) &&
              watchStart &&
              watchStart > DateTime.now() && (
                <>
                  <FormSection.Label htmlFor="residentialCare">
                    {t("visitDetailsForm.residentialCare")}
                  </FormSection.Label>
                  <CheckboxGroup>
                    <Checkbox
                      {...register("residentialCare")}
                      id="residentialCare"
                      type="checkbox"
                      disabled={!!visit.id}
                      defaultChecked={!!visit.residentialCare}
                    />
                  </CheckboxGroup>
                </>
              )}
          </FormSection>

          <div className="px-4 pb-8 pt-6">
            <FormTable.Header gridCols="grid-cols-2">
              <Headline size="m" className="px-5">
                {t("visitDetailsForm.time")}
              </Headline>
              <Label size="m" className="justify-self-end px-5">
                {t("visitDetailsForm.scheduled")}
              </Label>
            </FormTable.Header>

            <FormTable.Body>
              <FormTable.Row gridCols="grid-cols-2">
                <FormTable.Cell>
                  <Label size="m" htmlFor="timeStart" className="mx-5">
                    {t("visitDetailsForm.timeStart")}
                  </Label>
                </FormTable.Cell>

                <FormTable.Cell justifyEnd>
                  <Controller
                    control={control}
                    name="timeStart"
                    render={({ field }) => (
                      <TimePicker
                        dateSettings={viewer?.tenantSettings}
                        className="grow"
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        invalid={!!errors.timeStart}
                        alignRight
                        editView={!visit.cancelledAt}
                      />
                    )}
                  />
                </FormTable.Cell>
              </FormTable.Row>

              <FormTable.Row gridCols="grid-cols-2">
                <FormTable.Cell>
                  <Label size="m" htmlFor="timeEnd" className="mx-5">
                    {t("visitDetailsForm.timeEnd")}
                  </Label>
                </FormTable.Cell>
                <FormTable.Cell justifyEnd>
                  <Controller
                    control={control}
                    name="timeEnd"
                    render={({ field }) => (
                      <TimePicker
                        dateSettings={viewer?.tenantSettings}
                        className="grow"
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        invalid={!!errors.timeEnd}
                        alignRight
                        editView={!visit.cancelledAt}
                      />
                    )}
                  />
                </FormTable.Cell>
              </FormTable.Row>

              <FormTable.Row gridCols="grid-cols-2">
                <FormTable.Cell>
                  <Label size="m" className="mx-5">
                    {t("duration")}
                  </Label>
                </FormTable.Cell>

                <FormTable.Cell justifyEnd>
                  <Paragraph size="m" className="px-5">
                    {watchTimeStart && watchTimeEnd
                      ? formatDuration(
                          getDuration(watchTimeStart, watchTimeEnd),
                          t,
                        )
                      : 0}
                  </Paragraph>
                </FormTable.Cell>
              </FormTable.Row>
            </FormTable.Body>
          </div>

          <Form.StickyFooter>
            {visit.id ? (
              <Button
                variant="secondary"
                text={t("visitDetailsForm.delete").toString()}
                disabled={mutationLoading}
                onClick={() => {
                  setShowDeleteVisitModal(true);
                }}
              />
            ) : (
              <div /> // placeholder to keep the buttons aligned
            )}
            <div className="flex gap-3">
              {visit.id && (
                <Button
                  variant="secondary"
                  text={t(
                    visit.cancelledAt
                      ? "visitDetails.reactivateVisit"
                      : "visitDetails.cancelVisit",
                  ).toString()}
                  onClick={() =>
                    visit.cancelledAt
                      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        handleReactivateVisit(visit.id!)
                      : setShowCancelVisitModal(true)
                  }
                  disabled={mutationLoading}
                />
              )}

              {!visit.id && visit.start < DateTime.now().toString() && (
                <p className="mt-3 text-sm text-gray-700">
                  {t("visitDetails.visitLogNotice")}
                </p>
              )}
              <Button
                className="ml-auto"
                variant="primary"
                text={t("save") ?? ""}
                type="submit"
                disabled={mutationLoading}
                loading={mutationLoading}
              />
            </div>
          </Form.StickyFooter>
        </form>
      </FormProvider>

      <UpdateRecurringModal
        show={!!updateReccurringVisit}
        onConfirmThisVisit={() => {
          updateReccurringVisit && handleUpdateThisVisit(updateReccurringVisit);
        }}
        onConfirmFollowingVisits={() => {
          updateReccurringVisit &&
            handleConfirmUpdateRecurringVisit(updateReccurringVisit);
        }}
        onCancel={() => {
          setUpdateRecurringVisit(undefined);
          visitUpdateFollowingReset();
        }}
        loading={mutationLoading}
      />
      <ConfirmVisitDeleteModal
        show={!!showDeleteVisitModal}
        recurrent={!!visit.recurrences?.frequency}
        onCancel={() => {
          setShowDeleteVisitModal(false);
          deleteVisitReset();
        }}
        onConfirmFollowingVisits={() => {
          deleteVisit({
            deleteFollowing: true,
          });
          setShowDeleteVisitModal(false);
        }}
        onConfirmThisVisit={() => {
          deleteVisit({
            deleteFollowing: false,
          });
          setShowDeleteVisitModal(false);
        }}
        loading={mutationLoading}
      />
      <CancelVisitModal
        show={showCancelVisitModal}
        onCancel={() => {
          setShowCancelVisitModal(false);
          visitCancelReset();
        }}
        onConfirm={(values) => {
          if (!visit.id) {
            return;
          }
          handleCancelVisit({
            cancelledOnDate: values.cancelledAt,
            reason: values.reason,
          }).then(() => {
            setShowCancelVisitModal(false);
          });
        }}
      />
    </>
  );
};
