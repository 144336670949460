import countries from "i18n-iso-countries";
import countriesEn from "i18n-iso-countries/langs/en.json";
import Select from "react-select";
import { useMemo } from "react";
import { useTranslate } from "@tolgee/react";

countries.registerLocale(countriesEn);

type Props = {
  id?: string;
  value: string | null;
  name?: string | undefined;
  onChange: (value: string | null) => void;
  onBlur: () => void;
  disabled?: boolean;
  "aria-labelledby"?: string;
};

export const CountrySelect = ({
  id,
  value,
  name,
  onChange,
  onBlur,
  disabled,
  "aria-labelledby": ariaLabelledBy,
}: Props) => {
  const { t } = useTranslate();
  const options = useMemo(() => {
    const codes = Object.keys(countries.getAlpha2Codes());
    return codes.map((code) => ({
      value: code,
      label: countries.getName(code, "en"),
    }));
  }, []);

  const selectedOption = useMemo(() => {
    return value ? options.find((option) => option.value === value) : null;
  }, [value, options]);

  return (
    <div
      data-testid="country-select"
      className="relative max-w-lg rounded-md shadow-sm sm:max-w-xs"
    >
      <Select
        id={id}
        name={name}
        placeholder={t("placeholder.select")}
        options={options}
        value={selectedOption}
        isMulti={false}
        isClearable={true}
        onChange={(value) => onChange(value?.value || null)}
        onBlur={onBlur}
        isDisabled={disabled}
        getOptionLabel={(option) => option.label ?? ""}
        getOptionValue={(option) => option.value}
        styles={{
          input: (base) => ({
            ...base,
            "input:focus": {
              boxShadow: "none",
            },
          }),
        }}
        aria-labelledby={ariaLabelledBy}
      />
    </div>
  );
};
