import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslate } from "@tolgee/react";

import { ColumnDef } from "@tanstack/react-table";

import {
  VisitTypesQuery,
  useTenantSettingsSetEnableMileageExpenseMutation,
  useTenantSettingsSetEnableVisitBillingMutation,
  useVisitTypesQuery,
} from "../../../../api/generated/graphql";

import { Button, Table } from "../../../../components/common";
import { Toggle } from "@frontend/lyng/forms";
import { Headline, HeadlineContainer } from "@frontend/lyng/typography";
import { useCareContext } from "../../../../providers/CareProvider";
import { useNumberFormatter } from "../../../../utils/hooks/useNumberFormatter";
import { useSortingOptions } from "../../../../utils/hooks/useSortingOptions";
import { errorToToastMessage } from "../../../../utils/toastUtils";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useTranslateBillingType } from "../../../../utils/translationUtils";

type VisitTypeRow = VisitTypesQuery["visitTypes"][number];

export const VisitTypes = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const {
    state: { viewer },
  } = useCareContext();
  const { collator } = useSortingOptions();
  const { formatCurrency } = useNumberFormatter();
  const translateBillingType = useTranslateBillingType(t);

  const { data, loading, error } = useVisitTypesQuery({
    skip: !viewer?.tenantSettings.enableVisitBilling,
    fetchPolicy: "no-cache",
  });

  const [visitBillingEnabled, setVisitBillingEnabled] = useState<boolean>(
    viewer?.tenantSettings.enableVisitBilling ?? false,
  );

  const [expensesEnabled, setExpensesEnabled] = useState<boolean>(
    viewer?.tenantSettings.enableMileageExpense ?? false,
  );

  const [enableVisitBillingMutation, { loading: enableVisitBillingLoading }] =
    useTenantSettingsSetEnableVisitBillingMutation({
      refetchQueries: ["Viewer"],
    });

  const handleEnableVisitBilling = useCallback(
    async (enabled: boolean) => {
      const promise = enableVisitBillingMutation({
        variables: {
          enabled,
        },
      }).catch((e) => {
        setVisitBillingEnabled(!enabled);
        throw e;
      });

      await toast.promise(promise, {
        loading: enabled
          ? t("visitTypes.enablingVisitTypes")
          : t("visitTypes.disablingVisitTypes"),
        success: enabled
          ? t("visitTypes.enabledVisitTypes")
          : t("visitTypes.disabledVisitTypes"),
        error: errorToToastMessage,
      });
    },
    [enableVisitBillingMutation, t],
  );

  const [
    enableMileageExpenseMutation,
    { loading: enableMileageExpenseLoading },
  ] = useTenantSettingsSetEnableMileageExpenseMutation({
    refetchQueries: ["Viewer"],
  });

  const handleEnableMileageExpense = useCallback(
    async (enabled: boolean) => {
      const promise = enableMileageExpenseMutation({
        variables: {
          enabled,
        },
      }).catch((e) => {
        setExpensesEnabled(!enabled);
        throw e;
      });

      await toast.promise(promise, {
        loading: enabled
          ? t("visitTypes.enablingMileageExpense")
          : t("visitTypes.disablingMileageExpense"),
        success: enabled
          ? t("visitTypes.enabledMileageExpense")
          : t("visitTypes.disabledMileageExpense"),
        error: errorToToastMessage,
      });
    },
    [enableMileageExpenseMutation, t],
  );

  useEffect(() => {
    if (viewer?.tenantSettings.enableVisitBilling === visitBillingEnabled) {
      return;
    }
    handleEnableVisitBilling(visitBillingEnabled);
  }, [
    handleEnableVisitBilling,
    viewer?.tenantSettings.enableVisitBilling,
    visitBillingEnabled,
  ]);

  useEffect(() => {
    if (viewer?.tenantSettings.enableMileageExpense === expensesEnabled) {
      return;
    }
    handleEnableMileageExpense(expensesEnabled);
  }, [
    handleEnableMileageExpense,
    viewer?.tenantSettings.enableMileageExpense,
    expensesEnabled,
  ]);

  const columns = useMemo<ColumnDef<VisitTypeRow>[]>(() => {
    return [
      {
        id: "title",
        header: t("visitTypes.title") ?? "",
        accessorFn: (row) => row.title,
        sortingFn: (a, b, cId) =>
          collator.compare(a.getValue(cId), b.getValue(cId)),
      },
      {
        id: "code",
        header: t("visitTypes.code") ?? "",
        accessorFn: (row) => row.code,
        sortingFn: (a, b, cId) =>
          collator.compare(a.getValue(cId), b.getValue(cId)),
      },
      {
        id: "billingType",
        header: t("visitTypes.billingType") ?? "",
        accessorFn: (row) => translateBillingType(row.billingType),
        sortingFn: (a, b, cId) =>
          collator.compare(a.getValue(cId), b.getValue(cId)),
      },
      {
        id: "baseRate",
        header: t("visitTypes.baseRate") ?? "",
        accessorFn: (row) => formatCurrency(parseFloat(row.baseRate)),
        sortingFn: (a, b) =>
          parseFloat(a.original.baseRate) - parseFloat(b.original.baseRate),
      },
      {
        id: "eveningRate",
        header: t("visitTypes.eveningRate") ?? "",
        accessorFn: (row) =>
          row.eveningRate ? formatCurrency(parseFloat(row.eveningRate)) : "-",
        sortingFn: (a, b) => {
          const aVal = a.original.eveningRate
            ? parseFloat(a.original.eveningRate)
            : 0;
          const bVal = b.original.eveningRate
            ? parseFloat(b.original.eveningRate)
            : 0;
          return aVal - bVal;
        },
      },
      {
        id: "nightRate",
        header: t("visitTypes.nightRate") ?? "",
        accessorFn: (row) =>
          row.nightRate ? formatCurrency(parseFloat(row.nightRate)) : "-",
        sortingFn: (a, b) => {
          const aVal = a.original.nightRate
            ? parseFloat(a.original.nightRate)
            : 0;
          const bVal = b.original.nightRate
            ? parseFloat(b.original.nightRate)
            : 0;
          return aVal - bVal;
        },
      },
      {
        id: "edit",
        header: "",
        accessorFn: (row) => row.id,
        enableSorting: false,
        cell: (row) => (
          <Link to={`visit-types/${row.row.original.id}`}>{t("edit")}</Link>
        ),
      },
    ];
  }, [collator, formatCurrency, t, translateBillingType]);

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div className="p-5 md:p-0">
      <HeadlineContainer>
        <Headline size="l">{t("visitTypes.header")}</Headline>
      </HeadlineContainer>
      <div className="mb-4 flex-row rounded-2xl bg-white p-7 shadow-md sm:flex-col">
        <Toggle
          checked={visitBillingEnabled}
          onToggle={() =>
            !enableVisitBillingLoading &&
            setVisitBillingEnabled(!visitBillingEnabled)
          }
          text={t("visitTypes.toggleDescription") ?? ""}
          size="s"
        />
        <div className="mt-4">
          <Toggle
            checked={expensesEnabled}
            onToggle={() =>
              !enableMileageExpenseLoading &&
              setExpensesEnabled(!expensesEnabled)
            }
            text={t("visitTypes.expensesToggleDescription") ?? ""}
            size="s"
          />
        </div>
      </div>
      {viewer?.tenantSettings.enableVisitBilling && (
        <>
          <HeadlineContainer>
            <Headline size="l">{t("visitTypes.subHeader")}</Headline>
            <Button
              className="ml-auto"
              variant="primary"
              text={t("visitTypes.addVisitType").toString()}
              onClick={() => {
                navigate("visit-types/new");
              }}
            />
          </HeadlineContainer>

          <Table
            columns={columns}
            data={data?.visitTypes ?? []}
            defaultSorting={[{ id: "title", desc: false }]}
            loading={loading}
          />
        </>
      )}
    </div>
  );
};
