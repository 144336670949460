import { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslate } from "@tolgee/react";
import { toast } from "react-hot-toast";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  CareTeamMemberRoleType,
  ResourceType,
  useCareTeamMembersQuery,
} from "../../../api/generated/graphql";
import { errorToToastMessage } from "../../../utils/toastUtils";
import { useCareContext } from "../../../providers/CareProvider";
import {
  Button,
  SelectGroupLabel,
  SimpleModal,
} from "../../../components/common";
import {
  Form,
  Input,
  ValidationMessage,
  Radios,
  FormSection,
  Select,
} from "../../../components/formfields";
import { useNavigate } from "react-router-dom";
import { useTeamMemberMutations, useTranslateCareTeamMemberRole } from "./hook";
import {
  flattenedOrganization,
  groupOrganizationUnits,
} from "../../../utils/organizationUtils";

const organizationUnit = z.object({
  id: z.string(),
  type: z.nativeEnum(ResourceType),
  label: z.string(),
});
const validationSchema = z
  .object({
    id: z.string().optional(),
    firstName: z.string().min(1, "First name is required"),
    lastName: z.string().min(1, "Last name is required"),
    phone: z.string().nullable(),
    homePhone: z.string().nullable(),
    birthDate: z.string().nullish(),
    pid: z.string().nullish(),
    email: z
      .string()
      .email({ message: "Invalid email address" })
      .min(1, "Email is required"),
    role: z.nativeEnum(CareTeamMemberRoleType).optional().nullable(),
    organizationUnit: organizationUnit.optional(),
  })
  .superRefine((input, context) => {
    // If id is not present we're in create mode
    if (!input.id) {
      if (!input.organizationUnit) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["organizationUnit"],
          message: "teamMembers.validation.missingOrganization",
        });
      }

      if (!input.role) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["role"],
          message: "teamMembers.validation.missingRole",
        });
      }
    }
  });
export type FormInput = z.infer<typeof validationSchema>;
export type Member = FormInput & {
  id?: string;
  deactivatedAt?: string | null;
  resourceId?: string | null;
};

type Props = {
  member?: Member;
  onSubmit: (data: Member) => Promise<void>;
};

const roleOptions: CareTeamMemberRoleType[] = Object.values(
  CareTeamMemberRoleType,
);

export const MemberForm = ({ member, onSubmit }: Props) => {
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const { t } = useTranslate();
  const { viewer } = useCareContext().state;
  const navigate = useNavigate();

  const { data, loading: careTeamMembersQueryLoading } =
    useCareTeamMembersQuery({});

  const organizationUnits: flattenedOrganization = useMemo(() => {
    return (data && groupOrganizationUnits(data.organization)) || [[], [], []];
  }, [data]);

  const { deactivate } = useTeamMemberMutations();

  const translateCareTaemMemberRole = useTranslateCareTeamMemberRole(t);

  const handleClose = () => navigate("..");

  const isUserCheckingThemselves = () => {
    return viewer?.id === member?.id;
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<FormInput>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      id: member?.id,
      firstName: member?.firstName,
      lastName: member?.lastName,
      phone: member?.phone,
      homePhone: member?.homePhone,
      birthDate: member?.birthDate,
      pid: member?.pid,
      email: member?.email,
      role: undefined,
      organizationUnit: undefined,
    },
  });

  const submitHandler = async (values: FormInput) => {
    const output: Member = {
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      homePhone: values.homePhone,
      birthDate: values.birthDate,
      pid: values.pid,
      email: values.email,
    };
    if (values.id) {
      output.id = values.id;
    } else {
      output.role = values.role;
      output.organizationUnit = values.organizationUnit;
      output.resourceId = values.organizationUnit?.id;
    }

    await onSubmit(output);
  };

  const unitOptions = useMemo(() => {
    const [divisions, regions, offices] = organizationUnits || [[], [], []];

    const flattenOptions = (
      options: { id: string; name: string }[],
      type: ResourceType,
    ) =>
      options.map((option) => ({
        id: option.id,
        type: type,
        label: option.name,
      }));

    return [
      {
        label: t("organization.label").toString(),
        options: flattenOptions(
          [data?.organization ?? { id: "", name: "" }],
          ResourceType.Tenant,
        ),
      },
      {
        label: t("division.label").toString(),
        options: flattenOptions(divisions, ResourceType.Division),
      },
      {
        label: t("region.label").toString(),
        options: flattenOptions(regions, ResourceType.Region),
      },
      {
        label: t("office.label").toString(),
        options: flattenOptions(offices, ResourceType.Office),
      },
    ].flat();
  }, [data?.organization, organizationUnits, t]);

  const handleDeactivate = () => {
    if (!member?.role) return;

    const promise = deactivate(member.id ?? "", {
      resourceId: member.resourceId ?? "",
      roleType: member.role,
    }).then(() => {
      setShowDeactivateModal(false);
      handleClose();
    });
    const { firstName, lastName } = member;
    const name = `${firstName} ${lastName}`;
    toast.promise(promise, {
      loading: t("teamMembers.deactivating", { name }),
      success: () => t("teamMembers.deactivated", { name }),
      error: (err) => errorToToastMessage(err),
    });
  };

  if (careTeamMembersQueryLoading) {
    return null;
  }
  return (
    <>
      {/* TODO: hack to remove slideover padding, remove when new slideover is implemented */}
      <div className="-mx-4 -my-6 sm:-mx-6">
        <form onSubmit={handleSubmit(submitHandler)}>
          <FormSection>
            <FormSection.Label htmlFor="firstName">
              {t("firstName")}
            </FormSection.Label>
            <div>
              <Input
                {...register("firstName")}
                placeholder={t("placeholder.firstName").toString()}
                type="text"
                // autoFocus
                invalid={!!errors.firstName}
              />
              {errors.firstName && (
                <ValidationMessage>
                  {errors.firstName.message}
                </ValidationMessage>
              )}
            </div>

            <FormSection.Label htmlFor="lastName">
              {t("lastName")}
            </FormSection.Label>
            <div>
              <Input
                {...register("lastName")}
                placeholder={t("placeholder.lastName").toString()}
                type="text"
                invalid={!!errors.lastName}
              />
              {errors.lastName && (
                <ValidationMessage>{errors.lastName.message}</ValidationMessage>
              )}
            </div>

            <FormSection.Label htmlFor="email">{t("email")}</FormSection.Label>
            <div>
              <Input
                {...register("email")}
                placeholder={t("placeholder.email").toString()}
                // Team member emails can not be updated
                disabled={!!member?.id}
                type="text"
                invalid={!!errors.email}
              />
              {errors.email && (
                <ValidationMessage>{errors.email.message}</ValidationMessage>
              )}
            </div>

            <FormSection.Label htmlFor="phone">{t("phone")}</FormSection.Label>
            <div>
              <Input
                {...register("phone")}
                placeholder={t("placeholder.phone").toString()}
                type="text"
                invalid={!!errors.phone}
              />
              {errors.phone && (
                <ValidationMessage>{errors.phone.message}</ValidationMessage>
              )}
            </div>

            <FormSection.Label htmlFor="homePhone">
              {t("homePhone")}
            </FormSection.Label>
            <div>
              <Input
                {...register("homePhone")}
                placeholder={t("placeholder.homePhone").toString()}
                type="text"
                invalid={!!errors.homePhone}
              />
              {errors.homePhone && (
                <ValidationMessage>
                  {errors.homePhone.message}
                </ValidationMessage>
              )}
            </div>

            {!member?.id && !careTeamMembersQueryLoading ? (
              <>
                <FormSection.Label htmlFor="role">
                  {t("role")}
                </FormSection.Label>
                <div className="mt-6 space-y-6 sm:mt-0">
                  <Radios
                    control={control}
                    name="role"
                    options={roleOptions.map((option) => ({
                      key: option,
                      value: option,
                      label: translateCareTaemMemberRole(option),
                    }))}
                    invalid={!!errors.role}
                  />
                  {errors.role && (
                    <ValidationMessage className="!mt-0">
                      {t(errors.role.message || "required").toString()}
                    </ValidationMessage>
                  )}
                </div>

                <FormSection.Label htmlFor="organizationUnit">
                  {t("organization.unit")}
                </FormSection.Label>
                <div className="mt-1 max-w-lg sm:mt-0 sm:max-w-xs">
                  <Controller
                    control={control}
                    name="organizationUnit"
                    render={({ field }) => (
                      <Select
                        name={field.name}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        value={field.value && field.value}
                        id={"organization.unit.select"}
                        placeholder={t(
                          "teamMembers.selectOrganizationUnit",
                        ).toString()}
                        options={unitOptions}
                        isMulti={false}
                        isClearable={false}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.id}
                        formatGroupLabel={(data) => (
                          <SelectGroupLabel data={data} />
                        )}
                      />
                    )}
                  />
                  {errors.organizationUnit && (
                    <ValidationMessage className="!mt-0">
                      {t(
                        errors.organizationUnit.message || "required",
                      ).toString()}
                    </ValidationMessage>
                  )}
                </div>
              </>
            ) : null}
          </FormSection>
          <Form.StickyFooter>
            {member?.id && !isUserCheckingThemselves() && (
              <Button
                className="mr-auto"
                variant="danger"
                text={t("deactivate").toString()}
                onClick={() => setShowDeactivateModal(true)}
              />
            )}

            <Button
              variant="secondary"
              onClick={() => handleClose()}
              text={t("cancel").toString()}
              disabled={isSubmitting}
            />
            <Button
              variant="primary"
              type="submit"
              text={t("ok").toString()}
              disabled={isSubmitting}
            />
          </Form.StickyFooter>
        </form>
      </div>
      <SimpleModal
        show={showDeactivateModal}
        title={t("teamMembers.deactivateTitle", {
          name: `${member?.firstName} ${member?.lastName}`,
        })}
        onClose={() => setShowDeactivateModal(false)}
        onAccept={handleDeactivate}
      ></SimpleModal>
    </>
  );
};
