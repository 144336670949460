type Props = {
  className?: string;
  width?: number;
  height?: number;
};
export const PlusIcon = ({ className, width = 16, height = 16 }: Props) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99995 3.19995L7.99995 12.8M12.8 7.99995L3.19995 7.99995"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
