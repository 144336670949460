import { useMemo, useState } from "react";
import { useTranslate } from "@tolgee/react";

import { ColumnDef } from "@tanstack/react-table";

import {
  CareRecipientsQuery,
  useCareRecipientsQuery,
} from "../../api/generated/graphql";

import { Button, Status, Table } from "../../components/common";
import { Headline, HeadlineContainer } from "@frontend/lyng/typography";
import { useSortingOptions } from "../../utils/hooks/useSortingOptions";
import { Link } from "react-router-dom";
import {
  CareRecipient,
  CareRecipientFormModal,
} from "./CareRecipientFormModal";
import { OfficeFilter } from "../../components/common/officeFilter/OfficeFilter";
import { useLocalStorage } from "../../utils/hooks/useLocalStorage";
import { Tabs } from "@frontend/lyng/tabs";
import { match } from "ts-pattern";
import { useCareContext } from "../../providers";

type CareRecipientRow = CareRecipientsQuery["careRecipients"][number];

type RoleActiveTab = "active" | "deactivated" | "all";

export const CareRecipientList = () => {
  const {
    state: { selectedTenant },
  } = useCareContext();
  const { collator, nameOrderFn } = useSortingOptions();
  const { t } = useTranslate();
  const [roleActiveTab, setRoleActiveTab] = useState<RoleActiveTab>("active");
  const [careRecipientFormModal, setCareRecipientFormModal] =
    useState<Partial<CareRecipient> | null>(null);
  const [selectedOfficeId, setSelectedOfficeId] = useLocalStorage<
    string | null
  >(`office-filter-${selectedTenant?.id}`, null);

  const { data, loading, error } = useCareRecipientsQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      filter: {
        officeIds: selectedOfficeId ? [selectedOfficeId] : [],
      },
    },
  });

  const columns = useMemo<ColumnDef<CareRecipientRow>[]>(() => {
    return [
      {
        id: "name",
        header: t("careRecipients.name") ?? "",
        accessorFn: (row) => nameOrderFn(row),
        cell: (row) => (
          <Link to={row.row.original.id}>{row.getValue<string>()}</Link>
        ),
        sortingFn: (a, b, cId) =>
          collator.compare(a.getValue(cId), b.getValue(cId)),
      },
      {
        id: "address",
        header: t("careRecipients.address"),
        accessorFn: (row) => row.address,
        cell: (row) =>
          [
            row.row.original.address?.addressLine1,
            row.row.original.address?.city,
            row.row.original.address?.zipCode,
          ]
            .filter((x) => !!x)
            .join(", "),
      },
      {
        header: t("careRecipients.status") ?? "",
        accessorFn: (row) => !!row.deactivatedAt,
        sortable: false,
        cell: (row) => <Status active={!row.cell.getValue()} />,
      },
      {
        header: t("careRecipients.office") ?? "",
        accessorFn: (row) => row.office?.name,
      },
    ];
  }, [t, nameOrderFn, collator]);

  const rows = useMemo(() => {
    return (
      match(roleActiveTab)
        .with("active", () =>
          data?.careRecipients.filter((cr) => !cr.deactivatedAt),
        )
        .with("deactivated", () =>
          data?.careRecipients.filter((cr) => cr.deactivatedAt),
        )
        .with("all", () => data?.careRecipients)
        .exhaustive() ?? []
    );
  }, [data, roleActiveTab]);

  const roleActiveTabs: { id: RoleActiveTab; label: string }[] = useMemo(
    () => [
      {
        id: "active",
        label: t("careRecipients.active"),
      },
      {
        id: "deactivated",
        label: t("careRecipients.deactivated"),
      },
      {
        id: "all",
        label: t("careRecipients.all"),
      },
    ],
    [t],
  );

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div className="p-5 md:p-0">
      <HeadlineContainer>
        <Headline size="l">{t("careRecipients.title")}</Headline>
        <Button
          variant="primary"
          text={t("careRecipients.add").toString()}
          onClick={() => {
            setCareRecipientFormModal({});
          }}
        />
      </HeadlineContainer>

      <div className="px-8 pb-4 flex flex-row gap-2">
        <OfficeFilter
          selectedOfficeId={selectedOfficeId}
          setSelectedOfficeId={(officeId) => {
            setSelectedOfficeId(officeId);
          }}
          showAllOption={true}
        />
        <Tabs
          tabs={roleActiveTabs}
          currentTab={roleActiveTab}
          onChange={setRoleActiveTab}
        />
      </div>

      <Table
        columns={columns}
        data={rows}
        defaultSorting={[{ id: "name", desc: false }]}
        loading={loading && !data}
      />

      {careRecipientFormModal && (
        <CareRecipientFormModal
          careRecipient={careRecipientFormModal}
          onClose={() => setCareRecipientFormModal(null)}
        />
      )}
    </div>
  );
};
