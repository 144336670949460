import classNames from "classnames";
import { ReactElement } from "react";

type Tab = {
  id: string; // also used for translation
  label: string;
  icon?: ReactElement;
};

type Props = {
  tabs: Tab[];
  currentTab: string;
  onChange: (tab: string) => void;
};
export const Tabs = ({ tabs, currentTab, onChange }: Props) => {
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          onChange={(e) => onChange(e.target.value)}
          defaultValue={tabs.find((tab) => tab.id === currentTab)?.id}
        >
          {tabs.map((tab) => (
            <option key={tab.id} value={tab.id}>
              {tab.label}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden rounded-xl border border-greyscale-200 p-1 sm:block">
        <nav className="flex" aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={classNames(
                {
                  "text-gray-800 border-greyscale-400 rounded-[10px] border":
                    tab.id === currentTab,
                  "text-gray-600 hover:text-gray-800 ": tab.id !== currentTab,
                },
                "inline-flex h-10 gap-2 items-center rounded-md px-4 py-2 text-sm font-medium flex-auto justify-center",
              )}
              onClick={() => onChange(tab.id)}
              aria-current={tab.id === currentTab ? "page" : undefined}
            >
              <span>{tab.label}</span>
              {tab.icon}
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
};
