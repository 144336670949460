import { z } from "zod";
import Select from "react-select/async";
import {
  NameOrder,
  useCareGiversForSelectLazyQuery,
} from "../../../api/generated/graphql";
import { useTranslate } from "@tolgee/react";
import { Paragraph, Link } from "@frontend/lyng/typography";
import { components, SingleValueProps } from "react-select";
import { AriaAttributes } from "react";
import { useSortingOptions } from "../../../utils/hooks/useSortingOptions";

export const optionSchema = z.object({
  id: z.string(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
});
export type Option = z.infer<typeof optionSchema>;

type Props = {
  value: Option | null;
  name?: string | undefined;
  editView?: boolean;
  onChange: (value: Option | null) => void;
  onBlur: () => void;
  officeIdFilter: string;
} & AriaAttributes;

export const CaregiverSelect = ({
  value,
  name,
  editView = true,
  onChange,
  onBlur,
  officeIdFilter,
  ...props
}: Props) => {
  const { t } = useTranslate();
  const [careGiversForSelectQuery] = useCareGiversForSelectLazyQuery();
  const { nameOrder, collator } = useSortingOptions();

  const loadOptions = async (inputValue: string): Promise<Option[]> => {
    const data = await careGiversForSelectQuery({
      variables: { filter: { officeIds: [officeIdFilter] } },
    });

    const getName = (name: Option, nameOrder: NameOrder): string | null => {
      if (!name.firstName || !name.lastName) return null;
      return nameOrder === "FIRST_LAST"
        ? name.firstName.toLowerCase()
        : name.lastName.toLocaleLowerCase();
    };

    return (
      data.data?.careGivers
        .filter(
          ({ firstName, lastName }) =>
            firstName?.toLowerCase().includes(inputValue) ||
            lastName?.toLowerCase().includes(inputValue),
        )
        .sort((a, b) => {
          const nameA = getName(a, nameOrder);
          const nameB = getName(b, nameOrder);
          if (nameA !== null && nameB !== null) {
            return collator.compare(nameA, nameB);
          } else return 0;
        }) ?? []
    );
  };

  if (!editView) {
    return (
      <div className="flex h-12 items-center px-5">
        {value ? (
          <Link size="m" to={`/caregivers/${value.id}`}>
            {`${value.firstName} ${value.lastName}`}
          </Link>
        ) : (
          <Paragraph size="m">-</Paragraph>
        )}
      </div>
    );
  }

  return (
    <div
      data-testid="caregiver-dropdown"
      className="relative mx-1 max-w-lg rounded-md shadow-sm sm:max-w-xs"
    >
      <Select
        {...props}
        name={name}
        placeholder={t("placeholder.chooseCaregiver")}
        loadOptions={loadOptions}
        defaultOptions
        value={value}
        isMulti={false}
        isClearable={true}
        onChange={onChange}
        onBlur={onBlur}
        getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
        getOptionValue={(option) => option.id}
        cacheOptions
        components={{
          SingleValue: ({
            children,
            ...props
          }: SingleValueProps<Option, false>) => (
            <components.SingleValue {...props}>
              <Paragraph size="m">{children}</Paragraph>
            </components.SingleValue>
          ),
        }}
        styles={{
          container: (base) => ({
            ...base,
          }),
          control: (base) => ({
            ...base,
            height: "3rem",
            borderRadius: "0.75rem",
          }),
          valueContainer: (base) => ({
            ...base,
            padding: "0 1rem",
          }),
          singleValue: (base) => ({
            ...base,
            margin: 0,
          }),
          input: (base) => ({
            ...base,
            "input:focus": {
              boxShadow: "none",
            },
          }),
        }}
      />
    </div>
  );
};
