type Props = {
  className?: string;
  width?: number;
  height?: number;
  filled?: boolean;
};

export const CalendarIcon = ({
  className,
  width = 16,
  height = 16,
  filled = false,
}: Props) => {
  return (
    <div>
      {filled ? (
        <svg
          className={className}
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* Filled icon */}
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.59772 1C4.59772 0.585786 4.26193 0.25 3.84772 0.25C3.4335 0.25 3.09772 0.585786 3.09772 1V1.76308C1.77456 2.34229 0.850098 3.66318 0.850098 5.2V12.0001C0.850098 14.0711 2.52903 15.7501 4.6001 15.7501H11.4001C13.4712 15.7501 15.1501 14.0711 15.1501 12.0001V5.2C15.1501 3.60484 14.1541 2.24231 12.7501 1.70035V1C12.7501 0.585786 12.4143 0.25 12.0001 0.25C11.5859 0.25 11.2501 0.585786 11.2501 1V1.45H4.6001L4.59772 1.45V1ZM3.56519 4.99995C3.56519 4.57039 3.91341 4.22217 4.34296 4.22217H11.6001C12.0297 4.22217 12.3779 4.57039 12.3779 4.99995C12.3779 5.4295 12.0297 5.77772 11.6001 5.77772H4.34296C3.91341 5.77772 3.56519 5.4295 3.56519 4.99995Z"
            fill="currentColor"
          />
        </svg>
      ) : (
        <svg
          className={className}
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* Outlined icon */}
          <path
            d="M4.34295 5H11.6001M3.84772 1V2.20015M12.0001 1V2.2M14.4001 5.2L14.4001 12.0001C14.4001 13.6569 13.0569 15.0001 11.4001 15.0001H4.6001C2.94324 15.0001 1.6001 13.6569 1.6001 12.0001V5.2C1.6001 3.54315 2.94325 2.2 4.6001 2.2H11.4001C13.057 2.2 14.4001 3.54314 14.4001 5.2Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </div>
  );
};

// export default HomeIcon;
