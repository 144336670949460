import { ApolloError } from "@apollo/client";

import { ViewerQuery, Tenant } from "../api/generated/graphql";

import { USER_TYPES } from "./Types";
import { FeatureFlags } from "./FeatureFlags";

export const UserActions = {
  setViewer: (payload: { viewer: ViewerQuery["viewer"]; tenant?: Tenant }) => ({
    type: USER_TYPES.SET_VIEWER,
    payload,
  }),
  setViewerError: (payload: ApolloError) => ({
    type: USER_TYPES.SET_VIEWER_ERROR,
    payload,
  }),
  setTenant: (payload: Tenant) => ({
    type: USER_TYPES.SET_TENANT,
    payload,
  }),
  setFeatureFlag(payload: FeatureFlags) {
    return {
      type: USER_TYPES.SET_FEATURE_FLAG,
      payload,
    };
  },
};
