import {
  VisitTypesQuery,
  useVisitTypesByDateQuery,
  useVisitTypesQuery,
} from "../../../api/generated/graphql";
import { Paragraph } from "@frontend/lyng/typography";
import { useNumberFormatter } from "../../../utils/hooks/useNumberFormatter";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslate } from "@tolgee/react";
import { Select } from "../select/select";

type Option = VisitTypesQuery["visitTypes"][number];
type Value = { id: string };

const formatOptionLabel = (option: Option) => {
  return (
    <>
      {option.title}
      <span className="ml-2 text-greyscale-400">({option.code})</span>
    </>
  );
};

type Props = {
  value: Value | null;
  name?: string | undefined;
  onChange: (value: Value | null) => void;
  onBlur: () => void;
  activeOn?: string | null;
  editView?: boolean;
};
export const VisitTypeSelect = ({
  value,
  name,
  onChange,
  onBlur,
  activeOn,
  editView = true,
}: Props) => {
  const { t } = useTranslate();
  const { formatCurrency } = useNumberFormatter();

  const { data: latestData, loading: latestLoading } = useVisitTypesQuery({
    skip: !!activeOn,
  });
  const { data: byDateData, loading: byDateLoading } = useVisitTypesByDateQuery(
    {
      skip: !activeOn,
      variables: {
        date: activeOn ?? "",
      },
    },
  );
  const loading = activeOn ? byDateLoading : latestLoading;

  const options =
    (activeOn ? byDateData?.visitTypesByDate : latestData?.visitTypes) ?? [];

  const selectedOption = options?.find((option) => option.id === value?.id);

  return (
    <div data-testid="visit-type-select">
      {editView ? (
        <Select
          name={name}
          aria-label={t("placeholder.chooseVisitType").toString()}
          placeholder={t("placeholder.chooseVisitType")}
          value={selectedOption}
          options={options}
          isLoading={loading}
          isMulti={false}
          isClearable={true}
          onChange={(option: Option | null) => {
            onChange(option ? { id: option.id } : null);
          }}
          onBlur={onBlur}
          filterOption={(option, inputValue) => {
            return (
              option.data.title
                ?.toLowerCase()
                .includes(inputValue.toLowerCase()) ||
              option.data.code?.toLowerCase().includes(inputValue.toLowerCase())
            );
          }}
          getOptionValue={(option) => option.id}
          formatOptionLabel={formatOptionLabel}
        />
      ) : (
        <Paragraph size="m" className="flex h-12 items-center px-5">
          {selectedOption ? formatOptionLabel(selectedOption) : "-"}
        </Paragraph>
      )}

      <AnimatePresence initial={!selectedOption}>
        {selectedOption && (
          <motion.div
            key={"visit-type-description"}
            initial={{
              height: 0,
              overflow: "hidden",
            }}
            animate={{
              height: "auto",
              overflow: "hidden",
              transitionEnd: {
                overflow: "visible",
              },
            }}
            exit={{
              height: 0,
              overflow: "hidden",
            }}
          >
            <Paragraph
              size="xs"
              type="secondary"
              className="mx-5 mt-2 text-sm text-greyscale-600"
            >
              {t("visitTypeSelect.descriptionBase", {
                baseRate: formatCurrency(parseFloat(selectedOption.baseRate)),
              })}
              {selectedOption.eveningRate && (
                <span>
                  {t("visitTypeSelect.descriptionEvening", {
                    eveningRate: formatCurrency(
                      parseFloat(selectedOption.eveningRate),
                    ),
                  })}
                </span>
              )}
              {selectedOption.nightRate && (
                <span>
                  {t("visitTypeSelect.descriptionNight", {
                    nightRate: formatCurrency(
                      parseFloat(selectedOption.nightRate),
                    ),
                  })}
                </span>
              )}
            </Paragraph>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
