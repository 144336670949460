import {
  CareTeamMemberRoleType,
  RoleType,
  useCareTeamMemberDeleteMutation,
  useCareTeamMemberReactivateMutation,
} from "../../../api/generated/graphql";
import { Button, SimpleModal } from "../../../components/common";
import { useCareContext } from "../../../providers/CareProvider";
import { useDateFormatter } from "../../../utils/dateUtils";
import { errorToToastMessage } from "../../../utils/toastUtils";
import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslate } from "@tolgee/react";

type Props = {
  id: string;
  role: CareTeamMemberRoleType;
  resourceId: string;
  name: string;
  deactivatedAt: string;
  onClose: () => void;
};
export const DeactivatedMember = ({
  id,
  role,
  resourceId,
  name,
  deactivatedAt,
  onClose,
}: Props) => {
  const {
    state: { viewer },
  } = useCareContext();
  const { t } = useTranslate();
  const { formatDate } = useDateFormatter();

  const [deleteModal, setDeleteModal] = useState(false);

  const [reactivateTeamMember, { loading: reactivateLoading }] =
    useCareTeamMemberReactivateMutation({
      refetchQueries: ["CareTeamMembers"],
    });

  const [deleteTeamMember, { loading: deleteLoading }] =
    useCareTeamMemberDeleteMutation({
      refetchQueries: ["CareTeamMembers"],
    });

  const handleReactivate = () => {
    const promise = reactivateTeamMember({
      variables: {
        id: id,
        input: {
          resourceId: resourceId,
          roleType: role,
        },
      },
    }).then(() => {
      onClose();
    });

    toast.promise(promise, {
      loading: t("teamMembers.reactivating", { name }),
      success: () => t("teamMembers.reactivated", { name }),
      error: (err) => errorToToastMessage(err),
    });
  };

  const handleDelete = () => {
    const promise = deleteTeamMember({
      variables: {
        id: id,
        input: {
          resourceId: resourceId,
          roleType: role,
        },
      },
    }).then(() => {
      onClose();
    });

    toast.promise(promise, {
      loading: t("teamMembers.deactivatedTeamMember.deleting", { name }),
      success: () => t("teamMembers.deactivatedTeamMember.deleted", { name }),
      error: (err) => errorToToastMessage(err),
    });
  };

  const loading = reactivateLoading || deleteLoading;

  const isAdmin = viewer?.roles.some(
    ({ deactivateAt, roleType }) =>
      !deactivateAt && roleType === RoleType.Admin,
  );

  return (
    <>
      <div className="mt-16 flex flex-col items-center gap-2">
        <h2 className="text-2xl font-bold text-gray-800">
          {t("teamMembers.deactivatedTeamMember.heading", {
            name: name,
          })}
        </h2>
        <p className="text-gray-500">
          {t("teamMembers.deactivatedTeamMember.subHeading", {
            name: name,
            deactivatedAt: formatDate(deactivatedAt),
          })}
        </p>

        <div className="flex flex-row gap-2">
          {isAdmin && (
            <>
              <Button
                className="mt-4"
                variant="danger"
                size="lg"
                onClick={() => setDeleteModal(true)}
                text={t("teamMembers.deactivatedTeamMember.delete") ?? ""}
                loading={loading}
                disabled={loading}
              />
              <Button
                className="mt-4"
                variant="primary"
                size="lg"
                onClick={handleReactivate}
                text={t("reactivate") ?? ""}
                loading={reactivateLoading}
                disabled={loading}
              />
            </>
          )}
        </div>
      </div>
      <SimpleModal
        show={deleteModal}
        title={t("teamMembers.deactivatedTeamMember.deleteTitle") ?? ""}
        onClose={() => setDeleteModal(false)}
        onAccept={handleDelete}
        acceptText={t("teamMembers.deactivatedTeamMember.deleteConfirm") ?? ""}
        closeText={t("teamMembers.deactivatedTeamMember.deleteCancel") ?? ""}
        type="critical"
      >
        <p>{t("teamMembers.deactivatedTeamMember.deleteDescription") ?? ""}</p>
      </SimpleModal>
    </>
  );
};
