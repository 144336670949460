import classNames from "classnames";
import { useTranslate } from "@tolgee/react";
import { VisitHistoryEvent } from "../../../api/generated/graphql";
import { useDateFormatter } from "../../../utils/dateUtils";
import { Headline } from "@frontend/lyng/typography";
import { useCallback } from "react";
import { match } from "ts-pattern";

type Props = {
  history: {
    event: VisitHistoryEvent | null;
    updatedAt: string | null;
    version: number;
    updatedBy: {
      firstName: string | null;
      lastName: string | null;
      id: string;
    } | null;
  }[];
};
export const VisitLogHistory = ({ history }: Props) => {
  const { t } = useTranslate();
  const { formatTime, formatDate } = useDateFormatter();

  const translateEvent = useCallback(
    (event: VisitHistoryEvent) => {
      return match(event)
        .with(VisitHistoryEvent.ClockIn, () => t("visitLog.events.CLOCK_IN"))
        .with(VisitHistoryEvent.ClockOut, () => t("visitLog.events.CLOCK_OUT"))
        .with(VisitHistoryEvent.Unknown, () => t("visitLog.events.UNKNOWN"))
        .with(VisitHistoryEvent.Update, () => t("visitLog.events.UPDATE"))
        .with(VisitHistoryEvent.VisitStarting, () =>
          t("visitLog.events.VISIT_STARTING"),
        )
        .with(VisitHistoryEvent.ManualCreate, () =>
          t("visitLog.events.MANUAL_CREATE"),
        )
        .with(VisitHistoryEvent.ActivityStatusUpdated, () =>
          t("visitLog.events.ACTIVITY_STATUS_UPDATED"),
        )
        .with(VisitHistoryEvent.Cancelled, () => t("visitLog.events.CANCELLED"))
        .with(VisitHistoryEvent.Reactivated, () =>
          t("visitLog.events.REACTIVATED"),
        )
        .otherwise(() => t("visitLog.events.UNKNOWN"));
    },
    [t],
  );

  return (
    <div className="border-t border-greyscale-200">
      <Headline size="l" className="px-7 pb-4 pt-8">
        {t("visitLog.visitLogHistory")}
      </Headline>
      <div className="flow-root px-4 pb-8 pt-4">
        <ul className="-mb-8">
          {[...history] // spread to fix the issue of sorting the original array in-place
            .sort((a, b) => b.version - a.version)
            .map((event, eventIdx) => (
              <li key={event.version}>
                <div className="relative pb-8">
                  {eventIdx !== history.length - 1 ? (
                    <span
                      className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                      aria-hidden="true"
                    />
                  ) : null}
                  <div className="relative flex gap-3">
                    <div>
                      <span
                        className={classNames(
                          "bg-neutral-300",
                          "flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white",
                        )}
                      ></span>
                    </div>
                    <div className="flex min-w-0 flex-1 items-center justify-between space-x-4">
                      <div className="flex items-center gap-2 text-sm">
                        {event.updatedBy && (
                          <p className="font-semibold">
                            {`${event.updatedBy.firstName} ${event.updatedBy.lastName}`}
                          </p>
                        )}
                        <p className="text-gray-500">
                          {event.event && translateEvent(event.event)}
                        </p>
                      </div>
                      <p className="whitespace-nowrap text-right text-sm text-gray-500">
                        {event.updatedAt &&
                          `${formatDate(event.updatedAt)} ${formatTime(
                            event.updatedAt,
                          )}`}
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};
