type Props = {
  className?: string;
  width?: number;
  height?: number;
};
export const CaregiversIcon = ({
  className,
  width = 16,
  height = 16,
}: Props) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8001 9.12378C13.645 9.75504 14.4001 11.3444 14.4001 12.3237C14.4001 12.6288 14.1775 12.8762 13.9029 12.8762H13.6001M10.4001 6.53629C10.9467 6.22012 11.3144 5.62918 11.3144 4.95235C11.3144 4.27552 10.9467 3.68458 10.4001 3.36841M2.09728 12.8762H10.8553C11.1299 12.8762 11.3525 12.6288 11.3525 12.3237C11.3525 10.406 9.7503 8.85137 6.47629 8.85137C3.20227 8.85137 1.6001 10.406 1.6001 12.3237C1.6001 12.6288 1.82269 12.8762 2.09728 12.8762ZM8.30486 4.95235C8.30486 5.96224 7.48618 6.78092 6.47629 6.78092C5.4664 6.78092 4.64772 5.96224 4.64772 4.95235C4.64772 3.94246 5.4664 3.12378 6.47629 3.12378C7.48618 3.12378 8.30486 3.94246 8.30486 4.95235Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
