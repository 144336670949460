import {
  Division,
  Region,
  Office,
  DivisionSettings,
} from "../api/generated/graphql";

export type division = {
  id: Division["id"];
  name: Division["name"];
  settings: DivisionSettings;
  enabledSettings: string[];
};
type region = {
  id: Region["id"];
  name: Region["name"];
  division: Division["name"];
};
type office = {
  id: Office["id"];
  name: Office["name"];
  region: Region["name"];
  division: Division["name"];
};
export type flattenedOrganization = [division[], region[], office[]];

type input = {
  divisions?: {
    id: Division["id"];
    name: Division["name"];
    settings: DivisionSettings | null;
    regions?: {
      id: Region["id"];
      name: Region["name"];
      offices?: {
        id: Office["id"];
        name: Office["name"];
      }[];
    }[];
  }[];
};

export const generateEnabledSettings = (
  settings: Omit<DivisionSettings, "divisionId" | "__typename"> | undefined,
) => {
  if (!settings) {
    return [];
  }

  return Object.entries(settings).reduce<string[]>(
    (acc, [key, value]) => (value ? [...acc, key] : acc),
    [],
  );
};

export const groupOrganizationUnits = (
  organization: input,
): flattenedOrganization => {
  return (
    organization.divisions?.reduce<flattenedOrganization>(
      (
        [accumulatedDivisions, accumulatedRegions, accumulatedOffices],
        currentDivision,
      ) => {
        const regions = currentDivision.regions || [];
        const offices = regions.reduce<
          {
            id: string;
            name: string;
            region: string;
          }[]
        >((accumulated, currentRegion) => {
          const offices =
            currentRegion.offices?.map((office) => ({
              ...office,
              region: currentRegion.name,
            })) || [];
          return [...accumulated, ...offices];
        }, []);
        return [
          [
            ...accumulatedDivisions,
            {
              id: currentDivision.id,
              name: currentDivision.name,
              settings: currentDivision.settings
                ? currentDivision.settings
                : {
                    divisionId: currentDivision.id,
                    caregiversCanCreateAndUpdateVisits: false,
                  },
              enabledSettings: currentDivision.settings
                ? generateEnabledSettings(currentDivision.settings)
                : [],
            },
          ],
          [
            ...accumulatedRegions,
            ...regions.map((region) => ({
              id: region.id,
              name: region.name,
              division: currentDivision.name,
            })),
          ],
          [
            ...accumulatedOffices,
            ...offices.map((office) => ({
              ...office,
              division: currentDivision.name,
            })),
          ],
        ];
      },
      [[], [], []],
    ) || [[], [], []]
  );
};
