import { FormatterInput } from "@fullcalendar/core";

export const getTimeFormat = (meridiem?: boolean): FormatterInput => {
  if (meridiem)
    return {
      hour: "numeric",
      minute: "2-digit",
      omitZeroMinute: true,
      meridiem: "short",
    };

  return {
    hour: "numeric",
    minute: "2-digit",
    meridiem: false,
    hourCycle: "h23",
  };
};
