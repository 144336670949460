import { useMemo, useState } from "react";
import { useTranslate } from "@tolgee/react";

import {
  VisitlogsTotalsReportQuery,
  useVisitlogsTotalsReportQuery,
} from "../../../api/generated/graphql";

import {
  CSVExportColumnPickerModal,
  Dropdown,
  Table,
} from "../../../components/common";
import {
  Headline,
  HeadlineContainer,
  Link,
  Paragraph,
} from "@frontend/lyng/typography";
import { ColumnDef } from "@tanstack/react-table";
import { useSortingOptions } from "../../../utils/hooks/useSortingOptions";
import { DateTime } from "luxon";
import { useCareContext } from "../../../providers/CareProvider";
import { WiseExportModal } from "./WiseExportModal";
import { DateRangePicker } from "@frontend/lyng/forms";

type VisitlogsTotalsRow = {
  careRecipient: VisitlogsTotalsReportQuery["visitlogsTotalsReport"]["totals"][number]["careRecipient"];
  section: VisitlogsTotalsReportQuery["visitlogsTotalsReport"]["totals"][number]["sections"][number];
};

export const VisitTypeReport = () => {
  const { t } = useTranslate();
  const {
    state: { viewer },
  } = useCareContext();
  const { collator, nameOrderFn } = useSortingOptions();

  const [showCSVExportModal, setShowCSVExportModal] = useState(false);
  const [showExportWiseModal, setShowExportWiseModal] = useState(false);

  const [dateRange, setDateRange] = useState({
    start: DateTime.local().startOf("month"),
    end: DateTime.local().endOf("month"),
  });

  const { data, error, loading } = useVisitlogsTotalsReportQuery({
    fetchPolicy: "network-only",
    skip: dateRange.start > dateRange.end,
    variables: {
      from: dateRange.start.startOf("day").toISO() ?? "",
      to: dateRange.end.endOf("day").toISO() ?? "",
    },
  });

  const rows = useMemo(() => {
    return (
      data?.visitlogsTotalsReport.totals.flatMap((row) => {
        return row.sections.map((section) => ({
          careRecipient: row.careRecipient,
          section,
        }));
      }) ?? []
    );
  }, [data?.visitlogsTotalsReport.totals]);

  const csvData = useMemo(() => {
    return [
      [
        t("careRecipient"),
        t("visitTypes.visitType"),
        t("reports.visitTypes.totalHours"),
      ],

      ...(rows?.map((report) => [
        `${report.careRecipient.firstName} ${report.careRecipient.lastName}`,
        report.section?.billingKey.toString(),
        report.section?.totalHours.toString(),
      ]) ?? []),
    ];
  }, [rows, t]);

  const columns = useMemo<ColumnDef<VisitlogsTotalsRow>[]>(() => {
    return [
      {
        id: "careRecipient",
        header: t("careRecipient") ?? "",
        accessorFn: (row) => nameOrderFn(row.careRecipient),
        cell: (row) =>
          row.row.original.careRecipient.firstName !== "deleted.User" ? (
            <div>
              <Link
                size="s"
                to={`/care-recipients/${row.row.original.careRecipient.id}`}
              >
                {row.getValue<string>()}
              </Link>
              {viewer?.tenantSettings.showSsn &&
                row.row.original.careRecipient.careRecipientRoles.length && (
                  <Paragraph size="s" type="secondary">
                    {row.row.original.careRecipient.careRecipientRoles[0].ssn}
                  </Paragraph>
                )}
            </div>
          ) : (
            <span className="cursor-not-allowed">{t("deletedUser")}</span>
          ),
        sortingFn: (a, b, cId) =>
          collator.compare(a.getValue(cId), b.getValue(cId)),
      },
      {
        id: "visitType",
        header: t("visitTypes.visitType") ?? "",
        accessorFn: (row) => row.section.billingKey,
      },
      {
        id: "totalHours",
        header: t("reports.visitTypes.totalHours") ?? "",
        accessorFn: (row) => row.section.totalHours,
      },
    ];
  }, [collator, nameOrderFn, t, viewer?.tenantSettings.showSsn]);

  if (error) return <p>Error : {error.message}</p>;

  return (
    <div className="mb-8 p-5 md:p-0">
      <HeadlineContainer>
        <Headline size="l">{t("reports.visitTypes.title")}</Headline>

        <div className="flex items-center gap-4">
          <DateRangePicker
            dateSettings={viewer?.tenantSettings}
            aria-label="Select date range"
            name="dateRange"
            value={dateRange}
            onChange={(value) => value && setDateRange(value)}
          />

          <Dropdown
            text={t("export") ?? ""}
            buttonVariant="primary"
            options={[
              {
                label: t("reports.visitTypes.exportWise"),
                onClick: () => setShowExportWiseModal(true),
              },
              {
                label: t("exportCsv"),
                onClick: () => setShowCSVExportModal(true),
              },
            ]}
          />
        </div>
      </HeadlineContainer>

      <Table
        columns={columns}
        data={rows ?? []}
        defaultSorting={[{ id: "careRecipient", desc: false }]}
        loading={loading}
      />

      <CSVExportColumnPickerModal
        show={showCSVExportModal}
        onClose={() => setShowCSVExportModal(false)}
        name="exportCsv"
        defaultSelectedColumns={{}}
        columns={columns.reduce(
          (acc, column) => {
            if (column.enableHiding === false) return acc;
            if (!column.id) return acc;
            if (typeof column.header !== "string") return acc;
            return [...acc, { id: column.id, label: column.header }];
          },
          [] as { id: string; label: string }[],
        )}
        getData={() => csvData}
      />

      <WiseExportModal
        key={`wise-export-${showExportWiseModal}`} // Force re-render
        show={showExportWiseModal}
        from={dateRange.start}
        to={dateRange.end}
        onClose={() => setShowExportWiseModal(false)}
      />
    </div>
  );
};
