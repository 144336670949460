import { Link, NavLink, useMatches } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/20/solid";
import { useTranslate } from "@tolgee/react";

export type Crumb = (data: unknown) => { name: string; href: string } | null;
type HandleWithCrumb = {
  crumb: Crumb;
};

function isHandleWithCrumb(handle: unknown): handle is { crumb: Crumb } {
  return (
    typeof handle === "object" &&
    !!handle &&
    "crumb" in handle &&
    !!handle.crumb
  );
}

export const Breadcrumbs = () => {
  const matches = useMatches();
  const { t } = useTranslate();
  const crumbs = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match) => Boolean(isHandleWithCrumb(match.handle)))
    // now map them into an array of elements, passing the loader
    // data to each one
    .map((match) => (match.handle as HandleWithCrumb).crumb(match.data));

  return (
    <nav className="mb-2 flex" aria-label={t("breadcrumbs").toString()}>
      <ol className="flex items-center space-x-4">
        <li>
          <div>
            <Link to="/" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">{t("home")}</span>
            </Link>
          </div>
        </li>
        {crumbs.map(
          (crumb) =>
            crumb && (
              <li key={crumb.name}>
                <div className="flex items-center">
                  <svg
                    className="h-5 w-5 flex-shrink-0 text-gray-300"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                  <NavLink
                    to={crumb.href}
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  >
                    {t(crumb.name) !== crumb.name
                      ? t(crumb.name).toString()
                      : crumb.name}
                  </NavLink>
                </div>
              </li>
            ),
        )}
      </ol>
    </nav>
  );
};
