import { useMemo } from "react";
import { Select } from "@frontend/lyng/forms";
import {
  useCaregiverAvailabilitiesByLabelIdQuery,
  AvailabilityForLabel,
} from "../../api/generated/graphql";
import { useTranslate } from "@tolgee/react";
import { useSortingOptions } from "../../utils/hooks/useSortingOptions";
import { CareGiverAvailability } from "./types";
import { DateTime } from "luxon";

type Props = {
  name?: CareGiverAvailability | undefined;
  value: CareGiverAvailability | null;
  onChange: (value: CareGiverAvailability | null) => void;
  labelId: string;
  from: DateTime;
  to: DateTime;
};

export const CaregiverAvailabilityBulkSelect = ({
  value,
  from,
  to,
  labelId,
  onChange,
}: Props) => {
  const { t } = useTranslate();
  const { nameOrderFn } = useSortingOptions();

  const { data, error, loading } = useCaregiverAvailabilitiesByLabelIdQuery({
    skip: !labelId || !from || !to,
    variables: {
      labelId,
      from: from.toISO() ?? "",
      to: to.toISO() ?? "",
    },
  });

  const availabilitiesGrouped: {
    label: string;
    options: CareGiverAvailability[];
  }[] = useMemo(() => {
    const groups = data?.caregiverAvailabilitiesByLabelId.reduce(
      (prev, current) => {
        return {
          ...prev,
          [current.availability]: [...prev[current.availability], current],
        };
      },
      {
        [AvailabilityForLabel.Available]: [] as CareGiverAvailability[],
        [AvailabilityForLabel.Conflict]: [] as CareGiverAvailability[],
        [AvailabilityForLabel.NotAvailable]: [] as CareGiverAvailability[],
      },
    );
    if (!groups) {
      return [];
    }
    return [
      {
        label: t("schedule.caregiverAvailabilityByLabelId.available"),
        options: groups[AvailabilityForLabel.Available].map((option) => ({
          ...option,
          isDisabled: false,
        })),
      },
      {
        label: t("schedule.caregiverAvailabilityByLabelId.conflict"),
        options: groups[AvailabilityForLabel.Conflict].map((option) => ({
          ...option,
          isDisabled: false,
        })),
      },
      {
        label: t("schedule.caregiverAvailabilityByLabelId.notAvailable"),
        options: groups[AvailabilityForLabel.NotAvailable].map((option) => ({
          ...option,
          isDisabled: true,
        })),
      },
    ];
  }, [data?.caregiverAvailabilitiesByLabelId, t]);

  const selected = useMemo(() => {
    return data?.caregiverAvailabilitiesByLabelId.find(
      (option) => option.visitor.id === value?.visitor.id,
    );
  }, [data?.caregiverAvailabilitiesByLabelId, value]);

  return (
    <div className="w-80">
      <Select
        name="caregiverAvailabilityByLabel"
        value={selected}
        getOptionLabel={(option) => nameOrderFn(option.visitor)}
        getOptionValue={(option) => option.visitor.id}
        options={availabilitiesGrouped}
        isLoading={loading}
        errorMessage={error && error.message}
        onChange={(option) => onChange(option ?? null)}
        menuPlacement="top"
        isMulti={false}
      />
    </div>
  );
};
