export const COLORS = {
  ABSENCE: {
    text: "#040406",
    background: "#E2E2E4",
  },
  ACTIVE: {
    text: "#040406",
    background: "#C9E5A5",
  },
  CANCELED: {
    text: "#3D3D48",
    background: "#E2E2E4",
  },
  COMPLETED: {
    text: "#040406",
    background: "#E5EBFF",
  },
  LATE_CLOCK_IN: {
    text: "#040406",
    background: "#FFB2B9",
  },
  LATE_CLOCK_OUT: {
    text: "#040406",
    background: "#FFDBDE",
  },
  OPEN: {
    text: "#040406",
    background: "#FFE79A",
  },
  SCHEDULED: {
    text: "#040406",
    background: "#D6E0FF",
  },
} as const;
