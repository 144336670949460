/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDateFormatter as useLyngDateFormatter } from "@frontend/lyng/utils/dateUtils";
import { useCareContext } from "../providers/CareProvider";

export const useDateFormatter = () => {
  const {
    state: { viewer },
  } = useCareContext();

  return useLyngDateFormatter(viewer?.tenantSettings);
};
