import {
  BackendFetch,
  DevTools,
  FormatSimple,
  Tolgee,
  TolgeeInstance,
} from "@tolgee/react";
import { z } from "zod";

import { ZodIssueCode, ZodParsedType, defaultErrorMap, ZodErrorMap } from "zod";

const jsonStringifyReplacer = (_: string, value: bigint | unknown) => {
  if (typeof value === "bigint") {
    return value.toString();
  }
  return value;
};

function joinValues<T extends unknown[]>(array: T, separator = " | "): string {
  return array
    .map((val) => (typeof val === "string" ? `'${val}'` : val))
    .join(separator);
}

const isRecord = (value: unknown): value is Record<string, unknown> => {
  if (typeof value !== "object" || value === null) return false;

  for (const key in value) {
    if (!Object.prototype.hasOwnProperty.call(value, key)) return false;
  }

  return true;
};

const getKeyAndValues = (
  param: unknown,
  defaultKey: string,
): {
  values: Record<string, unknown>;
  key: string;
} => {
  if (typeof param === "string") return { key: param, values: {} };

  if (isRecord(param)) {
    const key =
      "key" in param && typeof param.key === "string" ? param.key : defaultKey;
    const values =
      "values" in param && isRecord(param.values) ? param.values : {};
    return { key, values };
  }

  return { key: defaultKey, values: {} };
};

export type ZodI18nMapOption = {
  t: TolgeeInstance["t"];
  ns?: string;
  handlePath?: HandlePathOption | false;
};

export type HandlePathOption = {
  context?: string;
  ns?: string;
  keyPrefix?: string;
};

const defaultNs = "zod";

// based on zod-i18n-map on npm
export type MakeZodI18nMap = (option: ZodI18nMapOption) => ZodErrorMap;
export const makeZodI18nMap: MakeZodI18nMap = (option) => (issue, ctx) => {
  const { t, ns, handlePath } = {
    ns: defaultNs,
    ...option,
    handlePath:
      option?.handlePath !== false
        ? {
            context: "with_path",
            ns: option?.ns ?? defaultNs,
            keyPrefix: undefined,
            ...option?.handlePath,
          }
        : null,
  };

  let message: string;
  message = defaultErrorMap(issue, ctx).message;

  const path: { context?: string; path?: string } =
    issue.path.length > 0 && !!handlePath
      ? {
          context: handlePath.context ?? "",
          path: t({
            key: [handlePath.keyPrefix, issue.path.join(".")]
              .filter(Boolean)
              .join("."),
            defaultValue: issue.path.join("."),
            ns: handlePath.ns,
          }),
        }
      : {};

  switch (issue.code) {
    case ZodIssueCode.invalid_type:
      if (issue.received === ZodParsedType.undefined) {
        message = t({
          key: "errors.invalid_type_received_undefined",
          ns,
          defaultValue: message,
          params: { ...path },
        });
      } else if (issue.received === ZodParsedType.null) {
        message = t("errors.invalid_type_received_null", {
          ns,
          defaultValue: message,
          ...path,
        });
      } else {
        message = t({
          key: "errors.invalid_type",
          ns,
          defaultValue: message,
          params: {
            ...path,
            expected: t(`types.${issue.expected}`, {
              defaultValue: issue.expected,
              ns,
            }),
            received: t(`types.${issue.received}`, {
              defaultValue: issue.received,
              ns,
            }),
          },
        });
      }
      break;
    case ZodIssueCode.invalid_literal:
      message = t({
        key: "errors.invalid_literal",
        ns,
        defaultValue: message,
        params: {
          ...path,
          expected: JSON.stringify(issue.expected, jsonStringifyReplacer),
        },
      });
      break;
    case ZodIssueCode.unrecognized_keys:
      message = t({
        key: "errors.unrecognized_keys",
        ns,
        defaultValue: message,
        params: {
          ...path,
          keys: joinValues(issue.keys, ", "),
          count: issue.keys.length,
        },
      });
      break;
    case ZodIssueCode.invalid_union:
      message = t({
        key: "errors.invalid_union",
        ns,
        defaultValue: message,
        params: {
          ...path,
        },
      });
      break;
    case ZodIssueCode.invalid_union_discriminator:
      message = t({
        key: "errors.invalid_union_discriminator",
        ns,
        defaultValue: message,
        params: {
          ...path,
          options: joinValues(issue.options),
        },
      });
      break;
    case ZodIssueCode.invalid_enum_value:
      message = t({
        key: "errors.invalid_enum_value",
        ns,
        defaultValue: message,
        params: {
          ...path,
          options: joinValues(issue.options),
          received: issue.received,
        },
      });
      break;
    case ZodIssueCode.invalid_arguments:
      message = t({
        key: "errors.invalid_arguments",
        ns,
        defaultValue: message,
        params: {
          ...path,
        },
      });
      break;
    case ZodIssueCode.invalid_return_type:
      message = t({
        key: "errors.invalid_return_type",
        ns,
        defaultValue: message,
        params: {
          ...path,
        },
      });
      break;
    case ZodIssueCode.invalid_date:
      message = t({
        key: "errors.invalid_date",
        ns,
        defaultValue: message,
        params: {
          ...path,
        },
      });
      break;
    case ZodIssueCode.invalid_string:
      if (typeof issue.validation === "object") {
        if ("startsWith" in issue.validation) {
          message = t({
            key: `errors.invalid_string.startsWith`,
            ns,
            defaultValue: message,
            params: {
              ...path,
              startsWith: issue.validation.startsWith,
            },
          });
        } else if ("endsWith" in issue.validation) {
          message = t({
            key: `errors.invalid_string.endsWith`,
            ns,
            defaultValue: message,
            params: {
              ...path,
              endsWith: issue.validation.endsWith,
            },
          });
        }
      } else {
        message = t({
          key: `errors.invalid_string.${issue.validation}`,
          ns,
          defaultValue: message,
          params: {
            ...path,
            validation: t({
              key: `validations.${issue.validation}`,
              defaultValue: issue.validation,
              ns,
            }),
          },
        });
      }
      break;
    case ZodIssueCode.too_small: {
      const minimum =
        issue.type === "date"
          ? new Date(issue.minimum as number)
          : issue.minimum;
      message = t({
        key: `errors.too_small.${issue.type}.${issue.exact ? "exact" : issue.inclusive ? "inclusive" : "not_inclusive"}`,
        ns,
        defaultValue: message,
        params: {
          ...path,
          minimum,
          count: typeof minimum === "number" ? minimum : "",
        },
      });
      break;
    }
    case ZodIssueCode.too_big: {
      const maximum =
        issue.type === "date"
          ? new Date(issue.maximum as number)
          : issue.maximum;
      message = t({
        key: `errors.too_big.${issue.type}.${issue.exact ? "exact" : issue.inclusive ? "inclusive" : "not_inclusive"}`,
        ns,
        defaultValue: message,
        params: {
          ...path,
          maximum,
          count: typeof maximum === "number" ? maximum : "",
        },
      });
      break;
    }
    case ZodIssueCode.custom: {
      const { key, values } = getKeyAndValues(
        issue.params?.i18n,
        "errors.custom",
      );

      message = t({
        key,
        ns,
        defaultValue: message,
        params: {
          ...path,
          ...values,
        },
      });
      break;
    }
    case ZodIssueCode.invalid_intersection_types:
      message = t({
        key: "errors.invalid_intersection_types",
        ns,
        defaultValue: message,
        params: {
          ...path,
        },
      });
      break;
    case ZodIssueCode.not_multiple_of:
      message = t({
        key: "errors.not_multiple_of",
        ns,
        defaultValue: message,
        params: {
          ...path,
          multipleOf: issue.multipleOf,
        },
      });
      break;
    case ZodIssueCode.not_finite:
      message = t({
        key: "errors.not_finite",
        ns,
        defaultValue: message,
        params: {
          ...path,
        },
      });
      break;
    default:
  }

  return { message };
};

const initI18n = () => {
  const tolgee = Tolgee()
    .use(DevTools())
    .use(FormatSimple())
    .use(
      BackendFetch({
        getPath: ({ language, namespace }) => {
          return `/locales/${language}/${namespace}.json`;
        },
      }),
    )
    .init({
      language: "en",
      fallbackLanguage: "en",

      projectId: 1857,
      defaultNs: "translation",

      // for development
      apiUrl: import.meta.env.VITE_TOLGEE_API_URL,
      apiKey: import.meta.env.VITE_TOLGEE_API_KEY,
    });

  z.setErrorMap(makeZodI18nMap({ t: tolgee.t }));
  return tolgee;
};

export default initI18n;
