import { Outlet, ScrollRestoration, useLocation } from "react-router-dom";
import { Breadcrumbs } from "./Breadcrumbs/Breadcrumbs";
import { SidebarMenu } from "./SidebarMenu/SidebarMenu";
import { useEffect } from "react";
import { analytics } from "../../analytics";
import typewriter from "../../typewriter/segment";
import { PushNotificationClicked } from "@frontend/lyng/notifications/types";

const Layout = () => {
  const location = useLocation();

  useEffect(() => {
    analytics.page();

    // if location contains "push" as a parameter we need to track it
    if (location.search.includes("push")) {
      // push has structure of { Count: number, Type: string }
      const push = new URLSearchParams(location.search).get("push");
      const pushObj: PushNotificationClicked = push
        ? JSON.parse(push)
        : { Count: 0, Type: "Visit" };

      typewriter.pushNotificationClicked(pushObj);
    }
  }, [location]);

  return (
    <div className="h-screen w-screen">
      <SidebarMenu />
      <div className="flex h-full flex-1 flex-col md:pl-64">
        <main className="flex flex-1 py-6">
          <div className="mx-auto flex w-full flex-1 flex-col px-4 sm:px-6">
            <div className="mb-4 hidden lg:block">
              <Breadcrumbs />
            </div>
            {/* This element renders the the matche route from the Router */}
            <Outlet />
          </div>
        </main>
      </div>
      <ScrollRestoration />
    </div>
  );
};

export default Layout;
