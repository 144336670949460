import { useTranslate } from "@tolgee/react";
import Button from "../button";
import Modal from "../modal/Modal";

export type Props = {
  show: boolean;
  description?: string;
  onConfirm: () => void;
  loading: boolean;
  onCancel: () => void;
};
export const ConfirmDeleteModal = ({
  show,
  description,
  onCancel,
  onConfirm,
  loading,
}: Props) => {
  const { t } = useTranslate();

  return (
    <Modal show={show} onClose={onCancel}>
      <Modal.Title>{t("confirmDeleteModal.confirmation")}</Modal.Title>
      {description && <p>{description}</p>}
      <Modal.Footer>
        <Button
          variant="primary"
          text={t("ok").toString()}
          className="col-start-2"
          onClick={onConfirm}
        />
        <Button
          variant="secondary"
          text={t("cancel").toString()}
          className="col-start-1"
          onClick={onCancel}
          disabled={loading}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDeleteModal;
