import { useTranslate } from "@tolgee/react";

const Status = ({ active }: { active?: boolean }) => {
  const { t } = useTranslate();
  return (
    <div className="flex items-center gap-3">
      <div
        className={`h-2.5 w-2.5 rounded-full ${
          active ? "bg-green-500" : "bg-red-500"
        }`}
      />
      <p>{active ? t("active") : t("inactive")}</p>
    </div>
  );
};

export default Status;
