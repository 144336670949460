import { DatesSetArg, EventClickArg, EventInput } from "@fullcalendar/core";
import interactionPlugin from "@fullcalendar/interaction";
import luxon3plugin from "@fullcalendar/luxon3";
import FullCalendar from "@fullcalendar/react";
import { ResourceInput } from "@fullcalendar/resource";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import { DateTime } from "luxon";
import { useTolgee, useTranslate } from "@tolgee/react";
import { Link } from "react-router-dom";
import { useDateFormatter } from "../../../utils/dateUtils";
import { getTimeFormat } from "../../../utils/fullCalendarUtils";
import { useEffect, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { match } from "ts-pattern";

const generateLoadingSkeleton = (
  api: FullCalendar | null,
  resources: ResourceInput[],
) => {
  const loadingResources = [
    { id: "loading-1" },
    { id: "loading-2" },
    { id: "loading-3" },
    { id: "loading-4" },
    { id: "loading-5" },
    { id: "loading-6" },
    { id: "loading-7" },
    { id: "loading-8" },
    { id: "loading-9" },
    { id: "loading-10" },
  ];

  const scrollStart = DateTime.local().minus({ hour: 1 });
  const viewStart = api
    ? DateTime.fromJSDate(api?.getApi().view.currentStart)
    : DateTime.local().startOf("day");
  const viewEnd = api
    ? DateTime.fromJSDate(api?.getApi().view.currentEnd)
    : DateTime.local().plus({ days: 1 }).startOf("day");

  const scrolledView = viewStart.set({
    hour: scrollStart.hour,
    minute: scrollStart.minute,
  });

  const viewDuration = viewEnd.diff(viewStart, "days");
  const slotDurationModifier = match(viewDuration.days)
    .with(1, () => 1)
    .with(7, () => 6)
    .otherwise(() => 12);

  const eventDurations: {
    resourceIndex: number;
    start: number;
    end: number;
  }[] = [
    { resourceIndex: 1, start: 0, end: 6 },
    { resourceIndex: 2, start: 2, end: 5 },
    { resourceIndex: 2, start: 6, end: 9 },
    { resourceIndex: 3, start: 1, end: 7 },
    { resourceIndex: 4, start: -1, end: 3 },
    { resourceIndex: 4, start: 4, end: 6 },
    { resourceIndex: 5, start: 3, end: 10 },
    { resourceIndex: 6, start: 0, end: 3 },
    { resourceIndex: 6, start: 6, end: 7 },
    { resourceIndex: 7, start: 2, end: 7 },
    { resourceIndex: 8, start: 1, end: 3 },
    { resourceIndex: 8, start: 3.5, end: 4.5 },
    { resourceIndex: 9, start: 0, end: 6 },
    { resourceIndex: 10, start: 1.5, end: 3 },
  ];

  const loadingEvents = (
    resources.length ? resources : loadingResources
  ).flatMap((resource, i) => {
    const resourceEventDurations = eventDurations.filter(
      (r) => r.resourceIndex === i % 10,
    );
    return resourceEventDurations.map((eventDuration, j) => {
      return {
        id: `loading-${resource.id}-${j}`,
        resourceId: resource.id,
        start:
          scrolledView
            .plus({ hours: eventDuration.start * slotDurationModifier })
            .toISO() ?? undefined,
        end:
          scrolledView
            .plus({ hours: eventDuration.end * slotDurationModifier })
            .toISO() ?? undefined,
        backgroundColor: "transparent",
        borderColor: "transparent",
      };
    });
  });

  return { loadingResources, loadingEvents };
};

export type ResourcesEvents = [ResourceInput[], EventInput[]];

type Props = {
  resources: ResourceInput[];
  events: EventInput[];
  datesSet?: (arg: DatesSetArg) => void;
  eventClick?: (arg: EventClickArg) => void;
  absenceClick?: (id: string) => void;
  resourceLink?: (arg: ResourceInput) => string;
  timezone: string;
  initialDate?: string;
  highlight?: string;
  resourceLoading?: boolean;
  eventsLoading?: boolean;
};
export const ScheduleTimeline = ({
  resources,
  events,
  datesSet,
  eventClick,
  resourceLink,
  timezone,
  initialDate,
  highlight,
  resourceLoading: resourcesLoading,
  eventsLoading,
}: Props) => {
  const { t } = useTranslate();
  const tolgee = useTolgee();
  const { meridiem } = useDateFormatter();
  const fullCalRef = useRef<FullCalendar | null>(null);

  useEffect(() => {
    if (
      initialDate &&
      new Date(initialDate).getTime() !==
        fullCalRef.current?.getApi().getDate().getTime() &&
      fullCalRef.current
    ) {
      fullCalRef.current.getApi().gotoDate(initialDate);
    }
  }, [initialDate]);

  const { loadingEvents, loadingResources } = generateLoadingSkeleton(
    fullCalRef.current,
    resources,
  );

  return (
    <FullCalendar
      ref={fullCalRef}
      timeZone={timezone}
      slotLabelFormat={getTimeFormat(meridiem)}
      views={{
        dayView: {
          type: "resourceTimeline",
          duration: { day: 1 },
          slotDuration: { hours: 1 },
        },
        weekView: {
          type: "resourceTimeline",
          duration: { weeks: 1 },
          slotDuration: { hours: 6 },
        },
        monthView: {
          type: "resourceTimeline",

          duration: { months: 1 },
          slotDuration: { hours: 12 },
        },
      }}
      locale={tolgee.getLanguage()}
      schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
      resources={resourcesLoading ? loadingResources : resources}
      events={eventsLoading ? loadingEvents : events}
      // interactionPlugin prevents weird artifact on click
      plugins={[resourceTimelinePlugin, interactionPlugin, luxon3plugin]}
      initialView="resourceTimelineDay"
      headerToolbar={{
        left: "prev,next,today",
        center: "title",
        right: "monthView,weekView,resourceTimelineDay",
      }}
      resourceAreaWidth="20%"
      resourceAreaHeaderContent=" "
      height="auto"
      slotMinWidth={120}
      eventMinWidth={15}
      datesSet={datesSet}
      eventClick={eventClick}
      resourceLabelClassNames="h-8 min-h-full"
      resourceLaneClassNames="h-8 min-h-full"
      slotDuration="00:30:00"
      nowIndicator={true}
      now={highlight}
      scrollTime={DateTime.local().minus({ hour: 1 }).toFormat("HH:mm:ss")}
      resourceOrder={"title"}
      buttonText={{
        today: t("calendar.today").toString(),
        month: t("calendar.month").toString(),
        week: t("calendar.week").toString(),
        day: t("calendar.day").toString(),
      }}
      initialDate={initialDate}
      resourceLabelContent={(arg) => {
        if (resourcesLoading) {
          return <Skeleton />;
        }
        return resourceLink ? (
          <Link to={resourceLink(arg.resource)}>{arg.resource.title}</Link>
        ) : (
          arg.resource.title
        );
      }}
      eventContent={
        eventsLoading
          ? (_arg) => {
              return (
                <Skeleton height={16} containerClassName="block leading-none" />
              );
            }
          : undefined
      }
    />
  );
};
