import Modal from "@frontend/lyng/modal/Modal";
import { Paragraph } from "@frontend/lyng/typography";
import { useTranslate } from "@tolgee/react";
import { Button } from "@frontend/lyng/button";
import { DateTime } from "luxon";
import { errorToToastMessage } from "../../utils/toastUtils";
import toast from "react-hot-toast";
import { useVisitsAssignVisitorsByLabelIdMutation } from "../../api/generated/graphql";

type Props = {
  visitAmount: number;
  conflict: boolean;
  noCaregiver: boolean;
  show: boolean;
  onSave?: () => void;
  onCancel: () => void;
  dateRange: { from: DateTime; to: DateTime } | null;
  selectedLabelId: string;
  selectedCaregiverId: string | null;
};

export const BulkUpdateModal = ({
  visitAmount,
  conflict,
  noCaregiver,
  show,
  onSave,
  onCancel,
  dateRange,
  selectedLabelId,
  selectedCaregiverId,
}: Props) => {
  const { t } = useTranslate();
  const visitString =
    visitAmount > 1
      ? t("schedule.bulkUpdateModal.visits")
      : t("schedule.bulkUpdateModal.visit");
  const [visitsAssignVisitorsByLabelId] =
    useVisitsAssignVisitorsByLabelIdMutation();

  const handleBulkUpdateClick = () => {
    const promise = visitsAssignVisitorsByLabelId({
      variables: {
        from: dateRange?.from.toISO() ?? "",
        to: dateRange?.to.toISO() ?? "",
        labelId: selectedLabelId ?? "",
        visitorIds: selectedCaregiverId ? [selectedCaregiverId] : [],
      },
    });

    return toast
      .promise(promise, {
        loading:
          visitAmount > 1
            ? t("careRecipientSchedule.updatingVisits")
            : t("careRecipientSchedule.updatingVisit"),
        success: () =>
          visitAmount > 1
            ? t("careRecipientSchedule.successVisits")
            : t("careRecipientSchedule.successVisit"),
        error:
          dateRange?.from ?? "" < DateTime.now().toISO()
            ? t("cantChangePastVisits")
            : errorToToastMessage,
      })
      .finally(() => onSave && onSave());
  };

  return (
    <Modal show={show}>
      <Modal.Title>
        {t("schedule.bulkUpdateModal.updateCaregiver")}
        <Paragraph size="m">{`${visitAmount} ${visitString}`}</Paragraph>
      </Modal.Title>
      {conflict && (
        <div>
          <Modal.Note variant="info">
            <Paragraph size="m">
              {t("schedule.bulkUpdateModal.conflict")}
            </Paragraph>
          </Modal.Note>
        </div>
      )}
      {noCaregiver && (
        <div>
          <Modal.Note variant="warning">
            <Paragraph size="m">
              {t("schedule.bulkUpdateModal.noCaregiver")}
            </Paragraph>
          </Modal.Note>
        </div>
      )}
      <Modal.Footer>
        <Button
          text={t("cancel")}
          variant="secondary"
          onClick={onCancel}
        ></Button>
        <Button text={t("save")} onClick={handleBulkUpdateClick}></Button>
      </Modal.Footer>
    </Modal>
  );
};
