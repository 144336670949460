import {
  Weekday,
  useActivityScheduleMutation,
  useActivityUnscheduleMutation,
} from "../../../api/generated/graphql";
import { Activity } from "../../../types/index";
import { weekdaysArrayToString } from "@frontend/lyng/utils/dateUtils";
import { useDateFormatter } from "../../../utils/dateUtils";
import classNames from "classnames";
import { DateTime } from "luxon";
import { useTranslate } from "@tolgee/react";
import Skeleton from "react-loading-skeleton";
import Button from "../button";
import toast from "react-hot-toast";
import { errorToToastMessage } from "../../../utils/toastUtils";
import { match } from "ts-pattern";
import { useState } from "react";
import { SimpleModal } from "..";

type ActivityButtonType = "ADD" | "REMOVE" | "EDIT";
type ActivityButtonProps = {
  activity: Activity;
  visitDefinitionId?: string;
  type: ActivityButtonType;
  onEdit?: (activity: Activity) => void;
};

const ActivityButton = ({
  activity,
  visitDefinitionId,
  type,
  onEdit,
}: ActivityButtonProps) => {
  const { t } = useTranslate();
  const [activityScheduleMutation, { loading: activityScheduleLoading }] =
    useActivityScheduleMutation({
      refetchQueries: ["ActivitiesByCareRecipientId", "VisitById"],
    });
  const [activityUnscheduleMutation, { loading: activityUnscheduleLoading }] =
    useActivityUnscheduleMutation({
      refetchQueries: ["ActivitiesByCareRecipientId", "VisitById"],
    });

  const [confirmUnschedule, setConfirmUnschedule] = useState(false);

  const handleSchedule = () => {
    if (!visitDefinitionId) {
      return;
    }
    const promise = activityScheduleMutation({
      variables: {
        input: {
          visitDefinitionId: visitDefinitionId,
          activityId: activity.id,
        },
      },
    });
    return toast.promise(promise, {
      loading: t("activities.assigningActivitiesToVisit"),
      success: t("activities.successAssignActivityToVisit"),
      error: (err) => errorToToastMessage(err),
    });
  };

  const handleUnschedule = () => {
    if (!visitDefinitionId) {
      return;
    }
    const promise = activityUnscheduleMutation({
      variables: {
        visitDefinitionId: visitDefinitionId,
        activityId: activity.id,
      },
    }).then(() => {
      setConfirmUnschedule(false);
    });

    return toast.promise(promise, {
      loading: t("activities.removingActivitiesFromVisit"),
      success: t("activities.successRemoveActivityFromVisit"),
      error: (err) => errorToToastMessage(err),
    });
  };

  const handleClick = () => {
    match(type)
      .with("ADD", () => handleSchedule())
      .with("REMOVE", () => setConfirmUnschedule(true))
      .with("EDIT", () => onEdit && onEdit(activity))
      .exhaustive();
  };

  const text = match<ActivityButtonType, string>(type)
    .with("ADD", () => t("activities.scheduleActivity"))
    .with("REMOVE", () => t("activities.unscheduleActivity"))
    .with("EDIT", () => t("activities.editActivity"))
    .exhaustive();

  return (
    <>
      <Button
        className="min-w-fit"
        variant="secondary"
        size="xs"
        text={text}
        onClick={() => handleClick()}
        loading={activityScheduleLoading || activityUnscheduleLoading}
        disabled={activityScheduleLoading || activityUnscheduleLoading}
      />
      <SimpleModal
        show={confirmUnschedule}
        title={t("activities.confirmUnscheduleActivityFromVisitHeader")}
        onAccept={() => handleUnschedule()}
        onClose={() => setConfirmUnschedule(false)}
      >
        {t("activities.confirmUnscheduleActivityFromVisit")}
      </SimpleModal>
    </>
  );
};

type Props = {
  activities: Activity[];
  visitDefinitionId?: string;
  headerText: string;
  headerButton?: { text: string; onClick: () => void };
  activityButtonType?: ActivityButtonType | null;
  onEdit?: (activity: Activity) => void;
  loading: boolean;
};

export const ActivitiesTable = ({
  activities,
  visitDefinitionId,
  headerText,
  headerButton,
  loading,
  activityButtonType,
  onEdit,
}: Props) => {
  const { t } = useTranslate();
  const { formatTime } = useDateFormatter();

  const weekdayTranslation = (weekdays: Weekday[] | null) => {
    // Already translated in utils
    return weekdaysArrayToString(weekdays ?? [], t) ?? t("everyDay");
  };

  const getTimeOfDayStart = (time?: string | null) => {
    if (!time) return "";
    return formatTime(DateTime.fromFormat(time, "HH:mm"));
  };

  return (
    <div className="px-7 pb-8 pt-3">
      <div className="flex justify-between border-b border-gray-200 pb-3">
        <h3 className="text-lg font-semibold">{headerText}</h3>
        {headerButton && (
          <Button
            variant="secondary"
            size="sm"
            onClick={headerButton.onClick}
            text={headerButton.text}
          />
        )}
      </div>
      {loading ? (
        <Skeleton count={3} height={44} containerTestId="skeleton-loader" />
      ) : (
        <table className="min-w-full divide-y divide-gray-300">
          <tbody className="divide-y divide-gray-200 bg-white">
            {activities.map((activity) => (
              <tr key={activity.id} className="">
                <td
                  data-testid="activity-title"
                  className="max-w-0 py-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none "
                >
                  {activity.title}
                  <dl className="font-normal lg:hidden">
                    <dt className="sr-only">{t("weekdays.weekdays")}</dt>
                    <dd className="mt-1 truncate text-gray-700">
                      {weekdayTranslation(activity.weekdays)}
                    </dd>
                    {activity.timeOfDayStart !== null && (
                      <>
                        <dt className="sr-only sm:hidden">Time</dt>
                        <dd className="mt-1 truncate text-gray-500 sm:hidden">
                          {`${getTimeOfDayStart(activity.timeOfDayStart)} `}
                        </dd>
                      </>
                    )}
                  </dl>
                </td>
                <td
                  data-testid="activity-description"
                  className="hidden max-w-0 overflow-hidden overflow-ellipsis whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell lg:w-1/2"
                >
                  {activity.description}
                </td>
                <td
                  data-testid="activity-time"
                  className={classNames(
                    { "text-right": !activityButtonType },
                    "hidden px-3 py-4 text-sm text-gray-500 sm:table-cell",
                  )}
                >
                  {`${getTimeOfDayStart(activity.timeOfDayStart)}`}
                </td>
                {activityButtonType && (
                  <td
                    data-testid="activity-button"
                    className="py-4 pl-3 text-right text-sm font-medium"
                  >
                    <ActivityButton
                      activity={activity}
                      visitDefinitionId={visitDefinitionId}
                      type={activityButtonType}
                      onEdit={onEdit}
                    />
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};
