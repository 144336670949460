type Props = {
  className?: string;
  width?: number;
  height?: number;
};
export const MenuIcon = ({ className, width = 24, height = 24 }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
  >
    <path
      d="M13.7999 19.2H2.3999M21.5999 12H2.3999M21.5999 4.79999H2.3999"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
