import classNames from "classnames";
import { ReactNode } from "react";
import ReactSelect, {
  FormatOptionLabelMeta,
  GetOptionLabel,
  GetOptionValue,
  GroupBase,
  InputActionMeta,
  InputProps,
  OnChangeValue,
  Options,
  OptionsOrGroups,
  PropsValue,
  StylesConfig,
  components,
} from "react-select";
import ReactCreatableSelect from "react-select/creatable";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import { Accessors } from "react-select/dist/declarations/src/useCreatable";

const BaseInput = components.Input;
function VisibleInput<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(props: InputProps<Option, IsMulti, Group>) {
  return <components.Input {...props} isHidden={false} />;
}

function styles<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(props: Props<Option, IsMulti, Group>): StylesConfig<Option, IsMulti, Group> {
  return {
    container: (base) => ({
      ...base,
    }),
    control: (base) => ({
      ...base,
      height: "3rem",
      borderRadius: "0.75rem",
      borderColor: props.invalid ? "#fca5a5" : base.borderColor,
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0 1rem",
    }),
    singleValue: (base) => ({
      ...base,
      margin: 0,
    }),
    input: (base) => ({
      ...base,
      "input:focus": {
        boxShadow: "none",
      },
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };
}

type Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> = {
  id?: string;
  className?: string;
  dataTestId?: string;
  value: PropsValue<Option> | undefined;
  options: OptionsOrGroups<Option, Group>;
  name?: string | undefined;
  placeholder?: React.ReactNode;
  isDisabled?: boolean;
  onChange: (value: OnChangeValue<Option, IsMulti>) => void;
  onBlur: () => void;
  getOptionLabel?: GetOptionLabel<Option>;
  getOptionValue?: GetOptionValue<Option>;
  isMulti?: IsMulti;
  isClearable?: boolean;
  formatGroupLabel?: (group: Group) => React.ReactNode;
  filterOption?:
    | ((option: FilterOptionOption<Option>, inputValue: string) => boolean)
    | null;
  invalid?: boolean;
  inputValue?: string;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  editable?: boolean;
  isLoading?: boolean;
  formatOptionLabel?: (
    data: Option,
    formatOptionLabelMeta: FormatOptionLabelMeta<Option>
  ) => ReactNode;
};

function Select<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(props: Props<Option, IsMulti, Group>) {
  const { dataTestId, className, ...rest } = props;
  return (
    <div
      data-testid={dataTestId}
      className={classNames(
        "relative mx-1 max-w-lg rounded-xl shadow-sm sm:max-w-xs",
        className
      )}
    >
      <ReactSelect {...rest} styles={styles(props)} />
    </div>
  );
}

type CreatableProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
> = Props<Option, IsMulti, Group> & {
  formatCreateLabel?: (inputValue: string) => React.ReactNode;
  onCreateOption?: (inputValue: string) => void;
  isValidNewOption?: (
    inputValue: string,
    value: Options<Option>,
    options: OptionsOrGroups<Option, Group>,
    accessors: Accessors<Option>
  ) => boolean;
};

function CreatableSelect<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(props: CreatableProps<Option, IsMulti, Group>) {
  return (
    <div
      data-testid={props.dataTestId}
      className="relative mx-1 max-w-lg rounded-md shadow-sm sm:max-w-xs"
    >
      <ReactCreatableSelect
        {...props}
        controlShouldRenderValue={!props.editable}
        components={{ Input: props.editable ? VisibleInput : BaseInput }}
        styles={styles(props)}
      />
    </div>
  );
}

export { CreatableSelect, Select };
