import { Controller, useForm } from "react-hook-form";
import { useTranslate } from "@tolgee/react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  ResourceType,
  RoleType,
  UserByIdQuery,
} from "../../../api/generated/graphql";
import { Button } from "../../../components/common";
import { Form, OfficeSelect } from "../../../components/formfields";
import { FormGroup, Select, Checkbox } from "@frontend/lyng/forms";
import { useCallback, useMemo } from "react";
import { match } from "ts-pattern";
import { SlideOver } from "@frontend/lyng/slideOver";
import { SingleValue } from "react-select";
import { stringToRoleType } from "./utils";
import { useCareContext } from "../../../../src/providers";
import { GroupLabel } from "@frontend/lyng/forms/select/GroupLabel";

const validationSchema = z
  .object({
    role: z.object({
      value: z.nativeEnum(RoleType),
      label: z.string(),
    }),
    office: z
      .object({
        id: z.string(),
        name: z.string(),
      })
      .optional(),
    status: z.boolean(),
    organizationUnit: z
      .object({
        id: z.string(),
        type: z.nativeEnum(ResourceType),
        label: z.string(),
      })
      .optional(),
  })
  .superRefine((data, ctx) => {
    if (
      (data.role.value === RoleType.CareRecipient ||
        data.role.value === RoleType.Caregiver) &&
      (!data.office || !data.office.id || !data.office.name)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Office is required for Care recipient or Caregiver roles",
        path: ["office"],
      });
    }
    if (
      (data.role.value === RoleType.Admin ||
        data.role.value === RoleType.SuccessManager ||
        data.role.value === RoleType.SchedulingManager) &&
      (!data.organizationUnit ||
        !data.organizationUnit.id ||
        !data.organizationUnit.type)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          "Organization Unit is required for Admin, Success manager, or Scheduling manager roles",
        path: ["organizationUnit"],
      });
    }
  });

export type RolesInput = z.infer<typeof validationSchema>;

type Props = {
  user?: UserByIdQuery["userById"];
  onRoleSubmit: (data: RolesInput) => void;
  roleToEdit?: RolesInput | null;
  setShowAddNewRolesForm: (value: boolean) => void;
  isNewlyAdded: boolean;
  careGiverRoles: { office: { id: string; name: string } }[];
  careRecipientRoles: { office: { id: string; name: string } }[];
  careTeamRoles: {
    organizationUnit?: { id: string; type: ResourceType };
  }[];
};

export const RolesForm = ({
  user,
  onRoleSubmit,
  roleToEdit,
  setShowAddNewRolesForm,
  isNewlyAdded,
  careGiverRoles,
  careRecipientRoles,
  careTeamRoles,
}: Props) => {
  const { t } = useTranslate();

  const { viewer } = useCareContext().state;
  const data = viewer?.tenantAccess;

  const handleClose = () => {
    setShowAddNewRolesForm(false);
  };

  const isCareTeamMember = (selectedRole: RoleType) =>
    selectedRole === RoleType.Admin ||
    selectedRole === RoleType.SuccessManager ||
    selectedRole === RoleType.SchedulingManager;

  const {
    control,
    setValue,
    register,
    handleSubmit,
    clearErrors,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<RolesInput>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      role: roleToEdit?.role || undefined,
      office: roleToEdit?.office || { id: "", name: "" },
      organizationUnit: roleToEdit?.organizationUnit || undefined,
      status: roleToEdit?.status ?? true,
    },
  });
  const selectedRole = watch("role")?.value;

  const savedCaregiverOffices = useMemo(() => {
    const savedRoles = user?.caregiverRoles || [];
    const unsavedRoles = careGiverRoles.map((field) => field?.office?.id);

    return [
      ...new Set([
        ...savedRoles.map((role) => role.office?.id),
        ...unsavedRoles,
      ]),
    ].filter((id): id is string => !!id);
  }, [user, careGiverRoles]);

  const savedCareRecipientOffices = useMemo(() => {
    const savedRoles = user?.careRecipientRoles || [];
    const unsavedRoles = careRecipientRoles.map((field) => field.office?.id);

    return [
      ...new Set([
        ...savedRoles.map((role) => role.office?.id),
        ...unsavedRoles,
      ]),
    ].filter((id): id is string => !!id);
  }, [user, careRecipientRoles]);

  const getFilteredOfficeOptions = useCallback(
    (roleType: RoleType) => {
      if (roleType === RoleType.Caregiver) {
        return data?.offices.filter(
          (office) => !savedCaregiverOffices.includes(office.id),
        );
      } else if (roleType === RoleType.CareRecipient) {
        return data?.offices.filter(
          (office) => !savedCareRecipientOffices.includes(office.id),
        );
      }
      return data?.offices || [];
    },
    [data?.offices, savedCaregiverOffices, savedCareRecipientOffices],
  );

  const savedOrganizationUnits = useMemo(() => {
    const savedRoles = user?.careTeamMemberRoles || [];
    const unsavedRoles = careTeamRoles.map(
      (field) => field.organizationUnit?.id,
    );

    return [
      ...new Set([
        ...savedRoles.map((role) => role.organizationUnit?.id),
        ...unsavedRoles,
      ]),
    ].filter((id): id is string => !!id);
  }, [user, careTeamRoles]);

  const filteredUnitOptions = useMemo(() => {
    const flattenOptions = (
      options: { id: string; name: string }[],
      type: ResourceType,
    ) =>
      options
        .map((option) => ({
          id: option.id,
          type,
          label: option.name,
        }))
        .filter((option) => !savedOrganizationUnits.includes(option.id));

    return [
      {
        label: t("organization.label").toString(),
        options: flattenOptions(
          [{ id: data?.id ?? "", name: data?.name ?? "" }],
          ResourceType.Tenant,
        ),
      },
      {
        label: t("division.label").toString(),
        options: flattenOptions(data?.divisions ?? [], ResourceType.Division),
      },
      {
        label: t("region.label").toString(),
        options: flattenOptions(data?.regions ?? [], ResourceType.Region),
      },
      {
        label: t("office.label").toString(),
        options: flattenOptions(
          getFilteredOfficeOptions(selectedRole) ?? [],
          ResourceType.Office,
        ),
      },
    ].flat();
  }, [data, savedOrganizationUnits, selectedRole, getFilteredOfficeOptions, t]);

  const translateRoleType = useCallback(
    (roleType: RoleType) => {
      return match(roleType)
        .with(RoleType.Contact, () => t("roleType.CONTACT"))
        .with(RoleType.Caregiver, () => t("roleType.CAREGIVER"))
        .with(RoleType.CareRecipient, () => t("roleType.CARE_RECIPIENT"))
        .with(RoleType.SchedulingManager, () =>
          t("roleType.SCHEDULING_MANAGER"),
        )
        .with(RoleType.SuccessManager, () => t("roleType.SUCCESS_MANAGER"))
        .with(RoleType.Admin, () => t("roleType.ADMIN"))
        .exhaustive();
    },
    [t],
  );

  const roleOptions = useMemo(() => {
    return [
      RoleType.Admin,
      RoleType.SuccessManager,
      RoleType.SchedulingManager,
      RoleType.Caregiver,
      RoleType.CareRecipient,
    ].map((role) => ({
      value: role,
      label: translateRoleType(role),
    }));
  }, [translateRoleType]);

  const submitRoleHandler = async (values: RolesInput) => {
    if (isCareTeamMember(selectedRole)) {
      if (!values.organizationUnit) {
        return;
      }
    }
    const output: RolesInput = {
      role: values.role,
      office: values.office,
      status: values.status,
      organizationUnit: values.organizationUnit,
    };
    onRoleSubmit(output);
  };

  const handleRoleChange = (
    selectedOption: SingleValue<{
      value: string;
      label: string;
    }>,
  ) => {
    if (selectedOption) {
      const roleType = stringToRoleType(selectedOption.value);
      if (roleType) {
        setValue("office", { id: "", name: "" });
        clearErrors("office");
        clearErrors("organizationUnit");
      }
    }
  };

  return (
    <div>
      <SlideOver.DetailSection>
        <FormGroup label={t("users.role")} labelFor="role">
          <Controller
            control={control}
            name="role"
            render={({ field }) => (
              <Select
                options={roleOptions}
                name={field.name}
                isMulti={false}
                placeholder={t("users.chooseRole")}
                onChange={(selectedOption) => {
                  field.onChange(selectedOption);
                  handleRoleChange(selectedOption);
                }}
                onBlur={field.onBlur}
                value={field.value || roleToEdit?.role || null}
                displayView={!!roleToEdit && !isNewlyAdded}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => option.label}
                errorMessage={errors.role?.message}
              />
            )}
          />
        </FormGroup>
        {(selectedRole === RoleType.CareRecipient ||
          selectedRole === RoleType.Caregiver) && (
          <FormGroup label={t("users.office")} labelFor="office">
            <Controller
              control={control}
              name="office"
              render={({ field }) => (
                <OfficeSelect
                  name={field.name}
                  filteredOptions={getFilteredOfficeOptions(selectedRole)}
                  onChange={(selectedOffice) => field.onChange(selectedOffice)}
                  onBlur={field.onBlur}
                  displayView={!!roleToEdit && !isNewlyAdded}
                  value={field.value?.id || null}
                  allowedRoles={[
                    RoleType.Admin,
                    RoleType.SuccessManager,
                    RoleType.SchedulingManager,
                  ]}
                  invalid={!!errors.office}
                  errorMessage={errors.office?.message}
                />
              )}
            />
          </FormGroup>
        )}

        {(isCareTeamMember(selectedRole) || roleToEdit?.organizationUnit) && (
          <FormGroup label={t("organization.unit")} labelFor="organizationUnit">
            <Controller
              control={control}
              name="organizationUnit"
              render={({ field }) => (
                <Select
                  name={field.name}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  value={field.value && field.value}
                  id={"organization.unit.select"}
                  placeholder={t(
                    "teamMembers.selectOrganizationUnit",
                  ).toString()}
                  options={filteredUnitOptions}
                  isMulti={false}
                  displayView={!!roleToEdit && !isNewlyAdded}
                  isClearable={false}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.id}
                  formatGroupLabel={(data) => <GroupLabel data={data} />}
                  errorMessage={errors.organizationUnit?.message}
                />
              )}
            />
          </FormGroup>
        )}
        {roleToEdit && !isNewlyAdded && (
          <FormGroup label={t("status")} labelFor="status">
            <Checkbox {...register("status")} id="status" label={t("active")} />
          </FormGroup>
        )}
      </SlideOver.DetailSection>
      <Form.StickyFooter>
        <Button
          variant="secondary"
          onClick={handleClose}
          text={t("users.back")}
          disabled={isSubmitting}
        />
        <Button
          variant="primary"
          text={t("save")}
          onClick={handleSubmit(submitRoleHandler)}
          disabled={isSubmitting}
        />
      </Form.StickyFooter>
    </div>
  );
};
