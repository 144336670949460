import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { Fragment, ReactNode } from "react";
import Button from "../button";
import type { Variant } from "../button";
import { ChevronDownFilled } from "@frontend/lyng/assets/svg/Chevron";
import { Label } from "@frontend/lyng/typography";

type OptionProp = {
  label: string;
  icon?: ReactNode;
  onClick: () => void;
};

type Props = {
  options: OptionProp[];
  leftIcon?: React.ElementType;
  rightIcon?: React.ElementType;
  text?: string;
  buttonVariant?: Variant;
};

const Dropdown = ({
  options,
  text,
  leftIcon,
  rightIcon,
  buttonVariant = "secondary",
}: Props) => {
  const leftIconWithDefault =
    !leftIcon && !text ? EllipsisVerticalIcon : leftIcon;
  const rightIconWithDefault =
    !rightIcon && text ? ChevronDownFilled : rightIcon;

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          as={Button}
          variant={buttonVariant}
          leftIcon={leftIconWithDefault}
          rightIcon={rightIconWithDefault}
          text={text}
        />
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right overflow-hidden rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="flex flex-col gap-1 py-1">
            {options.map((option) => (
              <Menu.Item key={option.label}>
                {({ active }) => (
                  <button
                    onClick={option.onClick}
                    className={classNames(
                      "block h-12 w-full cursor-pointer rounded p-3 text-sm text-left",
                      {
                        "bg-primary-100": active,
                      }
                    )}
                  >
                    <Label size="s" className="cursor-pointer">
                      {option.label}
                    </Label>
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default Dropdown;
