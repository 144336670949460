type Props = {
  className?: string;
  width?: number;
  height?: number;
  filled?: boolean;
};

export const UserIcon = ({
  className,
  width = 16,
  height = 16,
  filled = false,
}: Props) => {
  return (
    <div>
      {filled ? (
        <svg
          className={className}
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* Filled icon */}
          <path
            d="M1.6001 13.6749C1.6001 11.1579 3.70295 9.11743 8.0001 9.11743C12.2972 9.11743 14.4001 11.1579 14.4001 13.6749C14.4001 14.0754 14.1079 14.4 13.7475 14.4H2.25265C1.89225 14.4 1.6001 14.0754 1.6001 13.6749Z"
            fill="currentColor"
          />
          <path
            d="M10.4001 3.99998C10.4001 5.32546 9.32558 6.39997 8.0001 6.39997C6.67461 6.39997 5.6001 5.32546 5.6001 3.99998C5.6001 2.67449 6.67461 1.59998 8.0001 1.59998C9.32558 1.59998 10.4001 2.67449 10.4001 3.99998Z"
            fill="currentColor"
          />
          <path
            d="M1.6001 13.6749C1.6001 11.1579 3.70295 9.11743 8.0001 9.11743C12.2972 9.11743 14.4001 11.1579 14.4001 13.6749C14.4001 14.0754 14.1079 14.4 13.7475 14.4H2.25265C1.89225 14.4 1.6001 14.0754 1.6001 13.6749Z"
            stroke="currentColor"
            strokeWidth="1.5"
          />
          <path
            d="M10.4001 3.99998C10.4001 5.32546 9.32558 6.39997 8.0001 6.39997C6.67461 6.39997 5.6001 5.32546 5.6001 3.99998C5.6001 2.67449 6.67461 1.59998 8.0001 1.59998C9.32558 1.59998 10.4001 2.67449 10.4001 3.99998Z"
            stroke="currentColor"
            strokeWidth="1.5"
          />
        </svg>
      ) : (
        <svg
          className={className}
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* Outlined icon */}
          <path
            d="M1.6001 13.6749C1.6001 11.1579 3.70295 9.11744 8.0001 9.11744C12.2972 9.11744 14.4001 11.1579 14.4001 13.6749C14.4001 14.0754 14.1079 14.4 13.7475 14.4H2.25265C1.89225 14.4 1.6001 14.0754 1.6001 13.6749Z"
            stroke="currentColor"
            strokeWidth="1.5"
          />
          <path
            d="M10.4001 3.99998C10.4001 5.32546 9.32558 6.39997 8.0001 6.39997C6.67461 6.39997 5.6001 5.32546 5.6001 3.99998C5.6001 2.67449 6.67461 1.59998 8.0001 1.59998C9.32558 1.59998 10.4001 2.67449 10.4001 3.99998Z"
            stroke="currentColor"
            strokeWidth="1.5"
          />
        </svg>
      )}
    </div>
  );
};

// export default HomeIcon;
