type Props = {
  className?: string;
  width?: number;
  height?: number;
  filled?: boolean;
};

export const HomeIcon = ({
  className,
  width = 16,
  height = 16,
  filled = false,
}: Props) => {
  return (
    <div>
      {filled ? (
        <svg
          className={className}
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* Filled icon */}

          <path
            d="M1.6001 6.16253C1.6001 5.89985 1.7338 5.65341 1.9589 5.50118L7.50557 1.75017C7.80164 1.54995 8.19855 1.54995 8.49462 1.75017L14.0413 5.50118C14.2664 5.65341 14.4001 5.89985 14.4001 6.16253V13.1827C14.4001 13.855 13.827 14.4 13.1201 14.4H2.8801C2.17317 14.4 1.6001 13.855 1.6001 13.1827V6.16253Z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="1.5"
          />
        </svg>
      ) : (
        <svg
          className={className}
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* Outlined icon */}
          <path
            d="M1.6001 6.16253C1.6001 5.89985 1.7338 5.65341 1.9589 5.50118L7.50557 1.75017C7.80164 1.54995 8.19855 1.54995 8.49462 1.75017L14.0413 5.50118C14.2664 5.65341 14.4001 5.89985 14.4001 6.16253V13.1827C14.4001 13.855 13.827 14.4 13.1201 14.4H2.8801C2.17317 14.4 1.6001 13.855 1.6001 13.1827V6.16253Z"
            stroke="currentColor"
            strokeWidth="1.5"
          />
        </svg>
      )}
    </div>
  );
};

// export default HomeIcon;
