import { useLabelCreateMutation } from "../../../../api/generated/graphql";
import { useTranslate } from "@tolgee/react";
import { useNavigate } from "react-router-dom";
import { FormOutput, LabelForm } from "./labelForm";
import { toast } from "react-hot-toast";
import { errorToToastMessage } from "../../../../utils/toastUtils";
import { SlideOver } from "@frontend/lyng/slideOver";

export const NewLabelModal = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();

  const [labelCreate] = useLabelCreateMutation();

  const handleSubmit = (values: FormOutput) => {
    const promise = labelCreate({
      variables: {
        input: {
          name: values.name,
          officeId: values.officeId,
        },
      },
      refetchQueries: ["labelsByOfficeIds"],
    });

    return toast.promise(promise, {
      loading: t("labels.creatingLabel"),
      success: () => t("labels.labelCreated"),
      error: errorToToastMessage,
    });
  };

  return (
    <SlideOver.WithIntercomHider show onClose={() => navigate("..")}>
      <SlideOver.Title>{t("labels.addLabel").toString()}</SlideOver.Title>
      <LabelForm
        onSubmit={(values) => handleSubmit(values).then(() => navigate(".."))}
      />
    </SlideOver.WithIntercomHider>
  );
};
