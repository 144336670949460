import {
  CareTeamMemberRoleType,
  ResourceType,
  RoleType,
} from "../../../api/generated/graphql";
import { match } from "ts-pattern";
import { UseTranslateResult } from "@tolgee/react";

export const roleTypeToCareTeamMemberRoleType = (
  role: RoleType,
): CareTeamMemberRoleType | undefined => {
  return match(role)
    .with(
      RoleType.SchedulingManager,
      () => CareTeamMemberRoleType.SchedulingManager,
    )
    .with(RoleType.SuccessManager, () => CareTeamMemberRoleType.SuccessManager)
    .with(RoleType.Admin, () => CareTeamMemberRoleType.Admin)
    .otherwise(() => undefined);
};

export const CareTeamMemberRoleTypeToRoleType = (
  role: CareTeamMemberRoleType,
): RoleType | undefined => {
  return match(role)
    .with(
      CareTeamMemberRoleType.SchedulingManager,
      () => RoleType.SchedulingManager,
    )
    .with(CareTeamMemberRoleType.SuccessManager, () => RoleType.SuccessManager)
    .with(CareTeamMemberRoleType.Admin, () => RoleType.Admin)
    .otherwise(() => undefined);
};

export const mapToResourceType = (type: string): ResourceType | undefined => {
  switch (type) {
    case "Division":
      return ResourceType.Division;
    case "Office":
      return ResourceType.Office;
    case "Region":
      return ResourceType.Region;
    case "Tenant":
      return ResourceType.Tenant;
    default:
      return undefined;
  }
};

export const translateRoleType = (
  roleType: CareTeamMemberRoleType,
  t: UseTranslateResult["t"],
): string => {
  return match(roleType)
    .with(CareTeamMemberRoleType.SchedulingManager, () =>
      t("roleType.SCHEDULING_MANAGER"),
    )
    .with(CareTeamMemberRoleType.SuccessManager, () =>
      t("roleType.SUCCESS_MANAGER"),
    )
    .with(CareTeamMemberRoleType.Admin, () => t("roleType.ADMIN"))
    .exhaustive();
};

// Translates a ResourceType enum value to a localized string
export const translateResourceType = (
  resourceType: ResourceType,
  t: UseTranslateResult["t"],
): string => {
  return match(resourceType)
    .with(ResourceType.CareRecipient, () => t("resourceType.CARE_RECIPIENT"))
    .with(ResourceType.Division, () => t("resourceType.DIVISION"))
    .with(ResourceType.Office, () => t("resourceType.OFFICE"))
    .with(ResourceType.Region, () => t("resourceType.REGION"))
    .with(ResourceType.Tenant, () => t("resourceType.TENANT"))
    .exhaustive();
};

// Converts a role string to RoleType
export const stringToRoleType = (role: string): RoleType | undefined => {
  switch (role) {
    case "ADMIN":
      return RoleType.Admin;
    case "SUCCESS_MANAGER":
      return RoleType.SuccessManager;
    case "SCHEDULING_MANAGER":
      return RoleType.SchedulingManager;
    case "CAREGIVER":
      return RoleType.Caregiver;
    case "CARE_RECIPIENT":
      return RoleType.CareRecipient;
    default:
      return undefined;
  }
};
