import { Label } from "@frontend/lyng/typography";

type Props = {
  children: React.ReactNode;
};
const FormSectionRoot = ({ children }: Props) => {
  return (
    <div className="grid grid-cols-1 gap-x-3 gap-y-4 px-7 pb-8 pt-3 sm:grid-cols-[1fr,3fr]">
      {children}
    </div>
  );
};

type LabelProps = {
  id?: string;
  htmlFor?: string;
  children: React.ReactNode;
};
const FormSectionLabel = ({ id, htmlFor, children }: LabelProps) => {
  return (
    <div className="flex h-12 items-center">
      <Label size="m" id={id} htmlFor={htmlFor}>
        {children}
      </Label>
    </div>
  );
};

export const FormSection = Object.assign(FormSectionRoot, {
  Label: FormSectionLabel,
});
