import { EventApi, EventChangeArg, EventClickArg } from "@fullcalendar/core";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslate } from "@tolgee/react";
import { useNavigate } from "react-router-dom";

import {
  useVisitUpdateFollowingMutation,
  useVisitUpdateMutation,
  VisitUpdateFollowingInput,
  useEventsByCareRecipientIdLazyQuery,
} from "../../../api/generated/graphql";
import {
  Schedule,
  VisitExtendedProps,
  UpdateRecurringModal,
  TopActions,
  VisitInstance,
} from "../../../components/common";
import { getEndOfWeek, getStartOfWeek } from "@frontend/lyng/utils/dateUtils";
import { errorToToastMessage } from "../../../utils/toastUtils";
import useGetHolidays from "../../../utils/hooks/useGetHolidays";
import { useCareContext } from "../../../providers/CareProvider";
import {
  VisitCreationStartedSource,
  VisitDeletedRecurrence,
  VisitUpdatedSource,
  visitCreationStarted,
  visitUpdated,
} from "../../../typewriter/segment";

type Props = {
  careRecipient: {
    id: string;
  };
};

export const CareRecipientSchedule = ({ careRecipient }: Props) => {
  const {
    state: { viewer },
  } = useCareContext();
  const navigate = useNavigate();
  const { t } = useTranslate();

  const timezone = viewer?.tenantSettings.timezone ?? "UTC";

  // Triggers confirmation modal for editing recurring visit
  const [recurringVisitChange, setRecurringVisitChange] = useState<
    EventChangeArg | undefined
  >(undefined);

  // Current calendar view range
  const [dateRange, setDateRange] = useState<{
    from: string;
    to: string;
  } | null>(null);

  const [getEvents, { data, error, loading }] =
    useEventsByCareRecipientIdLazyQuery({
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    if (dateRange) {
      getEvents({
        variables: {
          careRecipientId: careRecipient.id,
          from: dateRange.from,
          to: dateRange.to,
        },
      });
    }
  }, [dateRange, getEvents, careRecipient.id]);

  const { holidays, loading: holidaysLoading } = useGetHolidays(
    dateRange?.from ?? getStartOfWeek(),
    dateRange?.to ?? getEndOfWeek(),
    // TODO: use office settings for care recipient
    viewer?.tenantSettings.country,
  );

  const [
    visitUpdateMutation,
    { loading: visitUpdateLoading, reset: visitUpdateReset },
  ] = useVisitUpdateMutation({
    refetchQueries: ["EventsByCareRecipientId", "EventsByCaregiverId"],
  });

  const [
    visitUpdateFollowingMutation,
    { loading: visitUpdateFollowingLoading, reset: visitUpdateFollowingReset },
  ] = useVisitUpdateFollowingMutation({
    refetchQueries: ["EventsByCareRecipientId", "EventsByCaregiverId"],
  });

  const getUpdateInput = (event: EventApi, following?: boolean) => {
    const extendedProps: VisitExtendedProps =
      event.extendedProps as VisitExtendedProps;

    const dtStart = DateTime.fromISO(event.startStr);
    // This is the end of the event instance from fullcalendar not the end of the recurrence
    const dtEnd = DateTime.fromISO(event.endStr);

    const input: VisitUpdateFollowingInput = {
      visitInstanceId: event.id,
      start: dtStart.toUTC().toISO() ?? "",
      durationMinutes: dtEnd.diff(dtStart, "minutes").minutes,
      visitorIds: extendedProps.visitorIds,
      labelIds: extendedProps.labelIds,
    };

    if (following) {
      input.end = extendedProps.recurrenceEnd;
      input.rRule = extendedProps.recurrences;
    }
    return input;
  };

  const handleUpdateVisit = (event: EventApi) => {
    const promise = visitUpdateMutation({
      variables: { input: getUpdateInput(event) },
    }).then(() => {
      setRecurringVisitChange(undefined);
      visitUpdated({
        care_recipient_id: careRecipient.id,
        recurrence: VisitDeletedRecurrence.This,
        source: VisitUpdatedSource.Calendar,
      });
    });

    toast.promise(promise, {
      loading: t("careRecipientSchedule.updatingVisit"),
      success: t("careRecipientSchedule.successVisit"),
      error: (err) => errorToToastMessage(err),
    });
  };

  const handleUpdateFollowingVisits = (event: EventApi) => {
    const promise = visitUpdateFollowingMutation({
      variables: { input: getUpdateInput(event, true) },
    }).then(() => {
      setRecurringVisitChange(undefined);
      visitUpdated({
        care_recipient_id: careRecipient.id,
        recurrence: VisitDeletedRecurrence.Following,
        source: VisitUpdatedSource.Calendar,
      });
    });

    toast.promise(promise, {
      loading: t("careRecipientSchedule.updatingVisits"),
      success: t("careRecipientSchedule.successVisits"),
      error: (err) => errorToToastMessage(err),
    });
  };

  const handleNewVisit = (path: string, source?: "calendar" | "button") => {
    navigate(`visits/new/${path}`);
    visitCreationStarted({
      care_recipient_id: careRecipient.id,
      source:
        source === "button"
          ? VisitCreationStartedSource.Button
          : VisitCreationStartedSource.Calendar,
    });
  };

  if (error) return <p>Error : {error.message}</p>;

  const isLoading =
    loading ||
    visitUpdateLoading ||
    visitUpdateFollowingLoading ||
    holidaysLoading;

  const topActions: TopActions = {
    header: t("careRecipientDetails.visits"),
    actions: [
      {
        text: t("absences.register"),
        onClick: () => navigate("absence"),
        variant: "secondary",
      },
      {
        text: `+ ${t("careRecipientDetails.addVisits")}`,
        onClick: () =>
          handleNewVisit(
            `${DateTime.now()
              .plus({ hour: 1 })
              .set({ minute: 0, second: 0, millisecond: 0 })
              .toISO()}`,
            "button",
          ),
        variant: "primary",
      },
    ],
  };

  const getTitle = (visit: VisitInstance) => {
    const visitor =
      visit.visitors && Array.isArray(visit.visitors)
        ? visit.visitors[0]
        : null;
    return `${visitor?.firstName ?? ""} ${visitor?.lastName ?? ""}`.trim();
  };

  const absenceClick = (id: string) => {
    navigate(`absence/${id}`);
  };

  const handleClick = (e: EventClickArg) => {
    navigate(`visits/${e.event.id}`, { preventScrollReset: true });
  };

  return (
    <>
      <Schedule
        events={data?.eventsByCareRecipientId ?? []}
        holidays={holidays}
        editable={true}
        getTitle={getTitle}
        loading={isLoading}
        absenceClick={absenceClick}
        datesSet={({ startStr, endStr }) => {
          setDateRange({ from: startStr, to: endStr });
        }}
        eventClick={handleClick}
        eventChange={(e) => {
          if (
            e.event.extendedProps.recurrences &&
            !e.event.extendedProps.exceptionId
          ) {
            setRecurringVisitChange(e);
          } else {
            handleUpdateVisit(e.event);
          }
        }}
        dateClick={(e) => {
          if (DateTime.now() <= DateTime.fromISO(e.dateStr)) {
            handleNewVisit(e.dateStr);
          }
        }}
        select={(e) => {
          handleNewVisit(`${e.startStr}--${e.endStr}`);
        }}
        topActions={topActions}
        timezone={timezone}
      />

      <UpdateRecurringModal
        show={!!recurringVisitChange}
        onConfirmThisVisit={() =>
          recurringVisitChange && handleUpdateVisit(recurringVisitChange.event)
        }
        onConfirmFollowingVisits={() =>
          recurringVisitChange &&
          handleUpdateFollowingVisits(recurringVisitChange.event)
        }
        onCancel={() => {
          recurringVisitChange?.revert();
          setRecurringVisitChange(undefined);

          // Remove error messages that might stick around
          visitUpdateReset();
          visitUpdateFollowingReset();
        }}
        loading={visitUpdateLoading || visitUpdateFollowingLoading}
      />
    </>
  );
};
