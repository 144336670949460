import { useMemo } from "react";
import { useTranslate } from "@tolgee/react";
import { CancelledReason } from "../../../api/generated/graphql";
import { Select } from "../../../components/formfields";
import { Paragraph } from "@frontend/lyng/typography";
import { useTranslateCancelledReason } from "../../../utils/translationUtils";

type Props = {
  id?: string;
  value: CancelledReason | null;
  name?: string | undefined;
  onChange: (value: CancelledReason | null) => void;
  onBlur: () => void;
  disabled?: boolean;
  invalid?: boolean;
  editView?: boolean;
  "aria-labelledby"?: string;
};

export const CancelledReasonSelect = ({
  id,
  value,
  name,
  onChange,
  onBlur,
  disabled,
  invalid,
  editView = true,
  "aria-labelledby": ariaLabelledBy,
}: Props) => {
  const { t } = useTranslate();
  const translateCancelledReason = useTranslateCancelledReason(t);

  const options = useMemo(() => {
    return Object.values(CancelledReason).map((reason) => ({
      value: reason,
      label: translateCancelledReason(reason),
    }));
  }, [translateCancelledReason]);

  const selectedOption = useMemo(() => {
    if (!value) {
      return null;
    }
    return options.find((option) => option.value === value);
  }, [value, options]);

  if (!editView) {
    return (
      <div className="flex h-12 items-center px-5">
        <Paragraph size="m">
          {selectedOption ? selectedOption.label : "-"}
        </Paragraph>
      </div>
    );
  }

  return (
    <Select
      // TODO: Remove !max-w-none when design overhaul of formfields is done
      className="!max-w-none"
      id={id}
      name={name}
      onChange={(value) => onChange(value?.value ?? null)}
      onBlur={onBlur}
      options={options}
      value={selectedOption}
      placeholder={t("cancelledReasonSelect.cancelVisitReasonPlaceholder")}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.value}
      invalid={invalid}
      aria-labelledby={ariaLabelledBy}
      isMulti={false}
      isClearable={true}
      isDisabled={disabled}
    />
  );
};
