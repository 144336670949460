import { P, match } from "ts-pattern";
import { PillColors, PillProps } from "./types";
import { Label } from "../typography";
import classNames from "classnames";

const renderMaybeIconOrDot = (
  Icon: PillProps["icon"],
  side: "left" | "right",
  pillColor: PillColors,
  iconOnly: boolean,
) => {
  if (!Icon) {
    return null;
  }
  if (Icon === "dot") {
    const dotColor = match(pillColor)
      .with("green", () => "bg-secondary-500")
      .with("red", () => "bg-critical-600")
      .with("blue", () => "bg-primary-400")
      .with("gray", () => "bg-greyscale-300")
      .exhaustive();
    return (
      <div
        className={classNames("flex h-7 w-8 items-center justify-center", {
          "ml-1": side === "left",
          "mr-1": side === "right",
        })}
      >
        <span className={classNames("h-3 w-3 rounded-full", dotColor)} />
      </div>
    );
  }

  const iconColor = match(pillColor)
    .with("green", () => "text-secondary-400")
    .with("red", () => "text-critical-800")
    .with("blue", () => "text-primary-600")
    .with("gray", () => "text-greyscale-400")
    .exhaustive();

  return (
    <span
      className={classNames(iconColor, {
        "mr-2": side === "left" && !iconOnly,
        "ml-2": side === "right" && !iconOnly,
      })}
    >
      {Icon}
    </span>
  );
};

export const Pill = ({ text, color, icon, iconSide = "left" }: PillProps) => {
  const paddingClass = match([text, icon, iconSide])
    .with([P._, P.nullish, P._], () => "px-4")
    .with([P._, "dot", "left"], () => "pl-1 pr-4")
    .with([P._, "dot", "right"], () => "pl-4 pr-1")
    .with([P.nullish, P._, P._], ["", P._, P._], () => "px-2.5 py-2.5")
    .with([P._, P._, "left"], () => "pl-2.5 pr-4")
    .with([P._, P._, "right"], () => "pl-4 pr-2.5")
    .exhaustive();

  const textColor = match(color)
    .with("blue", () => "text-greyscale-900")
    .with("gray", () => "text-greyscale-900")
    .with("green", () => "text-greyscale-900")
    .with("red", () => "text-critical-800")
    .exhaustive();

  const bgColor = match(color)
    .with("green", () => "bg-secondary-200")
    .with("red", () => "bg-critical-300")
    .with("blue", () => "bg-primary-100")
    .with("gray", () => "bg-greyscale-100")
    .exhaustive();

  return (
    <span
      className={classNames(
        "inline-flex flex-shrink-0 items-center rounded-full py-2",
        paddingClass,
        bgColor,
      )}
    >
      {iconSide === "left"
        ? renderMaybeIconOrDot(icon, iconSide, color, !text)
        : null}
      <Label size="m" className={textColor}>
        {text}
      </Label>
      {iconSide === "right"
        ? renderMaybeIconOrDot(icon, iconSide, color, !text)
        : null}
    </span>
  );
};
