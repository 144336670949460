import { DateTime } from "luxon";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslate } from "@tolgee/react";

import {
  useActivityCreateMutation,
  useActivityDeleteMutation,
  useActivityUpdateMutation,
} from "../../../api/generated/graphql";

import { Activity } from "../../../types";
import { errorToToastMessage } from "../../../utils/toastUtils";
import { PartialBy } from "../../../utils/typeUtils";

import { ConfirmDeleteModal, SlideOver } from "../../../components/common";
import { ActivityForm, ActivityFormInput } from "../activity-form/ActivityForm";

type Props = {
  activity: PartialBy<Activity, "id">;
  onClose: () => void;
};

export const ActivityDetailsModal = ({ activity, onClose }: Props) => {
  const { t } = useTranslate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [activityCreateMutation, { loading }] = useActivityCreateMutation({
    refetchQueries: ["ActivitiesByCareRecipientId"],
  });

  const [activityUpdateMutation] = useActivityUpdateMutation({
    refetchQueries: ["ActivitiesByCareRecipientId"],
  });

  const [activityDeleteMutation, { loading: deleteLoading }] =
    useActivityDeleteMutation({
      refetchQueries: ["ActivitiesByCareRecipientId"],
    });

  const onSubmit = async (values: ActivityFormInput): Promise<void> => {
    if (activity.id) {
      const activityUpdatePromise = activityUpdateMutation({
        variables: {
          activityId: activity.id ?? "",
          input: {
            ...values,
            priority: values.priority.value,
            timeOfDayStart: values.timeOfDayStart
              ? DateTime.fromObject(values.timeOfDayStart).toFormat("HH:mm")
              : null,
          },
        },
      }).then((data) => {
        onClose();
        return data;
      });

      toast.promise(activityUpdatePromise, {
        loading: t("activityDetailsModal.updating"),
        success: (data) =>
          t("saved", {
            name: data.data?.activityUpdate.title ?? t("activity"),
          }),
        error: (err) => errorToToastMessage(err),
      });
      return;
    }

    const activityCreatePromise = activityCreateMutation({
      variables: {
        input: {
          ...values,
          priority: values.priority.value,
          careRecipientId: activity.careRecipientId,
          timeOfDayStart: values.timeOfDayStart
            ? DateTime.fromObject(values.timeOfDayStart).toFormat("HH:mm")
            : null,
        },
      },
    }).then((data) => {
      onClose();
      return data;
    });

    await toast.promise(activityCreatePromise, {
      loading: t("activityDetailsModal.creating"),
      success: (data) =>
        t("saved", {
          name: data.data?.activityCreate.title ?? t("activity"),
        }),
      error: (err) => errorToToastMessage(err),
    });
  };

  const deleteActivity = () => {
    const activityDeletePromise = activityDeleteMutation({
      variables: {
        activityId: activity.id ?? "",
      },
    }).then((data) => {
      onClose();
      return data;
    });

    toast.promise(activityDeletePromise, {
      loading: t("activityDetailsModal.deleting"),
      success: () => t("deletedSuccessfully"),
      error: (err) => errorToToastMessage(err),
    });
    return;
  };
  return (
    <SlideOver
      title={`${activity.id ? t("edit") : t("create")} ${t("activity")}`}
      onClose={onClose}
    >
      <ActivityForm
        onSubmit={onSubmit}
        activity={activity}
        onDelete={activity.id ? () => setShowDeleteModal(true) : null}
        onCancel={onClose}
        loading={loading}
      />
      <ConfirmDeleteModal
        show={!!showDeleteModal}
        onCancel={() => {
          setShowDeleteModal(false);
        }}
        onConfirm={() => {
          deleteActivity();
          setShowDeleteModal(false);
        }}
        loading={!!deleteLoading}
        description={t(
          "activityDetailsModal.removeActivitiesFromVisitsConfirmation",
        ).toString()}
      />
    </SlideOver>
  );
};
