import classNames from "classnames";

type Props = {
  id?: string;
  dataTestId?: string;
  className?: string;
  children: React.ReactNode;
};
const ValidationMessage = ({ className, dataTestId, children, id }: Props) => {
  return (
    <p
      id={id}
      data-testid={dataTestId}
      className={classNames("text-sm text-red-600", className)}
      role="alert"
    >
      {children}
    </p>
  );
};

export default ValidationMessage;
