import { LoadingSpinner, SlideOver } from "../../../components/common";
import { toast } from "react-hot-toast";
import { MemberForm } from "./MemberForm";
import { useTranslate } from "@tolgee/react";
import { useNavigate } from "react-router-dom";
import { Member } from "./MemberForm";
import {
  CareTeamMemberRoleType,
  useCareTeamMembersQuery,
} from "../../../api/generated/graphql";
import { useTeamMemberMutations } from "./hook";
import { errorToToastMessage } from "../../../utils/toastUtils";
import {
  Type,
  UserCreatedRole,
  userCreated,
} from "../../../typewriter/segment";

export const NewMemberModal = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();

  const { data, error } = useCareTeamMembersQuery({});

  const { create, createLoading } = useTeamMemberMutations();

  const handleClose = () => navigate("..");

  const handleSubmit: (values: Member) => Promise<void> = (values) => {
    const promise = create({
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      homePhone: values.homePhone,
      birthDate: values.birthDate,
      pid: values.pid,
      email: values.email,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      roleType: values.role!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      resourceType: values.organizationUnit!.type,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      resourceId: values.organizationUnit!.id,
    }).then((data) => {
      handleClose();
      const id = data.data?.careTeamMemberCreate.id;

      const getRole = () => {
        const findRole = {
          [CareTeamMemberRoleType.Admin]: UserCreatedRole.Administrator,
          [CareTeamMemberRoleType.SchedulingManager]:
            UserCreatedRole.SchedulingManager,
          [CareTeamMemberRoleType.SuccessManager]:
            UserCreatedRole.SuccessManager,
        };
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return findRole[values.role!];
      };

      if (id)
        userCreated({
          user_id: id,
          type: Type.TeamMember,
          role: getRole(),
        });
    });

    return toast.promise(promise, {
      loading: t("teamMembers.creating"),
      success: () => {
        const { firstName, lastName } = values;
        return t("teamMembers.created", { name: `${firstName} ${lastName}` });
      },
      error: (err) => errorToToastMessage(err),
    });
  };

  return (
    <SlideOver title={t("teamMembers.add").toString()} onClose={handleClose}>
      {!data && createLoading && (
        <div className="flex h-full items-center justify-center">
          <LoadingSpinner />
        </div>
      )}
      {error && <p>{error.message}</p>}
      {data && (
        <MemberForm onSubmit={(values: Member) => handleSubmit(values)} />
      )}
    </SlideOver>
  );
};
