import { cloneElement, useMemo, useState } from "react";
import { useTranslate } from "@tolgee/react";
import Skeleton from "react-loading-skeleton";
import { match } from "ts-pattern";
import classNames from "classnames";

import { useOrganizationQuery } from "../../../api/generated/graphql";
import { Button } from "../../../components/common";
import { FieldLabel } from "../../../pages/careRecipientDetails/CareRecipientProfileFormModal";
import {
  division,
  groupOrganizationUnits,
} from "../../../utils/organizationUtils";

import {
  OrganizationFormModal,
  Organization as OrganizationType,
} from "./OrganizationFormModal";
import {
  OrganizationDivisionFormModal,
  Props as DivisionProps,
} from "./OrganizationDivisionFormModal";
import {
  OrganizationRegionUpdateFormModal,
  OrganizationRegionCreateFormModal,
  CreateProps as CreateRegionProps,
  UpdateProps as UpdateRegionProps,
} from "./OrganizationRegionFormModal";
import {
  OrganizationOfficeUpdateFormModal,
  OrganizationOfficeCreateFormModal,
  CreateProps as CreateOfficeProps,
  UpdateProps as UpdateOfficeProps,
} from "./OrganizationOfficeFormModal";
import { useFeatureFlag } from "../../../providers/FeatureFlags";

type organizationModal = {
  type: "organizationUpdate";
  organization: OrganizationType;
};

interface DivisionCreate {
  type: "divisionCreate";
}
interface DivisionUpdate {
  type: "divisionUpdate";
  division: DivisionProps["division"];
}
type DivisionModal = DivisionCreate | DivisionUpdate;

interface RegionCreate {
  type: "regionCreate";
  availableValues: CreateRegionProps["availableDivisions"];
}
interface RegionUpdate {
  type: "regionUpdate";
  id: string;
  region: UpdateRegionProps["region"];
}
type regionModal = RegionCreate | RegionUpdate;

interface OfficeCreate {
  type: "officeCreate";
  availableValues: CreateOfficeProps["availableRegions"];
}
interface OfficeUpdate {
  type: "officeUpdate";
  id: string;
  office: UpdateOfficeProps["office"];
}
type officeModal = OfficeCreate | OfficeUpdate;

type oneModalToRuleThemAll =
  | organizationModal
  | DivisionModal
  | regionModal
  | officeModal;

const divisionSettingsToText = (
  settings: string[],
  t: (s: string) => string,
) => {
  return settings.reduce((acc, setting) => {
    switch (setting) {
      case "caregiversCanCreateAndUpdateVisits":
        return acc + t("division.settings.caregiversCanCreateAndUpdateVisits");
      default:
        return acc;
    }
  }, "");
};

const divisionHeaderColumns = (
  caregiversCanCreateAndUpdateVisits: boolean,
  t: (s: string) => string,
) => {
  const headers = [
    <th key="name" scope="col">
      {t("division.table.name")}
    </th>,
  ];
  if (caregiversCanCreateAndUpdateVisits) {
    headers.push(
      <th key="settings" scope="col">
        {t("division.table.settings")}
      </th>,
    );
  }
  headers.push(
    <th key="edit" className="min-w-[50px]" scope="col">
      <span className="sr-only">{t("edit")}</span>
    </th>,
  );
  return headers;
};

const mapDivisionToRows = (
  row: division,
  caregiversCanCreateAndUpdateVisits: boolean,
  t: (s: string) => string,
  onClick: () => void,
) => {
  const content = [<td key="name">{row.name}</td>];

  if (caregiversCanCreateAndUpdateVisits) {
    content.push(
      <td key="settings">{divisionSettingsToText(row.enabledSettings, t)}</td>,
    );
  }
  content.push(
    <td key="edit" className="text-right">
      <button
        className="px-4 py-2 text-sm text-primary-600 hover:text-primary-900"
        onClick={onClick}
      >
        {t("edit").toString()}
      </button>
    </td>,
  );

  return {
    id: row.id,
    content,
  };
};

const Organization = () => {
  const { t } = useTranslate();
  const ff_caregiverCanCreateAndUpdateVisits = useFeatureFlag(
    "CaregiverCreateAndUpdateVisits",
  );

  const [modal, setModal] = useState<oneModalToRuleThemAll | undefined>(
    undefined,
  );

  const { data, error, loading } = useOrganizationQuery();

  // For now only console log the error
  error && console.error(error);

  const [divisions, regions, offices] = useMemo(() => {
    return loading
      ? [[], [], []]
      : (data && groupOrganizationUnits(data.organization)) || [[], [], []];
  }, [loading, data]);

  return (
    <>
      <div className="p-5 md:p-0">
        <div className="-mx-4 flex flex-col justify-between gap-3 sm:-mx-6 md:mx-0">
          <h1 className="text-3xl font-semibold">{t("organization.label")}</h1>
          <Section
            title={t("organization.information.label")}
            description={t("organization.information.description").toString()}
            action={{
              text: t("edit"),
              onClick: () =>
                setModal(
                  data && {
                    type: "organizationUpdate",
                    organization: {
                      name: data.organization.name,
                    },
                  },
                ),
            }}
          >
            <div className="mt-10">
              <FieldLabel htmlFor="organization.name">
                {t("organization.name")}
              </FieldLabel>
              <p className="mt-3 text-sm text-gray-700">
                {loading ? (
                  <Skeleton containerClassName="flex" />
                ) : (
                  data?.organization.name || ""
                )}
              </p>
            </div>
          </Section>
          <Section
            title={t("division.title")}
            description={t("division.description").toString()}
            action={{
              text: t("division.add"),
              onClick: () => setModal({ type: "divisionCreate" }),
            }}
          >
            <Table
              className="mt-10"
              headerColumns={divisionHeaderColumns(
                ff_caregiverCanCreateAndUpdateVisits,
                t,
              )}
              rows={divisions.map((row) =>
                mapDivisionToRows(
                  row,
                  ff_caregiverCanCreateAndUpdateVisits,
                  t,
                  () => {
                    setModal({
                      type: "divisionUpdate",
                      division: row,
                    });
                  },
                ),
              )}
              loading={loading}
            />
          </Section>
          <Section
            title={t("region.title")}
            description={t("region.description").toString()}
            action={{
              text: t("region.add"),
              onClick: () =>
                setModal({
                  type: "regionCreate",
                  availableValues: divisions,
                }),
            }}
          >
            <Table
              className="mt-10"
              headerColumns={[
                <th key="name" scope="col">
                  {t("region.table.name")}
                </th>,
                <th key="division" scope="col">
                  {t("region.table.division")}
                </th>,
                // <th scope="col">{t("region.table.description")}</th>,
                <th key="edit" className="min-w-[50px]" scope="col">
                  <span className="sr-only">{t("edit")}</span>
                </th>,
              ]}
              rows={regions.map((row) => ({
                id: row.id,
                content: [
                  <td key="name">{row.name}</td>,
                  <td key="division">{row.division}</td>,
                  // <td key="description">{row.description}</td>,
                  <td key="edit" className="text-right">
                    <button
                      className="px-4 py-2 text-sm text-primary-600 hover:text-primary-900"
                      onClick={() =>
                        setModal({
                          id: row.id,
                          type: "regionUpdate",
                          region: row,
                        })
                      }
                    >
                      {t("edit").toString()}
                    </button>
                  </td>,
                ],
              }))}
              loading={loading}
            />
          </Section>
          <Section
            title={t("office.title")}
            description={t("office.description").toString()}
            action={{
              text: t("office.add"),
              onClick: () =>
                setModal({
                  type: "officeCreate",
                  availableValues: regions,
                }),
            }}
          >
            <Table
              className="mt-10"
              headerColumns={[
                <th key="name" scope="col">
                  {t("office.table.name")}
                </th>,
                <th key="region" scope="col">
                  {t("office.table.region")}
                </th>,
                <th key="division" scope="col">
                  {t("office.table.division")}
                </th>,
                // <th scope="col">{t("office.table.description")}</th>,
                <th key="edit" className="min-w-[50px]" scope="col">
                  <span className="sr-only">{t("edit")}</span>
                </th>,
              ]}
              rows={offices.map((row) => ({
                id: row.id,
                content: [
                  <td key="name">{row.name}</td>,
                  <td key="region">{row.region}</td>,
                  <td key="division">{row.division}</td>,
                  // <td key="description">{row.description}</td>,
                  <td key="edit" className="text-right">
                    <button
                      className="px-4 py-2 text-sm text-primary-600 hover:text-primary-900"
                      onClick={() =>
                        setModal({
                          id: row.id,
                          type: "officeUpdate",
                          office: row,
                        })
                      }
                    >
                      {t("edit").toString()}
                    </button>
                  </td>,
                ],
              }))}
              loading={loading}
            />
          </Section>
        </div>
      </div>

      {modal &&
        match(modal)
          .with({ type: "organizationUpdate" }, ({ organization }) => (
            <OrganizationFormModal
              organization={organization}
              onClose={() => setModal(undefined)}
            />
          ))
          .with({ type: "divisionCreate" }, () => (
            <OrganizationDivisionFormModal
              type={"create"}
              onClose={() => setModal(undefined)}
            />
          ))
          .with({ type: "divisionUpdate" }, ({ division }) => (
            <OrganizationDivisionFormModal
              division={division}
              type={"update"}
              onClose={() => setModal(undefined)}
            />
          ))
          .with({ type: "regionCreate" }, ({ availableValues }) => (
            <OrganizationRegionCreateFormModal
              availableDivisions={availableValues}
              onClose={() => setModal(undefined)}
            />
          ))
          .with({ type: "regionUpdate" }, ({ id, region }) => (
            <OrganizationRegionUpdateFormModal
              id={id}
              region={region}
              onClose={() => setModal(undefined)}
            />
          ))
          .with({ type: "officeCreate" }, ({ availableValues }) => (
            <OrganizationOfficeCreateFormModal
              availableRegions={availableValues}
              onClose={() => setModal(undefined)}
            />
          ))
          .with({ type: "officeUpdate" }, ({ id, office }) => (
            <OrganizationOfficeUpdateFormModal
              id={id}
              office={office}
              onClose={() => setModal(undefined)}
            />
          ))
          .exhaustive()}
    </>
  );
};

type SectionProps = {
  action?: {
    text: string;
    onClick: () => void;
  };
  title: string;
  description?: string;
  children: React.ReactNode;
};
const Section = ({ action, title, description, children }: SectionProps) => {
  return (
    <div className="mt-4 overflow-hidden bg-white px-4 py-8 shadow sm:rounded-xl">
      <div className="flex flex-row justify-between">
        <div>
          <p className="text-m block font-bold text-gray-700">{title}</p>
          {description && (
            <p className="mt-3 text-sm text-gray-700">{description}</p>
          )}
        </div>
        {action && (
          <div>
            <Button text={action.text} onClick={action.onClick} />
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

type TableProps = {
  headerColumns: JSX.Element[];
  rows: {
    id: string;
    content: JSX.Element[];
  }[];
  className?: string;
  loading: boolean;
};
const Table = ({ headerColumns, rows, className, loading }: TableProps) => {
  return (
    <table
      className={classNames("min-w-full divide-y divide-gray-300", className)}
    >
      <thead>
        <tr>
          {headerColumns.map((column) =>
            cloneElement(column, {
              className: classNames(
                "py-3.5 pr-3 text-left text-xs font-bold uppercase tracking-wide text-gray-600",
                column.props.className,
              ),
            }),
          )}
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {loading ? (
          <tr>
            {headerColumns.map((_, index) => (
              <td key={index} className="min-h-[45px]">
                <Skeleton />
              </td>
            ))}
          </tr>
        ) : (
          rows.map(({ id, content }) => (
            <tr key={id}>
              {content.map((column) =>
                cloneElement(column, {
                  className: classNames(
                    "py-1 text-xs font-medium tracking-wide text-gray-600 min-h-[45px]",
                    column.props.className,
                  ),
                }),
              )}
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
};

export default Organization;
