import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslate } from "@tolgee/react";

import { ColumnDef } from "@tanstack/react-table";

import {
  LabelsByOfficeIdsQuery,
  useLabelsByOfficeIdsQuery,
  useTenantSettingsSetEnableLabelsMutation,
} from "../../../../api/generated/graphql";

import { Table } from "../../../../components/common";
import { Toggle } from "@frontend/lyng/forms";
import {
  Headline,
  HeadlineContainer,
  Label,
  Paragraph,
} from "@frontend/lyng/typography";
import { useCareContext } from "../../../../providers/CareProvider";
import { useSortingOptions } from "../../../../utils/hooks/useSortingOptions";
import { errorToToastMessage } from "../../../../utils/toastUtils";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@frontend/lyng/button";
import { Checkmark, Close } from "@frontend/lyng/assets/icons/16/outline";
import { SmallPill } from "@frontend/lyng/pill";

type LabelsRow = LabelsByOfficeIdsQuery["labelsByOfficeIds"][number];

export const Labels = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();

  const {
    state: { viewer },
  } = useCareContext();
  const { collator } = useSortingOptions();

  const { data, loading, error } = useLabelsByOfficeIdsQuery({
    variables: {
      officeIds: viewer?.offices.map((office) => office.id) ?? [],
    },
    fetchPolicy: "no-cache",
  });

  const [labelsEnabled, setLabelsEnabled] = useState<boolean>(
    viewer?.tenantSettings.enableLabels ?? false,
  );

  const [enableLabelsMutation, { loading: enableLabelsLoading }] =
    useTenantSettingsSetEnableLabelsMutation({
      refetchQueries: ["Viewer"],
    });

  const handleEnableLabels = useCallback(
    async (enabled: boolean) => {
      const promise = enableLabelsMutation({
        variables: {
          enabled,
        },
      }).catch((e) => {
        setLabelsEnabled(!enabled);
        throw e;
      });

      await toast.promise(promise, {
        loading: enabled
          ? t("labels.enablingLabels")
          : t("labels.disablingLabels"),
        success: enabled
          ? t("labels.enabledLabels")
          : t("labels.disabledLabels"),
        error: errorToToastMessage,
      });
    },
    [enableLabelsMutation, t],
  );

  useEffect(() => {
    if (viewer?.tenantSettings.enableLabels === labelsEnabled) {
      return;
    }
    handleEnableLabels(labelsEnabled);
  }, [handleEnableLabels, viewer?.tenantSettings.enableLabels, labelsEnabled]);

  const columns = useMemo<ColumnDef<LabelsRow>[]>(() => {
    return [
      {
        id: "title",
        header: t("visitTypes.title") ?? "",
        accessorFn: (row) => row.name,
        sortingFn: (a, b, cId) =>
          collator.compare(a.getValue(cId), b.getValue(cId)),
      },
      {
        id: "office",
        header: t("labels.office") ?? "",
        accessorFn: (row) =>
          viewer?.offices.find((office) => office.id === row.officeId)?.name,
        sortingFn: (a, b, cId) =>
          collator.compare(a.getValue(cId), b.getValue(cId)),
      },
      {
        id: "visitSeriesAmount",
        header: t("labels.usage") ?? "",
        accessorFn: (row) => row.inUse,
        sortingFn: (a, b, cId) =>
          collator.compare(a.getValue(cId), b.getValue(cId)),
        cell: (row) =>
          row.row.original.inUse ? (
            <SmallPill icon={<Checkmark />} color="green" />
          ) : (
            <SmallPill icon={<Close />} color="gray" />
          ),
      },
      {
        id: "edit",
        header: "",
        accessorFn: (row) => row.id,
        enableSorting: false,
        cell: (row) => (
          <Link
            className=" flex justify-end"
            to={`label/${row.row.original.id}`}
          >
            {t("edit")}
          </Link>
        ),
      },
    ];
  }, [collator, t, viewer?.offices]);

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div className="p-5 md:p-0">
      <HeadlineContainer>
        <Headline size="l">{t("labels.header")}</Headline>
      </HeadlineContainer>
      <div className="mb-4 flex-row rounded-2xl bg-white p-7 shadow-md sm:flex-col">
        <Toggle
          checked={labelsEnabled}
          onToggle={() =>
            !enableLabelsLoading && setLabelsEnabled(!labelsEnabled)
          }
          text={t("labels.toggleDescription") ?? ""}
        />
      </div>
      {viewer?.tenantSettings.enableLabels && (
        <>
          <HeadlineContainer>
            <Headline size="l">{t("labels.subHeader")}</Headline>
            <Button
              className="ml-auto"
              variant="primary"
              text={t("labels.addLabel").toString()}
              onClick={() => {
                navigate("labels/new");
              }}
              disabled={false}
            />
          </HeadlineContainer>
          <div className="hidden md:block">
            <Table
              columns={columns}
              data={data?.labelsByOfficeIds ?? []}
              defaultSorting={[{ id: "title", desc: false }]}
              loading={loading}
            />
          </div>
          <div className="md:hidden rounded-2xl shadow-md divide-y divide-gray-200 bg-white [&>*:nth-child(even)]:bg-gray-50">
            {data?.labelsByOfficeIds.map((label) => (
              <div key={label.id} className="flex flex-col">
                <div className="flex justify-between px-5 pt-4 items-center">
                  <Label size="m">{label.name}</Label>
                  <Button
                    variant="secondary"
                    text={t("edit")}
                    onClick={() => navigate(`label/${label.id}`)}
                  />
                </div>
                <div className="flex pt-4 pb-5 px-5">
                  <Paragraph size="s" className="pr-2">
                    {
                      viewer?.offices.find(
                        (office) => office.id === label.officeId,
                      )?.name
                    }
                  </Paragraph>
                  •
                  <Paragraph size="s" className="pl-2">
                    {label.inUse ? t("labels.inUse") : t("labels.notInUse")}
                  </Paragraph>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
