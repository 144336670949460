import { useTranslate } from "@tolgee/react";

import { Modal, Button } from "../../../components/common";

type Props = {
  show: boolean;
  onClose: () => void;
  onAccept: () => void;
};

const NewPrimaryModal = ({ show, onClose, onAccept }: Props) => {
  const { t } = useTranslate();

  return (
    <Modal show={show} onClose={onClose}>
      <Modal.Title>{t("contacts.newPrimary")}</Modal.Title>

      <Modal.Footer>
        <Button
          variant="primary"
          text={t("ok").toString()}
          className="col-start-2"
          onClick={onAccept}
        />
        <Button
          variant="secondary"
          text={t("cancel").toString()}
          className="col-start-1"
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default NewPrimaryModal;
