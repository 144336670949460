import { SlideOver } from "@frontend/lyng/slideOver";
import { Label } from "@frontend/lyng/typography";
import { DateTime } from "luxon";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslate } from "@tolgee/react";
import { CancelledReason } from "../../../api/generated/graphql";
import {
  CancelledReasonSelect,
  FormGroupStacked,
} from "../../../components/formfields";
import { useCareContext } from "../../../providers";
import { DatePicker } from "@frontend/lyng/forms";

type CancelledVisitFields = {
  cancelledReason: CancelledReason | null;
  cancelledOnDate: DateTime | null;
};
type Props = {
  editView: boolean;
};
export function CancelledVisitCard({ editView }: Props) {
  const { t } = useTranslate();
  const {
    state: { viewer },
  } = useCareContext();
  const {
    control,
    formState: { errors },
  } = useFormContext<CancelledVisitFields>();

  return (
    <SlideOver.CardSection>
      <div className="flex flex-col gap-6">
        <FormGroupStacked>
          <Label id="reason-label">
            {t("visitDetails.cancelVisitReasonLabel")}
          </Label>
          <Controller
            control={control}
            name="cancelledReason"
            render={({ field }) => (
              <CancelledReasonSelect
                id="cancelledReason"
                value={field.value}
                name={field.name}
                onChange={field.onChange}
                onBlur={field.onBlur}
                invalid={!!errors.cancelledReason}
                editView={editView}
              />
            )}
          />
        </FormGroupStacked>

        <FormGroupStacked>
          <Label id="cancelledOnDate-label">
            {t("visitDetails.cancelVisitDateLabel")}
          </Label>
          <Controller
            control={control}
            name="cancelledOnDate"
            render={({ field }) => (
              <DatePicker
                // TODO: remove !max-w-none when design overhaul of formfields is done
                dateSettings={viewer?.tenantSettings}
                className="!max-w-none"
                aria-labelledby="cancelledOnDate-label"
                name="cancelledOnDate"
                showCalendar="noCalendar"
                withTime
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                isInvalid={!!errors.cancelledOnDate}
                editView={editView}
              />
            )}
          />
        </FormGroupStacked>
      </div>
    </SlideOver.CardSection>
  );
}
