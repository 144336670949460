import React, { useCallback, useMemo, useState } from "react";
import {
  UsersQuery,
  ResourceType,
  useUsersQuery,
  useUserInvitationResendMutation,
  CareTeamMemberRoleType,
} from "../../../api/generated/graphql";
import { Table } from "../../../components/common";
import { useCareContext } from "../../../providers/CareProvider";
import { ColumnDef } from "@tanstack/react-table";
import { useDateFormatter } from "../../../utils/dateUtils";
import { useSortingOptions } from "../../../utils/hooks/useSortingOptions";
import { errorToToastMessage } from "../../../utils/toastUtils";
import toast from "react-hot-toast";
import { useTranslate } from "@tolgee/react";
import {
  Headline,
  HeadlineContainer,
  Link,
  Paragraph,
} from "@frontend/lyng/typography";
import { Tabs } from "@frontend/lyng/tabs";
import { useNavigate, useSearchParams } from "react-router-dom";
import { match } from "ts-pattern";
import { Button } from "@frontend/lyng/button";
import { NumberPill } from "@frontend/lyng/pill";
import { Empty } from "@frontend/lyng/assets/svg";
import classNames from "classnames";

export type UsersFilterTabs =
  | "all"
  | "contacts"
  | "caregivers"
  | "careRecipients"
  | "careTeamMembers"
  | "no-role";

type UsersRow = UsersQuery["users"][number];

function isCareTeamMemberRole(
  role:
    | UsersRow["careTeamMemberRoles"][number]
    | UsersRow["caregiverRoles"][number]
    | UsersRow["careRecipientRoles"][number]
    | UsersRow["contactRoles"][number],
): role is UsersRow["careTeamMemberRoles"][number] {
  return role.__typename === "CareTeamMemberRole";
}

function isCaregiverRole(
  role:
    | UsersRow["careTeamMemberRoles"][number]
    | UsersRow["caregiverRoles"][number]
    | UsersRow["careRecipientRoles"][number]
    | UsersRow["contactRoles"][number],
): role is UsersRow["caregiverRoles"][number] {
  return role.__typename === "CaregiverRole";
}

function isCareRecipientRole(
  role:
    | UsersRow["careTeamMemberRoles"][number]
    | UsersRow["caregiverRoles"][number]
    | UsersRow["careRecipientRoles"][number]
    | UsersRow["contactRoles"][number],
): role is UsersRow["careRecipientRoles"][number] {
  return role.__typename === "CareRecipientRole";
}

function isContactRole(
  role:
    | UsersRow["careTeamMemberRoles"][number]
    | UsersRow["caregiverRoles"][number]
    | UsersRow["careRecipientRoles"][number]
    | UsersRow["contactRoles"][number],
): role is UsersRow["contactRoles"][number] {
  return role.__typename === "ContactRole";
}

const Users = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialTab = searchParams.get("tab") as UsersFilterTabs | null;
  const [tabView, setTabView] = useState<UsersFilterTabs>(initialTab || "all");

  const {
    state: { viewer },
  } = useCareContext();
  const { formatRelativeTime } = useDateFormatter();

  const country = viewer?.tenantSettings.country;
  const { nameOrderFn } = useSortingOptions();
  const { t } = useTranslate();

  const { data, loading, error } = useUsersQuery();
  const [userInvitationMutation] = useUserInvitationResendMutation();

  const translateCareTeamRoleType = useCallback(
    (roleType: CareTeamMemberRoleType) => {
      return match(roleType)
        .with(CareTeamMemberRoleType.SchedulingManager, () =>
          t("roleType.SCHEDULING_MANAGER"),
        )
        .with(CareTeamMemberRoleType.SuccessManager, () =>
          t("roleType.SUCCESS_MANAGER"),
        )
        .with(CareTeamMemberRoleType.Admin, () => t("roleType.ADMIN"))
        .exhaustive();
    },
    [t],
  );

  const translateResourceType = useCallback(
    (resourceType: ResourceType) => {
      return match(resourceType)
        .with(ResourceType.CareRecipient, () =>
          t("resourceType.CARE_RECIPIENT"),
        )
        .with(ResourceType.Division, () => t("resourceType.DIVISION"))
        .with(ResourceType.Office, () => t("resourceType.OFFICE"))
        .with(ResourceType.Region, () => t("resourceType.REGION"))
        .with(ResourceType.Tenant, () => t("resourceType.TENANT"))
        .exhaustive();
    },
    [t],
  );

  const usersWithoutRolesCount = useMemo(() => {
    if (!data?.users) return 0;

    return data.users.filter(
      (user) =>
        user.careRecipientRoles.length === 0 &&
        user.careTeamMemberRoles.length === 0 &&
        user.caregiverRoles.length === 0 &&
        user.contactRoles.length === 0,
    ).length;
  }, [data?.users]);

  const openModalForm = useCallback(
    (user?: Partial<UsersRow> | undefined) => {
      if (user) {
        navigate(`./${user.id}`, {
          state: {
            user: user,
          },
        });
      } else {
        navigate("./new", {
          state: {
            user: {},
          },
        });
      }
    },
    [navigate],
  );

  const usersTabs = [
    {
      id: "all",
      label: t("users.all"),
      emptyLabelState: t("users.allEmpty"),
    },
    {
      id: "caregivers",
      label: t("users.caregivers"),
      emptyLabelState: t("users.caregiversEmpty"),
    },
    {
      id: "careRecipients",
      label: t("users.careRecipients"),
      emptyLabelState: t("users.careRecipientsEmpty"),
    },
    {
      id: "careTeamMembers",
      label: t("users.careTeamMembers"),
      emptyLabelState: t("users.careTeamMembersEmpty"),
    },
    {
      id: "contacts",
      label: t("Contacts"),
      emptyLabelState: t("users.contactsEmpty"),
    },
    {
      id: "no-role",
      label: t("users.noRole"),
      emptyLabelState: t("users.noRoleEmpty"),
      icon: () => <NumberPill count={usersWithoutRolesCount} color="yellow" />,
    },
  ];

  const handleTabChange = (tab: string) => {
    const validTab: UsersFilterTabs = (
      [
        "all",
        "contacts",
        "caregivers",
        "careRecipients",
        "careTeamMembers",
        "no-role",
      ] as const
    ).includes(tab as UsersFilterTabs)
      ? (tab as UsersFilterTabs)
      : "all";

    setTabView(validTab);
    setSearchParams({ tab: validTab });
  };

  const filteredData = useMemo(() => {
    if (!data?.users) return [];

    switch (tabView) {
      case "contacts":
        return data.users.filter((user) => user.contactRoles.length > 0);
      case "caregivers":
        return data.users.filter((user) => user.caregiverRoles.length > 0);
      case "careRecipients":
        return data.users.filter((user) => user.careRecipientRoles.length > 0);
      case "careTeamMembers":
        return data.users.filter((user) => user.careTeamMemberRoles.length > 0);
      case "no-role":
        return data.users.filter(
          (user) =>
            user.careRecipientRoles.length === 0 &&
            user.careTeamMemberRoles.length === 0 &&
            user.caregiverRoles.length === 0 &&
            user.contactRoles.length === 0,
        );
      case "all":
      default:
        return data.users;
    }
  }, [data?.users, tabView]);

  const columns = useMemo<ColumnDef<UsersRow>[]>(() => {
    const collator = new Intl.Collator(country);
    return [
      {
        id: "name",
        header: t("name") ?? "",
        accessorFn: (row) => nameOrderFn(row),
        sortingFn: (a, b, cId) =>
          collator.compare(a.getValue(cId), b.getValue(cId)),
      },
      {
        id: "email",
        header: t("email") ?? "",
        accessorFn: (row) => row.email,
        cell: (row) => (
          <Link size="xs" to={`tel:${row.getValue<string>()}`}>
            {row.getValue<string>()}
          </Link>
        ),
      },
      {
        id: "roles",
        header: t("rolesPlural") ?? "",
        enableSorting: false,
        cell: (row) => {
          const roles = [
            ...row.row.original.careRecipientRoles,
            ...row.row.original.careTeamMemberRoles,
            ...row.row.original.caregiverRoles,
            ...row.row.original.contactRoles,
          ];

          roles.sort((a, b) => {
            const roleTypeA = isCareTeamMemberRole(a) ? a.roleType : "";
            const roleTypeB = isCareTeamMemberRole(b) ? b.roleType : "";
            return roleTypeA.localeCompare(roleTypeB);
          });

          return (
            <ul>
              {roles
                .filter((role) =>
                  isContactRole(role) ? true : !role.deactivatedAt,
                )
                .map((role, index) => (
                  <li key={index}>
                    <Paragraph size="s" className="flex">
                      <span className="h-2 w-2 rounded-full bg-secondary-500 mt-2 mr-3" />
                      {isCareTeamMemberRole(role)
                        ? translateCareTeamRoleType(role.roleType)
                        : isCaregiverRole(role)
                          ? t("roleType.CAREGIVER")
                          : isCareRecipientRole(role)
                            ? t("roleType.CARE_RECIPIENT")
                            : isContactRole(role)
                              ? t("roleType.CONTACT")
                              : t("roleType.UNKNOWN")}{" "}
                      •{" "}
                      {isCareTeamMemberRole(role)
                        ? role.organizationUnit.name
                        : isCaregiverRole(role) || isCareRecipientRole(role)
                          ? role.office.name
                          : isContactRole(role)
                            ? `${role.careRecipient.firstName} ${role.careRecipient.lastName}`
                            : ""}
                      {isCareTeamMemberRole(role)
                        ? ` • ${translateResourceType(role.resourceType)}`
                        : ""}
                    </Paragraph>
                  </li>
                ))}

              {roles.filter(
                (role) => !isContactRole(role) && role.deactivatedAt,
              ).length > 0 && (
                <Paragraph size="s" className="flex">
                  <span className="h-2 w-2 rounded-full bg-greyscale-200 self-center mr-3" />
                  {
                    roles.filter(
                      (role) => !isContactRole(role) && role.deactivatedAt,
                    ).length
                  }{" "}
                  {t("users.inactiveRoles")}
                </Paragraph>
              )}
            </ul>
          );
        },
      },
      {
        id: "phone",
        header: t("phone") ?? "",
        accessorFn: (row) => row.phone,
        cell: (row) => (
          <Link size="xs" to={`tel:${row.getValue<string>()}`}>
            {row.getValue<string>()}
          </Link>
        ),
      },
      {
        id: "lastLogin",
        header: t("users.lastLogin") ?? "",
        cell: (row) => {
          if (row.row.original.lastLogin) {
            return (
              <Paragraph size="s">
                {formatRelativeTime(row.row.original.lastLogin)}
              </Paragraph>
            );
          }
          return (
            row.row.original.email &&
            row.row.original.roles.length > 0 && (
              <Button
                className="-ml-6"
                type="button"
                size="md"
                variant="tertiary"
                text={t("users.resendInvite") ?? ""}
                onClick={() => {
                  const toastSuccess = (email: string) => {
                    return t("users.inviteSent", { email });
                  };

                  const promise = userInvitationMutation({
                    variables: {
                      userId: row.row.original.id,
                    },
                  });

                  toast.promise(promise, {
                    loading: t("users.sendingInvite"),
                    success: () => toastSuccess(row.row.original.email ?? ""),
                    error: (err) => errorToToastMessage(err),
                  });
                }}
              />
            )
          );
        },
      },
      {
        id: "edit",
        header: "",
        accessorFn: (row) => row.id,
        enableSorting: false,
        cell: (row) => (
          <div className="flex justify-end">
            <Button
              className="ml-auto"
              type="button"
              variant="tertiary"
              text={
                row.row.original.isTenantOwner ? t("view") ?? "" : t("edit")
              }
              onClick={() => openModalForm(row.row.original)}
            />
          </div>
        ),
      },
    ];
  }, [
    country,
    t,
    nameOrderFn,
    formatRelativeTime,
    userInvitationMutation,
    translateCareTeamRoleType,
    translateResourceType,
    openModalForm,
  ]);

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const emptyState = !loading && !filteredData.length;

  return (
    <div className={classNames("p-5 md:p-0", emptyState && "h-full")}>
      <HeadlineContainer>
        <Headline size="l">{t("users.title")}</Headline>
        <Button
          className="ml-auto"
          variant="primary"
          text={t("users.addUser").toString()}
          onClick={() => openModalForm()}
        />
      </HeadlineContainer>
      <div className="mb-4 w-fit">
        <Tabs
          tabs={usersTabs}
          currentTab={tabView}
          onChange={handleTabChange}
        />
      </div>
      {!emptyState && (
        <Table
          loading={loading}
          data={filteredData}
          columns={columns}
          topAlign={true}
          defaultSorting={[{ id: "name", desc: false }]}
        ></Table>
      )}
      {emptyState && (
        <div className="flex justify-center h-full items-center flex-col">
          <Empty.E />
          <Headline size="m" className="p-6">
            {usersTabs.find((tab) => tab.id === tabView)?.emptyLabelState}
          </Headline>
        </div>
      )}
    </div>
  );
};

export default Users;
