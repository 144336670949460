import { useMemo } from "react";
import { useTranslate } from "@tolgee/react";
import Select from "react-select";
import { z } from "zod";

import { RelationshipType } from "../../../api/generated/graphql";

import { relationshipTypeToString } from "../../../utils/translationUtils";

export const relationshipSchema = z.object({
  key: z.string(),
  value: z.nativeEnum(RelationshipType),
  label: z.string(),
});

type Option = z.infer<typeof relationshipSchema>;

type Props = {
  value: Option | null;
  name?: string;
  onChange: (value: Option | null) => void;
  onBlur: () => void;
};

export const getRelationshipOption = (type: RelationshipType) => {
  const key = Object.entries(RelationshipType).find(
    ([, value]) => value === type,
  )?.[0];

  if (!key) return null;
  return { key, value: type, label: relationshipTypeToString(type) };
};

const RelationshipSelect = ({ value, name, onChange, onBlur }: Props) => {
  const { t } = useTranslate();

  const options = useMemo(() => {
    return Object.entries(RelationshipType).map(([key, value]) => ({
      key,
      value,
      label: relationshipTypeToString(value),
    }));
  }, []);

  return (
    <div className="relative mt-1 max-w-lg rounded-md shadow-sm sm:max-w-xs">
      <Select
        name={name}
        placeholder={t("placeholder.chooseRelationship")}
        options={options}
        value={value}
        isMulti={false}
        isClearable={true}
        onChange={onChange}
        onBlur={onBlur}
        getOptionLabel={(option) => t(option.label)}
        getOptionValue={(option) => option.value}
        styles={{
          input: (base) => ({
            ...base,
            "input:focus": {
              boxShadow: "none",
            },
          }),
        }}
      />
    </div>
  );
};

export default RelationshipSelect;
