import { z } from "zod";
import { RoleType } from "../../../api/generated/graphql";
import { useTranslate } from "@tolgee/react";
import { useCareContext } from "../../../providers/CareProvider";
import { useMemo } from "react";
import { Select } from "@frontend/lyng/forms";

export const officeOptionSchema = z.object({
  id: z.string(),
  name: z.string(),
});
export type OfficeOption = z.infer<typeof officeOptionSchema>;

type Props = {
  value: string | { id: string; name: string } | null;
  name?: string | undefined;
  onChange: (value: { id: string; name: string } | null) => void;
  onBlur: () => void;
  displayView?: boolean;
  allowedRoles: RoleType[];
  disabled?: boolean;
  invalid?: boolean;
  isClearable?: boolean;
  errorMessage?: string;
  filteredOptions?: OfficeOption[];
};

export const OfficeSelect = ({
  filteredOptions,
  value,
  name,
  onChange,
  onBlur,
  displayView,
  disabled,
  allowedRoles,
  errorMessage,
  invalid,
  isClearable = true,
}: Props) => {
  const { t } = useTranslate();
  const {
    state: { viewer },
  } = useCareContext();

  const options: OfficeOption[] = useMemo(() => {
    return (
      viewer?.tenantAccess.offices.filter((office) => {
        return office.roles.some((role) =>
          allowedRoles.includes(role.roleType),
        );
      }) || []
    );
  }, [viewer, allowedRoles]);

  const selectedOption = options.find((option) => option.id === value);

  return (
    <Select
      name={name}
      aria-label={t("placeholder.chooseOffice").toString()}
      placeholder={t("placeholder.chooseOffice")}
      options={filteredOptions ? filteredOptions : options}
      value={selectedOption}
      isMulti={false}
      isClearable={isClearable}
      onChange={(value) =>
        onChange(value ? { id: value.id, name: value.name } : null)
      }
      onBlur={onBlur}
      isDisabled={disabled}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      displayView={displayView}
      errorMessage={errorMessage}
    />
  );
};
