import { useMemo, useState } from "react";
import { useTranslate } from "@tolgee/react";
import { Link } from "react-router-dom";

import {
  VisitorReportsQuery,
  useVisitorReportsQuery,
} from "../../../api/generated/graphql";
import { formatDuration } from "@frontend/lyng/utils/dateUtils";

import { ExportCSV, Table } from "../../../components/common";
import { Headline, HeadlineContainer } from "@frontend/lyng/typography";
import { ColumnDef } from "@tanstack/react-table";
import { useSortingOptions } from "../../../utils/hooks/useSortingOptions";
import { DateTime } from "luxon";
import { DateRangePicker } from "@frontend/lyng/forms";
import { useCareContext } from "../../../providers";

type WorkloadRow = VisitorReportsQuery["visitorReports"][number];
const CaregiverWorkload = () => {
  const { t } = useTranslate();
  const { collator, nameOrderFn } = useSortingOptions();
  const {
    state: { viewer },
  } = useCareContext();

  const [dateRange, setDateRange] = useState({
    start: DateTime.local().startOf("month"),
    end: DateTime.local().endOf("month"),
  });

  const { data, error, loading } = useVisitorReportsQuery({
    fetchPolicy: "network-only",
    skip: dateRange.start > dateRange.end,
    variables: {
      from: dateRange.start.startOf("day").toISO() ?? "",
      to: dateRange.end.endOf("day").toISO() ?? "",
    },
  });

  const csvData = useMemo(() => {
    return [
      [
        t("reports.workload.caregiver"),
        t("reports.workload.scheduled"),
        t("reports.workload.worked"),
      ],

      ...(data?.visitorReports.map((report) => [
        `${report.visitor.firstName} ${report.visitor.lastName}`,
        formatDuration(report.timeScheduledMinutes, t, false, true, false),
        formatDuration(report.timeWorkedMinutes, t, false, true, false),
      ]) ?? []),
    ];
  }, [data, t]);

  const columns = useMemo<ColumnDef<WorkloadRow>[]>(() => {
    return [
      {
        id: "caregiver",
        header: t("reports.workload.caregiver") ?? "",
        accessorFn: (row) => nameOrderFn(row.visitor),
        cell: (row) =>
          row.row.original.visitor.firstName !== "deleted.User" ? (
            <Link to={`/caregivers/${row.row.original.visitor.id}`}>
              {row.getValue<string>()}
            </Link>
          ) : (
            <span className="cursor-not-allowed">
              {t("reports.workload.deletedUser")}
            </span>
          ),
        sortingFn: (a, b, cId) =>
          collator.compare(a.getValue(cId), b.getValue(cId)),
      },
      {
        header: t("reports.workload.scheduled") ?? "",
        accessorFn: (row) =>
          formatDuration(row.timeScheduledMinutes, t, false, true, false),
        sortingFn: (a, b) =>
          a.original.timeScheduledMinutes - b.original.timeScheduledMinutes,
      },
      {
        header: t("reports.workload.worked") ?? "",
        accessorFn: (row) =>
          formatDuration(row.timeWorkedMinutes, t, false, true, false),
        sortingFn: (a, b) =>
          a.original.timeWorkedMinutes - b.original.timeWorkedMinutes,
      },
    ];
  }, [collator, nameOrderFn, t]);

  if (error) return <p>Error : {error.message}</p>;

  return (
    <div className="mb-8 p-5 md:p-0">
      <HeadlineContainer>
        <Headline size="l">{t("reports.workload.title")}</Headline>

        <div className="flex items-center justify-between gap-4 ">
          <DateRangePicker
            dateSettings={viewer?.tenantSettings}
            aria-label="Select date range"
            name="dateRange"
            value={dateRange}
            onChange={(value) => value && setDateRange(value)}
          />
          <ExportCSV data={csvData} name="report_caregiverWorkload" />
        </div>
      </HeadlineContainer>

      <Table
        columns={columns}
        data={data?.visitorReports ?? []}
        defaultSorting={[{ id: "caregiver", desc: false }]}
        loading={loading}
      />
    </div>
  );
};

export default CaregiverWorkload;
