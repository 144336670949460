type Props = {
  className?: string;
  width?: number;
  height?: number;
};
export const ReportsIcon = ({ className, width = 16, height = 16 }: Props) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0001 1.59998V3.99998C10.0001 4.4418 10.3582 4.79998 10.8001 4.79998H13.2001M5.60005 4.79998H7.20005M5.60005 7.19998H10.4001M5.60005 9.59998H10.4001M12.0001 2.79998C11.644 2.4814 11.2745 2.10356 11.0413 1.85816C10.8861 1.69487 10.6716 1.59998 10.4463 1.59998H4.39987C3.51621 1.59998 2.79987 2.31632 2.79987 3.19997L2.7998 12.7999C2.7998 13.6836 3.51614 14.3999 4.3998 14.3999L11.5998 14.4C12.4835 14.4 13.1998 13.6837 13.1998 12.8L13.2 4.31853C13.2001 4.11397 13.122 3.91733 12.98 3.77006C12.7175 3.49774 12.2791 3.04965 12.0001 2.79998Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
