import { useTranslate } from "@tolgee/react";
import { z } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-hot-toast";

import { Form, Input } from "../../../components/formfields";
import { Button, SlideOver } from "../../../components/common";
import {
  DivisionSettings,
  OrganizationDocument,
  useDivisionCreateMutation,
  useDivisionSettingsUpdateMutation,
  useDivisionUpdateMutation,
  ViewerDocument,
} from "../../../api/generated/graphql";
import { errorToToastMessage } from "../../../utils/toastUtils";
import { Checkboxes, FormGroup } from "@frontend/lyng/forms";
import { generateEnabledSettings } from "../../../utils/organizationUtils";
import { useFeatureFlag } from "../../../providers/FeatureFlags";

const validationSchema = z.object({
  name: z.string().min(1),
  enabledSettings: z.array(z.string()).nullable(),
});

type FormInput = z.infer<typeof validationSchema>;
export type Division = FormInput;

export type Props = {
  type: "update" | "create";
  division?: Division & {
    id: string;
    enabledSettings: string[];
    settings: DivisionSettings;
  };
  onClose: () => void;
};

export const OrganizationDivisionFormModal = ({
  type,
  division,
  onClose,
}: Props) => {
  const { t } = useTranslate();
  const ff_caregiverCanCreateAndUpdateVisits = useFeatureFlag(
    "CaregiverCreateAndUpdateVisits",
  );

  const {
    control,
    register,
    handleSubmit,
    formState: { isValid, isDirty, isLoading },
  } = useForm<FormInput>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      name: division?.name || "",
      enabledSettings: division?.enabledSettings
        ? division.enabledSettings
        : generateEnabledSettings(division?.settings),
    },
  });

  const [divisionCreateMutation] = useDivisionCreateMutation({
    refetchQueries: [OrganizationDocument],
  });
  const [divisionUpdateMutation] = useDivisionUpdateMutation();
  const [divisionSettingsUpdateMutation] = useDivisionSettingsUpdateMutation({
    refetchQueries: [OrganizationDocument, ViewerDocument],
  });
  const onSubmit: SubmitHandler<FormInput> = (value) => {
    const promise =
      division && type === "update"
        ? divisionUpdateMutation({
            variables: {
              id: division.id,
              input: {
                name: value.name,
              },
            },
          }).then(() => {
            if (!ff_caregiverCanCreateAndUpdateVisits) {
              onClose();
              return;
            }

            divisionSettingsUpdateMutation({
              variables: {
                input: {
                  divisionId: division.id,
                  caregiversCanCreateAndUpdateVisits:
                    value.enabledSettings?.includes(
                      "caregiversCanCreateAndUpdateVisits",
                    ) ?? false,
                },
              },
            }).then(() => {
              onClose();
            });
          })
        : divisionCreateMutation({
            variables: {
              input: {
                name: value.name,
              },
            },
          }).then((division) => {
            if (division.data && ff_caregiverCanCreateAndUpdateVisits) {
              divisionSettingsUpdateMutation({
                variables: {
                  input: {
                    divisionId: division.data?.divisionCreate.id,
                    caregiversCanCreateAndUpdateVisits:
                      value.enabledSettings?.includes(
                        "caregiversCanCreateAndUpdateVisits",
                      ) ?? false,
                  },
                },
              }).then(() => {
                onClose();
              });
            } else {
              onClose();
            }
          });

    toast.promise(promise, {
      loading:
        type === "update" ? t("division.updating") : t("division.creating"),
      success:
        type === "update" ? t("division.updated") : t("division.created"),
      error: (error) => errorToToastMessage(error),
    });
  };

  return (
    <SlideOver
      title={`${
        type === "update" ? t("update").toString() : t("create").toString()
      } ${t("division.label").toString()}`}
      onClose={onClose}
    >
      <Form className="mt-10" onSubmit={handleSubmit(onSubmit)}>
        <Form.Body>
          <FormGroup label={t("division.table.name")}>
            <Input
              {...register("name")}
              id="division.name"
              type="text"
              placeholder={t("division.table.name")}
            />
          </FormGroup>
          {ff_caregiverCanCreateAndUpdateVisits && (
            <FormGroup label={t("division.table.settings")}>
              <Checkboxes
                name="enabledSettings"
                control={control}
                options={[
                  {
                    label: t(
                      "division.settings.caregiversCanCreateAndUpdateVisits",
                    ),
                    value: "caregiversCanCreateAndUpdateVisits",
                  },
                ]}
              />
            </FormGroup>
          )}
        </Form.Body>
        <Form.Footer>
          <Button
            variant="secondary"
            text={t("cancel").toString()}
            onClick={() => {
              onClose();
            }}
          />
          <Button
            variant="primary"
            text={t("save").toString()}
            type="submit"
            disabled={!isValid || !isDirty || isLoading}
          />
        </Form.Footer>
      </Form>
    </SlideOver>
  );
};
