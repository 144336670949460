import { useCareRecipientReactivateMutation } from "../../../api/generated/graphql";
import { Button } from "../../../components/common";
import { useTranslate } from "@tolgee/react";
import { useRevalidator } from "react-router-dom";
import toast from "react-hot-toast";
import { errorToToastMessage } from "../../../utils/toastUtils";
import { useDateFormatter } from "../../../utils/dateUtils";

type Props = {
  id: string;
  officeId: string;
  name: string;
  deactivatedAt: string;
};
export const DeactivatedCareRecipient = ({
  id,
  officeId,
  name,
  deactivatedAt,
}: Props) => {
  const { t } = useTranslate();
  const revalidator = useRevalidator();

  const { formatDate } = useDateFormatter();

  const [careRecipientReactivate, { loading: reactivateLoading }] =
    useCareRecipientReactivateMutation();

  const handleReactivate = () => {
    const promise = careRecipientReactivate({
      variables: {
        id: id,
        officeId: officeId,
      },
    }).then(() => {
      revalidator.revalidate();
    });

    toast.promise(promise, {
      loading: t("careRecipientForm.reactivating", { name }),
      success: () => t("careRecipientForm.reactivated", { name }),
      error: (err) => errorToToastMessage(err),
    });
  };
  return (
    <div className="mt-16 flex flex-col items-center gap-2">
      <h2 className="text-2xl font-bold text-gray-800">
        {t("careRecipientDetails.deactivatedCareRecipient.heading", {
          name: name,
        })}
      </h2>
      <p className="text-gray-500">
        {t("careRecipientDetails.deactivatedCareRecipient.subHeading", {
          name: name,
          deactivatedAt: formatDate(deactivatedAt),
        })}
      </p>
      <Button
        className="mt-4"
        variant="primary"
        size="lg"
        onClick={handleReactivate}
        text={t("reactivate") ?? ""}
        loading={reactivateLoading}
        disabled={reactivateLoading}
      />
    </div>
  );
};
