import { useNavigate, useParams } from "react-router-dom";
import { useTranslate } from "@tolgee/react";

import { LoadingSpinner, SlideOver } from "../../components/common";

import AllContacts from "./allContacts";
import AddContact from "./addContact";
import { useContactsByCareRecipientIdQuery } from "../../api/generated/graphql";
import { useMemo } from "react";

const ContactModal = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const { contactId, careRecipientId } = useParams();

  const { data: contactsData, loading: loadingContacts } =
    useContactsByCareRecipientIdQuery({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      variables: { careRecipientId: careRecipientId! },
    });

  const contacts = contactsData?.contactsByCareRecipientId;
  const hasContacts = contacts && contacts.length;
  const paramContact = contacts?.find((c) => c.id === contactId);

  const primaryContact = useMemo(() => {
    return (
      contactsData?.contactsByCareRecipientId.find((c) => c.isPrimary) || null
    );
  }, [contactsData?.contactsByCareRecipientId]);

  const title = hasContacts
    ? t("contacts.seeAll")
    : contactId
      ? t("contacts.update")
      : t("contacts.add");

  const handleClose = () =>
    navigate(contactId ? "../.." : "..", { relative: "path" });

  return (
    <SlideOver title={title} onClose={handleClose}>
      {!contactsData && loadingContacts && (
        <div className="flex justify-center">
          <LoadingSpinner />
        </div>
      )}

      {!loadingContacts && (!hasContacts || paramContact) && (
        <AddContact
          onClose={handleClose}
          contact={paramContact}
          careRecipientId={careRecipientId ?? ""}
          primaryContactId={primaryContact?.id ?? null}
        />
      )}
      {hasContacts && !paramContact && (
        <AllContacts contacts={contacts} onClose={handleClose} />
      )}
    </SlideOver>
  );
};

export default ContactModal;
