type Props = {
  id: string;
  label: string;
  value?: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};
export const CheckboxButton = ({
  id,
  label,
  value,
  checked,
  onChange,
}: Props) => {
  return (
    <span>
      <input
        type="checkbox"
        id={id}
        value={value}
        checked={checked}
        onChange={onChange}
        className="peer hidden"
      />
      <label
        htmlFor={id}
        className="rounded-full border border-primary-200 bg-white p-1 text-primary-600 shadow-sm hover:bg-primary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 peer-checked:bg-primary-600 peer-checked:text-white"
      >
        <span className="inline-flex h-5 w-5 items-center justify-center">
          {label}
        </span>
      </label>
    </span>
  );
};
