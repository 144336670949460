/**
 * Converts a value to a number, if possible.
 */
export const valueAsNumberOpt = (v: unknown): number | undefined => {
  if (typeof v === "number") {
    return v;
  }
  if (typeof v !== "string") {
    return undefined;
  }
  const parsed = !v ? undefined : parseFloat(v);
  if (parsed !== undefined && isNaN(parsed)) {
    return undefined;
  }
  return parsed;
};
