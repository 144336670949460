import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import {
  Button,
  Card,
  SimpleModal,
  SlideOver,
} from "../../../components/common";
import { useTranslate } from "@tolgee/react";
import Dropzone from "react-dropzone";
import UploadIcon from "../../../assets/svg/upload.svg?react";
import {
  File as GqlFileType,
  useCareRecipientFileDeleteMutation,
  useCareRecipientFileUploadUrlMutation,
} from "../../../api/generated/graphql";
import toast from "react-hot-toast";
import { useRevalidator } from "react-router-dom";

interface FileCardProps {
  files: {
    url: string;
    name: string;
  }[];
  id: string;
}
const FileCard = ({ files, id }: FileCardProps) => {
  const { t } = useTranslate();
  const revalidator = useRevalidator();
  const [showAddFile, setShowAddFile] = useState<boolean>(false);
  const [uploadUrl, setUploadUrl] = useState<string | undefined>();
  const [stagedFile, setStagedFile] = useState<File[] | undefined>();
  const [fileToDelete, setFileToDelete] = useState<GqlFileType | undefined>();
  const [showDeleteFile, setShowDeleteFile] = useState<boolean>(false);
  const [showManageFiles, setShowManageFiles] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);

  const [CareRecipientFileUploadUrlMutation, { loading: loadingUrl }] =
    useCareRecipientFileUploadUrlMutation({});

  useEffect(() => {
    if (stagedFile) {
      CareRecipientFileUploadUrlMutation({
        variables: {
          fileName: stagedFile[0].name,
          id,
        },
        refetchQueries: ["CareRecipientById"],
      }).then((res) => {
        setUploadUrl(res.data?.careRecipientFileUploadUrl);
      });
    }
  }, [stagedFile, CareRecipientFileUploadUrlMutation, id]);

  const uploadFile = async (file: File, url: string) => {
    setUploading(true);
    const res = await fetch(url, {
      method: "PUT",
      body: file,
    });

    if (!res.ok) {
      toast.error("Error uploading file");
      Sentry.captureException(await res.text(), (scope) => {
        scope.setTransactionName("Error uploading file");
        return scope;
      });
      return;
    }
    revalidator.revalidate();

    // clear states
    setUploading(false);
    setStagedFile(undefined);
    setUploadUrl(undefined);
    setShowAddFile(false);
    toast.success(t("careRecipientFiles.uploadSuccess"));
  };

  const [CareRecipientFileDeleteMutation] = useCareRecipientFileDeleteMutation(
    {},
  );

  const deleteFile = (fileName: string, id: string) => {
    CareRecipientFileDeleteMutation({
      variables: {
        fileName,
        id,
      },
    }).then(() => {
      toast.success(t("careRecipientFiles.deleteSuccess"));
      revalidator.revalidate();
      setFileToDelete(undefined);
      setShowManageFiles(false);
    });
  };

  return (
    <>
      <Card>
        <h4 className="mb-3 text-xs font-medium uppercase text-gray-700">
          {t("careRecipientFiles.files")}
        </h4>

        <div className="grid gap-2">
          {files.length < 1 && <p>{t("careRecipientFiles.empty")}</p>}
          {files.slice(0, 3).map((file) => (
            <a key={file.name} href={file.url} target="_blank" rel="noreferrer">
              {file.name}
            </a>
          ))}
        </div>

        <div className="mt-6 flex gap-1">
          <Button
            className="flex-1"
            variant="secondary"
            disabled={files.length < 1}
            text={t("careRecipientFiles.manageFiles").toString()}
            onClick={() => setShowManageFiles(true)}
          />
          <Button
            className="flex-1"
            variant="secondary"
            text={t("careRecipientFiles.addFiles").toString()}
            onClick={() => setShowAddFile(true)}
          />
        </div>
      </Card>

      {/* Drawers & Modals */}
      {showAddFile && (
        <SlideOver
          title={t("careRecipientFiles.addFiles").toString()}
          onClose={() => {
            setShowAddFile(false);
            setStagedFile(undefined);
          }}
        >
          <p>{t("careRecipientFiles.maxSize")}</p>
          {stagedFile && uploadUrl ? (
            <div className="my-4">
              <h4 className="font-semibold">
                {t("careRecipientFiles.select")}
              </h4>
              <p className="mb-4 mt-4 italic">{stagedFile[0].name}</p>
              <Button
                variant="secondary"
                className="w-full"
                text={t("careRecipientFiles.upload") as string}
                loading={loadingUrl || uploading}
                disabled={!uploadUrl || !stagedFile}
                onClick={() => uploadFile(stagedFile[0], uploadUrl)}
              />
            </div>
          ) : (
            <Dropzone onDrop={(acceptedFile) => setStagedFile(acceptedFile)}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div
                    {...getRootProps()}
                    className="my-4 flex h-60 cursor-pointer flex-col items-center justify-center gap-2 rounded-md border border-primary-200"
                  >
                    <input {...getInputProps()} />
                    <UploadIcon />
                    <p className="font-semibold">
                      {t("careRecipientFiles.clickOrDrag")}
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>
          )}
        </SlideOver>
      )}
      {showManageFiles && (
        <SlideOver
          title={t("careRecipientFiles.manageFiles").toString()}
          onClose={() => setShowManageFiles(false)}
        >
          <h4 className="mb-4 text-xl font-semibold">All files</h4>
          <ul className="list-none">
            {files.map((file) => (
              <li
                key={file.name}
                className="flex items-center justify-between border-t border-t-slate-300 py-4"
              >
                <a
                  key={file.name}
                  href={file.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {file.name}
                </a>
                <div className="flex gap-4">
                  <Button
                    variant="danger"
                    text={t("careRecipientFiles.delete") as string}
                    onClick={() => {
                      setFileToDelete(file);
                      setShowDeleteFile(true);
                    }}
                  />
                </div>
              </li>
            ))}
          </ul>

          <SimpleModal
            onAccept={() => {
              if (fileToDelete) {
                deleteFile(fileToDelete.name, id);
                setShowDeleteFile(false);
              }
            }}
            onClose={() => setShowDeleteFile(false)}
            show={showDeleteFile}
            title={t("careRecipientFiles.deleteFile")}
          >
            <p>{t("careRecipientFiles.confirmDeletion")}</p>
          </SimpleModal>
        </SlideOver>
      )}
    </>
  );
};

export default FileCard;
