import {
  HolidaysForYearQuery,
  useHolidaysForYearLazyQuery,
} from "../../api/generated/graphql";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";

export type Holiday = HolidaysForYearQuery["holidaysForYear"][0];

export type useGetHolidaysResult = {
  holidays: Holiday[];
  error: Error | undefined;
  loading: boolean;
};

export default function useGetHolidays(
  dateFrom: string,
  dateTo: string,
  countryCode: string | undefined
): useGetHolidaysResult {
  const [fromHolidays, setFromHolidays] = useState<Holiday[]>([]);
  const [toHolidays, setToHolidays] = useState<Holiday[]>([]);
  const [error, setError] = useState<Error | undefined>();

  const [queryHolidays, { loading }] = useHolidaysForYearLazyQuery({
    fetchPolicy: "cache-first",
  });
  useEffect(() => {
    const fromYear = new Date(dateFrom).getFullYear();
    const toYear = new Date(dateTo).getFullYear();

    if (!countryCode) {
      return;
    }

    queryHolidays({
      variables: {
        year: fromYear,
        countryCode,
      },
    })
      .then(({ data }) => {
        setFromHolidays(data?.holidaysForYear ?? []);
      })
      .catch((error) => {
        setError(error);
      });

    // If the dates span multiple years, we need to fetch the holidays for the
    // second year as well.
    // Does not support more than 2 years.
    if (fromYear !== toYear) {
      queryHolidays({
        variables: {
          year: toYear,
          countryCode,
        },
      })
        .then(({ data }) => {
          setToHolidays(data?.holidaysForYear ?? []);
        })
        .catch((error) => {
          setError(error);
        });
    }
  }, [dateFrom, dateTo, countryCode, queryHolidays]);

  const fromDT = DateTime.fromISO(dateFrom);
  const toDT = DateTime.fromISO(dateTo);

  // Filter out holidays that are not in the date range
  const ret = [...fromHolidays, ...toHolidays].filter((holiday) => {
    const holidayDT = DateTime.fromFormat(holiday.date, "yyyy-MM-dd");
    return holidayDT >= fromDT && holidayDT < toDT;
  });

  return {
    holidays: ret,
    error,
    loading,
  };
}
