import {
  IntegrationStatus,
  useIntegrationRunByIdQuery,
  useIntegrationRunsQuery,
  useVisitlogReportIntegrateMutation,
} from "../../../api/generated/graphql";
import { DateTime } from "luxon";

type pendingState = {
  type: "pending";
  sendReport: () => Promise<unknown>;
};
export type readyState = {
  type: "readyState";
  lastReportSent: { from: string; to: string } | null;
  sendReport: () => Promise<unknown>;
};
type mutationCalledState = {
  type: "mutationCalled";
  status: "inProgress" | "success" | "failed";
};

export type ModalState = pendingState | readyState | mutationCalledState;

export const useExportModalState = (
  from: DateTime,
  to: DateTime
): ModalState => {
  const integrationRunsResult = useIntegrationRunsQuery();

  const [visitlogReportIntegrateMutation, mutationResult] =
    useVisitlogReportIntegrateMutation({
      variables: {
        from: from.startOf("day").toISO() ?? "",
        to: to.endOf("day").toISO() ?? "",
      },
      onCompleted: () => startPolling(500),
    });

  const {
    data: pollingResult,
    startPolling,
    stopPolling,
  } = useIntegrationRunByIdQuery({
    skip: !mutationResult.data,
    variables: {
      id: mutationResult.data?.visitlogReportIntegrate ?? "",
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true, // Needed to trigger onCompleted on every poll
    onCompleted: (data) => {
      if (
        data.integrationRunById?.integrationStatus ===
          IntegrationStatus.Success ||
        data.integrationRunById?.integrationStatus === IntegrationStatus.Failed
      ) {
        stopPolling();
      }
    },
  });

  if (integrationRunsResult.loading) {
    return {
      type: "pending",
      sendReport: visitlogReportIntegrateMutation,
    };
  }

  if (mutationResult.called) {
    if (
      mutationResult.error ||
      pollingResult?.integrationRunById?.integrationStatus ===
        IntegrationStatus.Failed
    ) {
      return { type: "mutationCalled", status: "failed" };
    }

    if (
      pollingResult?.integrationRunById?.integrationStatus ===
      IntegrationStatus.Success
    ) {
      return { type: "mutationCalled", status: "success" };
    }
    return { type: "mutationCalled", status: "inProgress" };
  }

  return {
    type: "readyState",
    lastReportSent: integrationRunsResult.data?.integrationRuns?.[0] ?? null,
    sendReport: visitlogReportIntegrateMutation,
  };
};
