import { UseTranslateResult } from "@tolgee/react";
import {
  RelationshipType,
  ActivityStatus,
  CancelledReason,
  BillingType,
} from "../api/generated/graphql";
import { match } from "ts-pattern";
import { useCallback } from "react";

export const relationshipTypeToString = (type: RelationshipType): string => {
  if (type === RelationshipType.Family) return "relationshipTypes.family";
  if (type === RelationshipType.Friend) return "relationshipTypes.friend";
  if (type === RelationshipType.Medical) return "relationshipTypes.medical";
  if (type === RelationshipType.Other) return "relationshipTypes.other";
  if (type === RelationshipType.Spouse) return "relationshipTypes.spouse";
  if (type === RelationshipType.Therapy) return "relationshipTypes.therapy";
  return "relationshipTypes.other";
};

export const activityStatusToString = (status: ActivityStatus): string => {
  return match(status)
    .with(ActivityStatus.Completed, () => "visitLog.activityStatus.completed")
    .with(
      ActivityStatus.CouldNotComplete,
      () => "visitLog.activityStatus.couldNotComplete",
    )
    .with(ActivityStatus.NotStarted, () => "visitLog.activityStatus.notStarted")
    .otherwise(() => "visitLog.activityStatus.notStarted");
};

export const useTranslateCancelledReason = (t: UseTranslateResult["t"]) => {
  return useCallback(
    (reason: CancelledReason) => {
      return match(reason)
        .with(CancelledReason.CancelledByCareProvider, () =>
          t(
            "cancelledReasonSelect.cancelVisitReason.CANCELLED_BY_CARE_PROVIDER",
          ),
        )
        .with(CancelledReason.CancelledByCareRecipient, () =>
          t(
            "cancelledReasonSelect.cancelVisitReason.CANCELLED_BY_CARE_RECIPIENT",
          ),
        )
        .with(CancelledReason.Holiday, () =>
          t("cancelledReasonSelect.cancelVisitReason.HOLIDAY"),
        )
        .with(CancelledReason.HospitalCareFacility, () =>
          t("cancelledReasonSelect.cancelVisitReason.HOSPITAL_CARE_FACILITY"),
        )
        .with(CancelledReason.Other, () =>
          t("cancelledReasonSelect.cancelVisitReason.OTHER"),
        )
        .exhaustive();
    },
    [t],
  );
};

export const useTranslateBillingType = (t: UseTranslateResult["t"]) => {
  return useCallback(
    (billingType: BillingType) => {
      return match(billingType)
        .with(BillingType.Fixed, () => t("visitTypes.billingTypes.FIXED"))
        .with(BillingType.Hourly, () => t("visitTypes.billingTypes.HOURLY"))
        .exhaustive();
    },
    [t],
  );
};
