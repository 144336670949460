import { useState } from "react";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { useTranslate } from "@tolgee/react";
import toast from "react-hot-toast";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  useContactCreateMutation,
  useContactUpdateMutation,
  RelationshipType,
  ContactForCareRecipient,
  ContactCreateInput,
  ContactUpdateInput,
  ContactCreateMutation,
  ContactUpdateMutation,
} from "../../api/generated/graphql";
import { errorToToastMessage } from "../../utils/toastUtils";

import { Button } from "../../components/common";

import {
  FormGroup,
  Input,
  Label,
  Form,
  ValidationMessage,
  RelationshipSelect,
  relationshipSchema,
  getRelationshipOption,
  Checkbox,
  Checkboxes,
  CheckboxGroup,
} from "../../components/formfields";

import NewPrimaryModal from "./components/newPrimary";
import { Type, userCreated, userUpdated } from "../../typewriter/segment";

enum Role {
  Primary = "Primary",
  PowerOfAttorney = "Power of attorney",
  Payer = "Payer",
}

const validationSchema = z
  .object({
    firstName: z.string().min(1),
    lastName: z.string().min(1, "Last name is required"),
    email: z
      .string()
      .email({ message: "Invalid email address" })
      .optional()
      .or(z.literal("")),
    phone: z.string(),
    homePhone: z.string().nullable(),
    birthDate: z.string().nullable(),
    pid: z.string().nullable(),
    relationship: relationshipSchema.nullable(),
    roles: z.array(z.nativeEnum(Role)),
    hasReadAccess: z.boolean().nullable(),
  })
  .refine((data) => !data.hasReadAccess || data.email, {
    message: "Email must be provided for app access",
    path: ["email"],
  });

export type FormInput = z.infer<typeof validationSchema>;

type Props = {
  careRecipientId: string;
  // If contact has primary contact we need to show confirmation modal for overriding that
  primaryContactId: string | null;
  contact?: ContactForCareRecipient;
  onClose: () => void;
};

const getRoles = ({
  isPrimary,
  isPayer,
  hasPowerOfAttorney,
}: {
  isPrimary?: boolean;
  isPayer?: boolean;
  hasPowerOfAttorney?: boolean;
}) => {
  const ret = [];
  if (isPrimary) ret.push(Role.Primary);
  if (isPayer) ret.push(Role.Payer);
  if (hasPowerOfAttorney) ret.push(Role.PowerOfAttorney);
  return ret;
};

const AddContact = ({
  onClose,
  contact,
  careRecipientId,
  primaryContactId,
}: Props) => {
  const { t } = useTranslate();

  const [showNewPrimaryConfirmation, setShowNewPrimaryConfirmation] =
    useState(false);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormInput>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      firstName: contact?.firstName ?? "",
      lastName: contact?.lastName ?? "",
      email: contact?.email ? contact.email : undefined,
      phone: contact?.phone ? contact.phone : undefined,
      homePhone: contact?.homePhone ?? null,
      birthDate: contact?.birthDate ?? null,
      pid: contact?.pid ?? null,
      relationship: contact
        ? getRelationshipOption(contact?.relationshipType)
        : null,
      roles: getRoles({
        isPrimary: contact?.isPrimary,
        isPayer: contact?.isPayer,
        hasPowerOfAttorney: contact?.hasPowerOfAttorney,
      }),
      hasReadAccess: contact?.hasReadAccess,
    },
  });

  const [contactCreateMutation, { loading: createLoading }] =
    useContactCreateMutation({
      refetchQueries: ["ContactsByCareRecipientId"],
    });

  const [contactUpdateMutation, { loading: updateLoading }] =
    useContactUpdateMutation({
      refetchQueries: ["ContactsByCareRecipientId"],
    });

  const roleCheckboxes: { key: string; label: string; value: Role }[] = [
    {
      key: "isPrimary",
      label: `${t("roles.primary")} ${t("roles.only")}`,
      value: Role.Primary,
    },
    {
      key: "isPayer",
      label: `${t("roles.payer")}`,
      value: Role.Payer,
    },
    {
      key: "hasPowerOfAttorney",
      label: t("roles.powerOfAttorney"),
      value: Role.PowerOfAttorney,
    },
  ];

  const rolesToInputs = (roles: Role[]) => ({
    isPrimary: roles.includes(Role.Primary),
    hasPowerOfAttorney: roles.includes(Role.PowerOfAttorney),
    isPayer: roles.includes(Role.Payer),
  });

  const createContact = (
    input: ContactCreateInput,
  ): Promise<Partial<ContactCreateMutation> | null | undefined> =>
    contactCreateMutation({
      variables: { input },
    }).then((data) => {
      onClose();
      userCreated({
        user_id: data.data?.contactCreate.id ?? "",
        type: Type.Contact,
      });
      return data.data;
    });

  const updateContact = (
    input: ContactUpdateInput,
    contactId: string,
  ): Promise<ContactUpdateMutation | null | undefined> =>
    contactUpdateMutation({
      variables: {
        careRecipientId: careRecipientId ?? "",
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: contactId,
        input,
      },
    }).then((data) => {
      onClose();
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      userUpdated({
        user_id: contactId,
        type: Type.Contact,
      });
      return data.data;
    });

  const doAction = () => {
    const { roles, relationship, email, ...rest } = getValues();
    const input = {
      ...rest,
      relationshipType: relationship?.value || RelationshipType.Other,
      ...rolesToInputs(roles),
    };

    const promise = (() => {
      if (!contact)
        return createContact({
          ...input,
          email,
          careRecipientId: careRecipientId ?? "",
        });

      return updateContact(input, contact.id);
    })();

    toast.promise(promise, {
      loading: contact ? t("contacts.saving") : t("contacts.creating"),
      success: () =>
        t("saved", { name: `${input.firstName} ${input.lastName}` }),
      error: (err) => errorToToastMessage(err),
    });
  };

  const onSubmit: SubmitHandler<FormInput> = (values: FormInput) => {
    const primarySelected = values.roles.some((el) => el === Role.Primary);
    if (
      primarySelected &&
      primaryContactId &&
      primaryContactId !== contact?.id
    ) {
      setShowNewPrimaryConfirmation(true);
    } else {
      doAction();
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Body>
          <FormGroup>
            <Label htmlFor="firstName">{t("firstName")}</Label>
            <Input
              {...register("firstName")}
              placeholder={t("placeholder.firstName").toString()}
              id="firstName"
              type="text"
              invalid={!!errors.firstName}
            />
            {errors.firstName && (
              <ValidationMessage>{errors.firstName.message}</ValidationMessage>
            )}
          </FormGroup>

          <FormGroup>
            <Label htmlFor="lastName">{t("lastName")}</Label>
            <Input
              {...register("lastName")}
              placeholder={t("placeholder.lastName").toString()}
              id="lastName"
              type="text"
              invalid={!!errors.lastName}
            />
            {errors.lastName && (
              <ValidationMessage>{errors.lastName.message}</ValidationMessage>
            )}
          </FormGroup>

          <FormGroup>
            <Label htmlFor="email">{t("email")}</Label>
            <Input
              {...register("email")}
              placeholder={t("placeholder.email").toString()}
              id="email"
              type="text"
              invalid={!!errors.email}
              disabled={!!contact}
            />
            {errors.email && (
              <ValidationMessage>{errors.email.message}</ValidationMessage>
            )}
          </FormGroup>

          <FormGroup>
            <Label htmlFor="phone">{t("phone")}</Label>
            <Input
              {...register("phone")}
              placeholder={t("placeholder.phone").toString()}
              id="phone"
              type="text"
              invalid={!!errors.phone}
            />
            {errors.phone && (
              <ValidationMessage>{errors.phone.message}</ValidationMessage>
            )}
          </FormGroup>

          <FormGroup>
            <Label htmlFor="homePhone">{t("homePhone")}</Label>
            <Input
              {...register("homePhone")}
              placeholder={t("placeholder.homePhone").toString()}
              id="homePhone"
              type="text"
              invalid={!!errors.homePhone}
            />
            {errors.homePhone && (
              <ValidationMessage>{errors.homePhone.message}</ValidationMessage>
            )}
          </FormGroup>

          <FormGroup>
            <Label htmlFor="relationship">{t("relationship")}</Label>
            <Controller
              control={control}
              name="relationship"
              render={({ field }) => (
                <RelationshipSelect
                  name={field.name}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  value={field.value}
                />
              )}
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor="roles">{t("role")}</Label>
            <CheckboxGroup>
              <Checkboxes
                control={control}
                name="roles"
                options={roleCheckboxes}
              />
            </CheckboxGroup>
          </FormGroup>

          <FormGroup>
            <Label htmlFor="hasReadAccess">{t("contacts.appAccess")}</Label>
            <CheckboxGroup>
              <Checkbox
                {...register("hasReadAccess")}
                id="hasReadAccess"
                type="checkbox"
                label={t("contacts.appAccessLabel").toString()}
              />
            </CheckboxGroup>
          </FormGroup>
        </Form.Body>
        <Form.Footer>
          <Button
            variant="secondary"
            onClick={() => onClose()}
            text={t("cancel").toString()}
            disabled={updateLoading || createLoading}
          />
          <Button
            variant="primary"
            type="submit"
            text={t("ok").toString()}
            disabled={updateLoading || createLoading}
          />
        </Form.Footer>
      </Form>

      <NewPrimaryModal
        show={showNewPrimaryConfirmation}
        onClose={() => setShowNewPrimaryConfirmation(false)}
        onAccept={doAction}
      />
    </>
  );
};

export default AddContact;
