import { Frequency, Weekday, Absence } from "../../../api/generated/graphql";
import { EventInput } from "@fullcalendar/core";

import { Variant } from "../../../components/common";

export const Events = {
  Visit: "Visit",
  Absence: "Absence",
} as const;

export type EventsType = (typeof Events)[keyof typeof Events];

export type VisitInstance = {
  __typename?: string;
  id: string;
  careRecipient?: {
    firstName: string | null;
    lastName: string | null;
  } | null;
  careRecipientId: string;
  start: string;
  durationMinutes: number;
  visitorIds: string[];
  labelIds: string[];
  visitors?:
    | {
        firstName: string | null;
        lastName: string | null;
      }[]
    | null;
  recurrence: {
    id: string;
    start: string;
    end: string | null;
    rRule: {
      frequency: Frequency;
      weekdays: Weekday[] | null;
      weekStart: Weekday;
    } | null;
  } | null;
  exceptionId: string | null;
  clockInTime: string | null;
  clockOutTime: string | null;
};

//  export type VisitInstance = Partial<Visit>
export type AbsenceInstance = Partial<Absence>;
export type Event = VisitInstance | Partial<AbsenceInstance>;

// Exposed by fullcalendar
export type VisitExtendedProps = {
  careRecipientId: string;
  durationMinutes: number;
  visitorIds: string[];
  labelIds: string[];
  recurrences: {
    frequency: Frequency;
    weekdays: Weekday[] | null;
    weekStart: Weekday;
  } | null;
  recurrenceEnd: string | null;
  exceptionId: string | null;
  type: "Visit";
};

export type AbsenceExtendedProps = {
  type: "Absence";
};

export type HolidayExtendedProps = {
  type: "Holiday";
};

export type FullCalendarEvent = EventInput &
  (VisitExtendedProps | AbsenceExtendedProps | HolidayExtendedProps);

export type TopActions = {
  header: string;
  actions?: Array<{
    text: string;
    onClick: () => void;
    variant?: Variant;
  }>;
};
