import {
  UsersQuery,
  ResourceType,
  useUsersQuery,
  useUserInvitationResendMutation,
} from "../../../api/generated/graphql";
import { Button, Table } from "../../../components/common";
import { useCareContext } from "../../../providers/CareProvider";
import { ColumnDef } from "@tanstack/react-table";
import { useDateFormatter } from "../../../utils/dateUtils";
import { useSortingOptions } from "../../../utils/hooks/useSortingOptions";
import { errorToToastMessage } from "../../../utils/toastUtils";
import { useCallback, useMemo } from "react";
import toast from "react-hot-toast";
import { useTranslate } from "@tolgee/react";
import { RoleType } from "@frontend/lyng/api/generated/graphql";
import { match } from "ts-pattern";

type UsersRow = UsersQuery["users"][number];

const Users = () => {
  const {
    state: { viewer },
  } = useCareContext();
  const { formatRelativeTime } = useDateFormatter();

  const country = viewer?.tenantSettings.country;
  const { nameOrderFn } = useSortingOptions();
  const { t } = useTranslate();

  const { data, loading, error } = useUsersQuery();
  const [userInvitationMutation] = useUserInvitationResendMutation();

  const translateRoleType = useCallback(
    (roleType: RoleType) => {
      return match(roleType)
        .with(RoleType.Contact, () => t("roleType.CONTACT"))
        .with(RoleType.Caregiver, () => t("roleType.CAREGIVER"))
        .with(RoleType.CareRecipient, () => t("roleType.CARE_RECIPIENT"))
        .with(RoleType.SchedulingManager, () =>
          t("roleType.SCHEDULING_MANAGER"),
        )
        .with(RoleType.SuccessManager, () => t("roleType.SUCCESS_MANAGER"))
        .with(RoleType.Admin, () => t("roleType.ADMIN"))
        .exhaustive();
    },
    [t],
  );

  const translateResourceType = useCallback(
    (resourceType: ResourceType) => {
      return match(resourceType)
        .with(ResourceType.CareRecipient, () =>
          t("resourceType.CARE_RECIPIENT"),
        )
        .with(ResourceType.Division, () => t("resourceType.DIVISION"))
        .with(ResourceType.Office, () => t("resourceType.OFFICE"))
        .with(ResourceType.Region, () => t("resourceType.REGION"))
        .with(ResourceType.Tenant, () => t("resourceType.TENANT"))
        .exhaustive();
    },
    [t],
  );

  const columns = useMemo<ColumnDef<UsersRow>[]>(() => {
    const collator = new Intl.Collator(country);
    return [
      {
        id: "name",
        header: t("name") ?? "",
        accessorFn: (row) => nameOrderFn(row),
        sortingFn: (a, b, cId) =>
          collator.compare(a.getValue(cId), b.getValue(cId)),
      },
      {
        id: "email",
        header: t("email") ?? "",
        accessorFn: (row) => row.email,
        cell: (row) => (
          <a href={`tel:${row.getValue<string>()}`}>{row.getValue<string>()}</a>
        ),
      },
      {
        id: "lastLogin",
        header: t("users.lastLogin") ?? "",
        cell: (row) => {
          if (row.row.original.lastLogin) {
            return <div>{formatRelativeTime(row.row.original.lastLogin)}</div>;
          }
          return (
            <Button
              type="button"
              size="sm"
              variant="tertiary"
              text={t("users.resendInvite") ?? ""}
              onClick={() => {
                const toastSuccess = (email: string) => {
                  return t("users.inviteSent", { email });
                };

                const promise = userInvitationMutation({
                  variables: {
                    userId: row.row.original.id,
                  },
                });

                toast.promise(promise, {
                  loading: t("users.sendingInvite"),
                  success: () => toastSuccess(row.row.original.email ?? ""),
                  error: (err) => errorToToastMessage(err),
                });
              }}
            />
          );
        },
      },
      {
        id: "roles",
        header: t("rolesPlural") ?? "",
        enableSorting: false,
        cell: (row) => {
          const rolesSorted = row.row.original.roles.slice().sort((a, b) => {
            return a.roleType.localeCompare(b.roleType);
          });
          return (
            <ul>
              {rolesSorted.map((role) => (
                <li key={role.resource.id}>
                  {translateRoleType(role.roleType)} - {role.resource.name} (
                  {translateResourceType(role.resource.type)})
                </li>
              ))}
            </ul>
          );
        },
      },
    ];
  }, [
    country,
    t,
    nameOrderFn,
    formatRelativeTime,
    userInvitationMutation,
    translateRoleType,
    translateResourceType,
  ]);

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <Table
      loading={loading}
      data={data?.users ?? []}
      columns={columns}
      topAlign={true}
    ></Table>
  );
};

export default Users;
