import { ViewerQuery, Tenant } from "../api/generated/graphql";
import { ApolloError } from "@apollo/client";
import { FeatureFlags } from "./FeatureFlags";

export type CareteamState = {
  viewer: ViewerQuery["viewer"] | undefined;
  viewerError: ApolloError | undefined;
  selectedTenant: Tenant | undefined;
  featureFlags: FeatureFlags;
};

export const USER_TYPES = {
  SET_VIEWER: "setViewer",
  SET_VIEWER_ERROR: "setViewerError",
  SET_TENANT: "setTenant",
  SET_FEATURE_FLAG: "setFeatureFlag",
} as const;

export type CareteamAction = (typeof USER_TYPES)[keyof typeof USER_TYPES];

export type CareteamPayload = {
  [USER_TYPES.SET_VIEWER]: { viewer: ViewerQuery["viewer"]; tenant?: Tenant };
  [USER_TYPES.SET_VIEWER_ERROR]: ApolloError;
  [USER_TYPES.SET_TENANT]: Tenant;
  [USER_TYPES.SET_FEATURE_FLAG]: FeatureFlags;
};

type ActionMap<M extends CareteamPayload> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type CareteamActions =
  ActionMap<CareteamPayload>[keyof ActionMap<CareteamPayload>];
