import { useState, useMemo } from "react";
import { useLoaderData, useNavigate, useRevalidator } from "react-router-dom";
import { useTranslate } from "@tolgee/react";
import { match } from "ts-pattern";
import { DateTime } from "luxon";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import {
  CareRecipientByIdQuery,
  CareRecipientByIdQueryVariables,
  useActivitiesByCareRecipientIdQuery,
  useContactsByCareRecipientIdQuery,
  InputFieldType,
  Field,
  Priority,
  useCarelogForCareRecipientInsertMutation,
  RadioField,
  useCareRecipientByIdQuery,
  useFeedItemsByCareRecipientIdQuery,
} from "../../api/generated/graphql";
import { careRecipientById } from "../../api/operations/CareRecipientById";
import {
  ActivityDetailsModal,
  ActivitiesTable,
  Button,
  Card,
  SlideOver,
  Badge,
  ThreeColumnLayout,
  CardColumn,
} from "../../components/common";
import { Activity } from "../../types";
import { calculateAge } from "@frontend/lyng/utils/dateUtils";
import { PartialBy } from "../../utils/typeUtils";
import { CareRecipientSchedule } from "./careRecipientSchedule/CareRecipientSchedule";
import {
  Recipient,
  CareRecipientProfileFormModal,
} from "./CareRecipientProfileFormModal";
import ContactCard from "./components/contactCard";
import AddContact from "../../pages/contactModal/addContact";
import FileCard from "./components/fileCard";
import { DeactivatedCareRecipient } from "./components/deactivatedCareRecipient";
import { ActivitiesFeed } from "@frontend/lyng/activityFeed/ActivitiesFeed";
import { Empty } from "@frontend/lyng/assets/svg";
import { Headline, Link } from "@frontend/lyng/typography";
import { useCareContext } from "../../providers";
import { VisitDetailsContainer } from "../visitDetails/VisitDetailsContainer";
import {
  User,
  Phone,
  Location,
  HomePhone,
  IdentityCard,
} from "@frontend/lyng/assets/icons/16/filled";

export const careRecipientDetailsLoader = async (
  client: ApolloClient<NormalizedCacheObject>,
  id: string | undefined,
): Promise<CareRecipientByIdQuery> => {
  if (!id) {
    return Promise.reject("id is required");
  }

  const res = await client.query<
    CareRecipientByIdQuery,
    CareRecipientByIdQueryVariables
  >({
    query: careRecipientById,
    variables: {
      id,
    },
    fetchPolicy: "network-only",
  });

  return res.data;
};

export const CareRecipientDetails = () => {
  // Loader data handles initial load of data. UseQuery handles reading from cache and all updates from there.
  const loaderData = (useLoaderData() as CareRecipientByIdQuery)
    .careRecipientById;
  const { data: queryData } = useCareRecipientByIdQuery({
    skip: !loaderData,
    variables: { id: loaderData?.id ?? "" },
    fetchPolicy: "cache-only",
  });
  const careRecipient = queryData?.careRecipientById ?? loaderData;

  const revalidator = useRevalidator();

  const { t } = useTranslate();
  const navigate = useNavigate();

  const [visitId, setVisitId] = useState<string | null>(null);
  const [addActivityModal, setAddActivityModal] = useState<PartialBy<
    Activity,
    "id"
  > | null>(null);

  const [careRecipientProfileFormModal, setCareRecipientProfileFormModal] =
    useState<Recipient | null>(null);

  const [showAddContact, setShowAddContact] = useState<boolean>(false);
  const [showAddCareEntry, setShowAddCareEntry] = useState<boolean>(false);
  const { viewer } = useCareContext().state;

  const { data: contactsData, loading: loadingContacts } =
    useContactsByCareRecipientIdQuery({
      skip: !careRecipient,
      variables: { careRecipientId: careRecipient?.id ?? "" },
    });

  const primaryContact = useMemo(() => {
    return (
      contactsData?.contactsByCareRecipientId.find((c) => c.isPrimary) || null
    );
  }, [contactsData?.contactsByCareRecipientId]);

  const { data: activitiesData, loading: loadingActivities } =
    useActivitiesByCareRecipientIdQuery({
      skip: !careRecipient,
      variables: { careRecipientId: careRecipient?.id ?? "" },
    });

  const {
    data: feedData,
    error: feedError,
    loading: feedLoading,
    refetch: refetchFeedItems,
  } = useFeedItemsByCareRecipientIdQuery({
    fetchPolicy: "cache-and-network",
    skip: !careRecipient,
    variables: {
      from: DateTime.local().endOf("day").toISO() ?? "",
      careRecipientId: careRecipient?.id ?? "",
      limit: 60,
    },
  });

  const handleShowCareEntryModal = () =>
    setShowAddCareEntry(() => !showAddCareEntry);

  const [careLogEntry, setCareLogEntry] = useState<string>();

  const [insertCarelogEntry, { loading }] =
    useCarelogForCareRecipientInsertMutation({
      onCompleted: () => {
        revalidator.revalidate();
        refetchFeedItems();
      },
    });

  const handleCreateContact = () => setShowAddContact(() => true);
  const handleShowAllContacts = () => {
    if (contactsData?.contactsByCareRecipientId?.length) {
      navigate("contacts");
    } else {
      setShowAddContact(true);
    }
  };

  const handleActivityCardClick = (id: string, type: string) => {
    type === "VISIT" ? setVisitId(id) : navigate(`/care-recipients/${id}`);
  };

  const handleClickAddActivity = (careRecipientId: string) => {
    setAddActivityModal({
      careRecipientId: careRecipientId,
      title: "",
      description: "",
      timeOfDayStart: null,
      weekdays: null,
      priority: Priority.Optional,
    });
  };

  // TODO change this when an office dropdown is added
  const getFirstActiveOffice = () => {
    return careRecipient?.careRecipientRoles.find(
      (role) => !role.deactivatedAt,
    );
  };

  const recipientRole = getFirstActiveOffice();
  const careNeeds = useMemo(
    () =>
      careRecipient?.carePlan.fields.find(
        ({ id }) => id === "carePlan.careNeeds",
      ) as RadioField | undefined,
    [careRecipient],
  );

  const getAddressWithMaps = () => {
    const address = [
      careRecipient?.address?.addressLine1,
      careRecipient?.address?.city,
      careRecipient?.address?.zipCode,
    ]
      .filter((x) => !!x)
      .join(", ");

    const mapsAddress = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address ?? "")}`;
    return mapsAddress;
  };

  if (!careRecipient) {
    return (
      <div className="flex flex-col w-full items-center gap-4 py-14">
        <Empty.E />
        {<Headline size="m">{t("careRecipientDetails.notFound")}</Headline>}
      </div>
    );
  }

  if (careRecipient.deactivatedAt) {
    return (
      <DeactivatedCareRecipient
        id={careRecipient.id}
        officeId={careRecipient.careRecipientRoles[0].office.id} // TODO add a way to select which role is being reactivated
        name={`${careRecipient.firstName} ${careRecipient.lastName}`}
        deactivatedAt={careRecipient.deactivatedAt}
      />
    );
  }

  return (
    <ThreeColumnLayout>
      <CardColumn>
        {/* Start left column area */}
        {/* Care recipient */}

        <Card>
          {careNeeds &&
            match(careNeeds.value)
              .with("carePlan.careNeeds.level1", () => (
                <Badge
                  className="mb-2"
                  variant="danger"
                  text={t("careRecipientDetails.level1")}
                />
              ))
              .with("carePlan.careNeeds.level2", () => (
                <Badge
                  className="mb-2"
                  variant="warning"
                  text={t("careRecipientDetails.level2")}
                />
              ))
              .with("carePlan.careNeeds.level3", () => (
                <Badge
                  className="mb-2"
                  variant="primary"
                  text={t("careRecipientDetails.level3")}
                />
              ))
              .with("carePlan.careNeeds.level4", () => (
                <Badge
                  className="mb-2"
                  variant="success"
                  text={t("careRecipientDetails.level4")}
                />
              ))
              .otherwise(() => null)}
          <h3 className="mb-6 text-xl font-bold">{`${careRecipient.firstName} ${careRecipient.lastName}`}</h3>
          {careRecipient.address && careRecipient.address.addressLine1 ? (
            <Link
              className="mt-4 flex items-center gap-2"
              to={getAddressWithMaps()}
              target="blank"
              rel="noopener noreferrer"
            >
              <Location className="text-primary-800" />
              <span className="text-sm font-medium">
                {[
                  careRecipient.address?.addressLine1,
                  careRecipient.address?.city,
                  careRecipient.address.zipCode,
                ]
                  .filter((x) => !!x)
                  .join(", ")}
              </span>
            </Link>
          ) : null}
          {careRecipient.phone && (
            <div className="mt-4 flex items-center gap-2">
              <Phone className="text-primary-800" />
              <Link
                className="text-sm font-medium"
                to={`tel:${careRecipient.phone}`}
                target="blank"
                rel="noopener noreferrer"
              >
                {careRecipient.phone}
              </Link>
            </div>
          )}
          {careRecipient.homePhone && (
            <div className="mt-4 flex items-center gap-2">
              <HomePhone className="text-primary-800" />
              <Link
                className="text-sm font-medium"
                to={`tel:${careRecipient.homePhone}`}
                target="blank"
                rel="noopener noreferrer"
              >
                {careRecipient.homePhone}
              </Link>
            </div>
          )}
          {recipientRole?.ssn && (
            <div className="mt-4 flex items-center gap-2">
              <IdentityCard className="text-primary-800" />
              <span className="text-sm font-medium">{recipientRole.ssn}</span>
            </div>
          )}
          {careRecipient.birthDate && (
            <div className="mt-4 flex items-center gap-2">
              <User className="text-primary-800" />
              <span className="text-sm font-medium">
                {t("careRecipientDetails.yearsOld", {
                  age: calculateAge(careRecipient.birthDate).toString(),
                })}
              </span>
            </div>
          )}
          <div className="mt-6">
            <Button
              className="w-full"
              variant="secondary"
              onClick={() => {
                const dateOfBirth = recipientRole?.birthDate
                  ? DateTime.fromFormat(recipientRole.birthDate, "yyyy-MM-dd")
                  : undefined;
                setCareRecipientProfileFormModal({
                  id: careRecipient.id,
                  firstName: careRecipient.firstName || "",
                  lastName: careRecipient.lastName || "",
                  email: careRecipient.email || "",
                  phone: careRecipient.phone || "",
                  homePhone: careRecipient.homePhone || null,
                  officeId: recipientRole?.office.id ?? "",
                  deactivatedAt: careRecipient.deactivatedAt,
                  ssn: recipientRole?.ssn ?? null,
                  addressLine1: careRecipient.address?.addressLine1 || "",
                  city: careRecipient.address?.city || "",
                  state: careRecipient.address?.state || "",
                  zipCode: careRecipient.address?.zipCode || "",
                  homeInformation: careRecipient.address?.homeInformation || "",
                  dateOfBirth: dateOfBirth,
                  gender: careRecipient.gender,
                  carePlan: careRecipient.carePlan.fields.reduce<
                    Recipient["carePlan"]
                  >(
                    (accumulated, currentField) =>
                      match<Field, Recipient["carePlan"]>(currentField)
                        .with({ __typename: "CheckboxField" }, (field) => [
                          ...accumulated,
                          {
                            ...field,
                            type: InputFieldType.CheckboxField,
                          },
                        ])
                        .with({ __typename: "RadioField" }, (field) => [
                          ...accumulated,
                          {
                            ...field,
                            type: InputFieldType.RadioField,
                          },
                        ])
                        .with({ __typename: "TextArea" }, (field) => [
                          ...accumulated,
                          {
                            ...field,
                            type: InputFieldType.TextArea,
                          },
                        ])
                        .with({ __typename: "TextField" }, (field) => [
                          ...accumulated,
                          {
                            ...field,
                            type: InputFieldType.TextField,
                          },
                        ])
                        .with({ __typename: "DateField" }, (field) => [
                          ...accumulated,
                          {
                            ...field,
                            value: field.value
                              ? DateTime.fromISO(field.value)
                              : undefined,
                            type: InputFieldType.DateField,
                          },
                        ])
                        // Whenever a future field type is added
                        // it should be explicitly added through code.
                        // Therefor this filters them out.
                        .otherwise(() => accumulated),
                    [],
                  ),
                });
              }}
              text={t("editCarePlan").toString()}
            />
          </div>
        </Card>

        <ContactCard
          contacts={contactsData?.contactsByCareRecipientId ?? []}
          primary={primaryContact}
          loading={loadingContacts}
          onCreateContact={handleCreateContact}
          onShowAllContacts={handleShowAllContacts}
        />
        <FileCard id={careRecipient.id} files={careRecipient.files} />

        {/* End left column area */}
      </CardColumn>

      <div>
        {/* Start main area*/}
        <div className="mb-4">
          <CareRecipientSchedule careRecipient={careRecipient} />
        </div>
        <div className="bg-white p-6 shadow sm:rounded-2xl">
          <ActivitiesTable
            activities={activitiesData?.activitiesByCareRecipientId ?? []}
            loading={loadingActivities}
            headerText={t("careRecipientDetails.activities")}
            headerButton={{
              text: `+ ${t("careRecipientDetails.addActivity")}`,
              onClick: () => handleClickAddActivity(careRecipient.id),
            }}
            activityButtonType="EDIT"
            onEdit={(activity) => {
              setAddActivityModal(activity);
            }}
          />
        </div>
      </div>
      {/* End main area */}

      {addActivityModal && (
        <ActivityDetailsModal
          activity={addActivityModal}
          onClose={() => {
            setAddActivityModal(null);
          }}
        />
      )}

      {careRecipientProfileFormModal && (
        <CareRecipientProfileFormModal
          recipient={careRecipientProfileFormModal}
          onClose={() => setCareRecipientProfileFormModal(null)}
        />
      )}

      {showAddContact && (
        <SlideOver
          title={t("contacts.add") ?? ""}
          onClose={() => setShowAddContact(false)}
        >
          <AddContact
            onClose={() => setShowAddContact(false)}
            careRecipientId={careRecipient.id}
            primaryContactId={primaryContact?.id ?? null}
          />
        </SlideOver>
      )}
      <div className="min-h-[200px] min-w-[400px] pl-4 xl:max-w-[320px]">
        <div className="mb-6 flex justify-between">
          <h2 className="text-lg font-semibold ">
            {t("careRecipientDetails.carelog.carelog")}
          </h2>
          <Button
            text={t("careRecipientDetails.carelog.addNote") as string}
            onClick={handleShowCareEntryModal}
          />
        </div>
        <div className="grid gap-6">
          <ActivitiesFeed
            dateSettings={viewer?.tenantSettings}
            data={feedData}
            error={feedError}
            loading={feedLoading}
            cardClick={handleActivityCardClick}
          />
        </div>
        {visitId && (
          <VisitDetailsContainer
            visitId={visitId}
            onClose={() => setVisitId(null)}
          />
        )}
      </div>

      {showAddCareEntry && (
        <SlideOver
          title={t("careRecipientDetails.carelog.addNote") as string}
          onClose={handleShowCareEntryModal}
        >
          <div className="grid gap-4">
            <p>{t("careRecipientDetails.carelog.warning")}</p>
            <textarea
              rows={6}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder={
                t("careRecipientDetails.carelog.inputPlaceholder") as string
              }
              defaultValue={careLogEntry}
              onChange={(e) => setCareLogEntry(e.target.value)}
            />
            <div className="flex justify-end gap-2">
              <Button
                text={t("cancel") as string}
                onClick={handleShowCareEntryModal}
                variant="secondary"
              />
              <Button
                text={t("submit") as string}
                onClick={() =>
                  insertCarelogEntry({
                    variables: {
                      id: careRecipient.id,
                      input: { log: careLogEntry || "" },
                    },
                  }).then(() => {
                    setCareLogEntry("");
                    setShowAddCareEntry(false);
                  })
                }
                disabled={!careLogEntry}
                loading={loading}
              />
            </div>
          </div>
        </SlideOver>
      )}
    </ThreeColumnLayout>
  );
};
