import {
  useCareRecipientsLazyQuery,
  useUserCountsQuery,
  useFeedItemsQuery,
} from "../../api/generated/graphql";
import { Stats } from "../../components/common";
import { UserIcon, UsersIcon } from "@heroicons/react/24/outline";
import { useCareContext } from "../../providers/index";
import { getGreetingTime } from "@frontend/lyng/utils/dateUtils";
import { useEffect, useState } from "react";
import { useTranslate } from "@tolgee/react";
import { ActivitiesFeed } from "@frontend/lyng/activityFeed/ActivitiesFeed";
import { useNotificationContext } from "@frontend/lyng/notifications";
import { DateTime } from "luxon";
import { VisitDetailsContainer } from "../visitDetails/VisitDetailsContainer";
import { useNavigate } from "react-router-dom";

export const Home = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const [visitId, setVisitId] = useState<string | null>(null);
  const {
    state: { viewer, viewerError },
  } = useCareContext();
  const { registerForWebPushNotifications } = useNotificationContext();

  useEffect(() => {
    if (viewer) {
      registerForWebPushNotifications(viewer.vapidKey, (v) => {
        console.log("registered", v);
      });
    }
  }, [viewer, registerForWebPushNotifications]);

  const officeIds = viewer?.offices.map((office) => office.id) ?? [];

  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useUserCountsQuery({
    variables: {
      officeIds: officeIds,
    },
  });

  const [singleCareRecipientId, setSingleCareRecipientId] = useState<
    string | undefined
  >(undefined);
  const [careRecipientsQuery, { loading: careRecipientsLoading }] =
    useCareRecipientsLazyQuery({
      variables: {
        filter: {
          officeIds: officeIds,
        },
      },
    });

  useEffect(() => {
    if (userData?.careRecipientsCount === 1) {
      careRecipientsQuery().then((result) => {
        if (result.data?.careRecipients?.length) {
          setSingleCareRecipientId(result.data?.careRecipients[0].id);
        }
      });
    }
  }, [userData, careRecipientsQuery]);

  const getGreeting = () =>
    t("homePage.greeting", {
      timeOfDay: getGreetingTime(t),
      name: `${viewer?.firstName ?? ""} ${viewer?.lastName ?? ""}`,
    });

  const {
    data: feedData,
    error: feedError,
    loading: feedLoading,
  } = useFeedItemsQuery({
    fetchPolicy: "network-only",
    variables: {
      from: DateTime.local().minus({ month: 1 }).startOf("day").toISO() ?? "",
      to: DateTime.local().endOf("day").toISO() ?? "",
    },
  });

  if (userError) {
    return <p>Error : {userError.message}</p>;
  }

  const handleCardClick = (id: string, type: string) => {
    type === "VISIT" ? setVisitId(id) : navigate(`/care-recipients/${id}`);
  };

  return (
    <div className="flex flex-row">
      <div className="flex flex-wrap flex-1">
        <div className="flex flex-col flex-1">
          {viewerError ? (
            <p>Error : {viewerError.message}</p>
          ) : (
            <h1 className="mb-5 text-3xl font-semibold">{getGreeting()}</h1>
          )}
          <Stats>
            <Stats.Card
              headerText={t("homePage.careRecipients")}
              stat={userData?.careRecipientsCount.toString() ?? "0"}
              link={{
                href: singleCareRecipientId
                  ? `/care-recipients/${singleCareRecipientId}`
                  : "/schedule/care-recipients",
                text: t("homePage.viewSchedule"),
              }}
              icon={UserIcon}
              loading={userLoading || careRecipientsLoading}
            />
            <Stats.Card
              headerText={t("homePage.caregivers")}
              stat={userData?.careGiversCount.toString() ?? "0"}
              link={{
                href: "/schedule/caregivers",
                text: t("homePage.viewSchedule"),
              }}
              icon={UsersIcon}
              loading={userLoading}
            />
          </Stats>
        </div>
      </div>

      <ActivitiesFeed
        dateSettings={viewer?.tenantSettings}
        data={feedData}
        error={feedError}
        loading={feedLoading}
        cardClick={handleCardClick}
      />
      {visitId && (
        <VisitDetailsContainer
          visitId={visitId}
          onClose={() => setVisitId(null)}
        />
      )}
    </div>
  );
};
