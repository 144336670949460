import { Paragraph } from "@frontend/lyng/typography";
import classNames from "classnames";
import { AriaAttributes, forwardRef, useEffect, useRef } from "react";
import {
  FieldValues,
  UseControllerProps,
  UseFormRegisterReturn,
  useController,
} from "react-hook-form";

// Updates the height of a <textarea> when the value changes.
const useAutosizeTextArea = (
  textAreaRef: HTMLTextAreaElement | null,
  value: string,
  editView: boolean
) => {
  useEffect(() => {
    if (textAreaRef) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.style.height = "0px";
      const scrollHeight = textAreaRef.scrollHeight;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      textAreaRef.style.height = scrollHeight + "px";
    }
  }, [textAreaRef, value, editView]);
};

type TextAreaWideProps<FormValues extends FieldValues> =
  UseControllerProps<FormValues> &
    AriaAttributes & {
      id?: string;
      placeholder?: string;
      editView?: boolean;
    };

export function TextAreaWide<FormValues extends FieldValues>({
  id,
  name,
  control,
  placeholder,
  editView = true,
  ...ariaProps
}: TextAreaWideProps<FormValues>) {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const {
    field,
    fieldState: { invalid, isDirty },
  } = useController({
    name,
    control,
  });
  const { ref, value, ...rest } = field;

  useAutosizeTextArea(textAreaRef.current, value, editView);

  return (
    <>
      {!editView && (
        <Paragraph
          id={id}
          size="m"
          className="whitespace-pre-wrap px-4 py-3"
          role="textbox"
          {...ariaProps}
          aria-readonly="true"
        >
          {value}
        </Paragraph>
      )}
      <textarea
        {...rest}
        ref={(e) => {
          ref(e);
          textAreaRef.current = e;
        }}
        id={id}
        value={value}
        placeholder={placeholder}
        disabled={!editView}
        rows={1}
        className={classNames(
          "w-full rounded-xl px-4 py-3 text-lg transition-colors",
          {
            "border-gray-300 placeholder:text-gray-400 focus:border-primary-500 focus:ring-primary-500":
              editView && !invalid,
            "border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500":
              editView && invalid,
            hidden: !editView,
            block: editView,
            // This is the minimum height of the textarea when it's empty on initial render
            "min-h-[132px]": editView && !value?.length && !isDirty,
          }
        )}
        {...ariaProps}
        aria-hidden={!editView}
      />
    </>
  );
}
type Props = {
  id?: string;
  value?: string | undefined;
  placeholder?: string;
  invalid?: boolean;
  description?: string;
  className?: string;
};

export const TextArea = forwardRef<
  HTMLTextAreaElement,
  Props & UseFormRegisterReturn<string>
>(function TextArea(
  {
    id,
    value,
    name,
    invalid,
    onBlur,
    onChange,
    placeholder,
    description,
    className,
  },
  ref
) {
  return (
    <div className={className ? className : "max-w-lg sm:max-w-xs"}>
      <textarea
        className={classNames(
          invalid
            ? "border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500"
            : "border-gray-300 focus:border-primary-500 focus:ring-primary-500",
          "mx-1 block w-full rounded-xl shadow-sm sm:text-sm"
        )}
        ref={ref}
        rows={4}
        name={name}
        id={id}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        placeholder={placeholder}
      />
      {description && (
        <p className="mt-3 text-sm text-gray-700">{description}</p>
      )}
    </div>
  );
});
