import { ResourceType, RoleType } from "../../../api/generated/graphql";
import { Disclosure } from "@headlessui/react";
import { ChevronOutline } from "@frontend/lyng/assets/svg/Chevron";
import { HomeIcon } from "../../../assets/svg/HomeIcon";
import { CalendarIcon } from "../../../assets/svg/CalendarIcon";
import { UserIcon } from "../../../assets/svg/UserIcon";
import { CaregiversIcon } from "../../../assets/svg/CaregiversIcon";
import { ReportsIcon } from "../../../assets/svg/ReportsIcon";
import { SettingsIcon } from "../../../assets/svg/SettingsIcon";
import { useCareContext } from "../../../providers/CareProvider";
import classNames from "classnames";
import { useTranslate } from "@tolgee/react";
import { NavLink, matchPath, useLocation } from "react-router-dom";
import { useState } from "react";
import { useFeatureFlag } from "../../../providers/FeatureFlags";

type MenuItem = {
  name: string;
  href: string;
  icon: React.ElementType;
  children?: {
    name: string;
    href: string;
  }[];
};

type Props = {
  onClick?: () => void;
};
const ACCESS_LOGS_TENANT_IDS =
  import.meta.env.VITE_ACCESS_LOGS_TENANT_IDS ?? "";
export const SidebarItems = ({ onClick }: Props) => {
  const { t } = useTranslate();
  const location = useLocation();
  const {
    state: { viewer },
  } = useCareContext();

  const [showSettingsMenu, setShowSettingsMenu] = useState(false);
  const ffRoleManagement = useFeatureFlag("RoleManagement");

  const isAdmin = viewer?.roles.some(
    ({ deactivateAt, roleType }) =>
      !deactivateAt && roleType === RoleType.Admin,
  );
  const isTenantAdmin = viewer?.roles.some(
    ({ deactivateAt, roleType, resourceType }) =>
      !deactivateAt &&
      resourceType === ResourceType.Tenant &&
      roleType === RoleType.Admin,
  );

  const menuItems: MenuItem[] = [
    { name: t("sidebarItems.home"), href: "/", icon: HomeIcon },
    {
      name: t("sidebarItems.schedule"),
      href: "/schedule/care-recipients",
      icon: CalendarIcon,
    },
    {
      name: t("sidebarItems.careRecipients"),
      href: "/care-recipients",
      icon: UserIcon,
    },
    {
      name: t("sidebarItems.caregivers"),
      href: "/caregivers",
      icon: CaregiversIcon,
    },
    {
      name: t("sidebarItems.reports"),
      href: "/reports",
      icon: ReportsIcon,
      children: [
        {
          name: t("sidebarItems.caregiverWorkload"),
          href: "/reports/workload",
        },
        {
          name: t("sidebarItems.visitLogs"),
          href: "/reports/visit-logs",
        },
        ...(isTenantAdmin && viewer?.tenantSettings.enableVisitBilling
          ? [
              {
                name: t("sidebarItems.billing"),
                href: "/reports/billing",
              },
            ]
          : []),
      ],
    },
  ];

  if (isAdmin) {
    menuItems.push({
      name: t("sidebarItems.settings"),
      href: "/settings",
      icon: SettingsIcon,
    });
  }

  const settingsMenu = [
    {
      name: t("sidebarItems.workspace"),
      href: "/settings/workspace",
    },
    {
      name: t("sidebarItems.organization"),
      href: "/settings/organization",
    },
    {
      name: t("sidebarItems.members"),
      href: "/settings/members",
    },
    ...(ffRoleManagement
      ? [
          {
            name: t("sidebarItems.users"),
            href: "/settings/users",
          },
        ]
      : [
          {
            name: t("sidebarItems.userList"),
            href: "/settings/userlist",
          },
        ]),

    {
      name: t("sidebarItems.labels"),
      href: "/settings/labels",
    },
    {
      name: t("sidebarItems.billing"),
      href: "/settings/billing",
    },
    ...(ACCESS_LOGS_TENANT_IDS.split(",").includes(viewer?.tenantAccess.id)
      ? [
          {
            name: t("sidebarItems.accessLogs"),
            href: "/settings/access-logs",
          },
        ]
      : []),
  ];

  return (
    <div>
      {!showSettingsMenu ? (
        menuItems.map((item) => {
          const isActive = matchPath(
            { path: item.href, end: true },
            location.pathname,
          );

          return !item.children ? (
            item.name !== t("sidebarItems.settings") ? (
              <NavLink
                key={item.name}
                to={item.href}
                className={() =>
                  classNames(
                    "group flex items-center rounded-md px-1 py-4 text-base font-medium text-greyscale-900 hover:bg-primary-100  md:text-sm",
                  )
                }
                onClick={onClick}
              >
                <item.icon
                  className={classNames(
                    "mr-2 flex-shrink-0",
                    "text-greyscale-500",
                    isActive &&
                      "fill-primary-500 stroke-primary-500 text-primary-500",
                  )}
                  aria-hidden="true"
                  filled={!!isActive}
                />
                {item.name}
              </NavLink>
            ) : isAdmin && item.name === t("sidebarItems.settings") ? (
              <div
                key={item.name}
                className={classNames(
                  "group flex cursor-pointer items-center rounded-md px-1 py-4 text-base font-medium text-greyscale-900 hover:bg-primary-100 md:text-sm",
                )}
                onClick={() => setShowSettingsMenu(true)}
              >
                <item.icon
                  className={classNames(
                    "mr-2 flex-shrink-0",
                    "text-greyscale-500",
                    isActive &&
                      "fill-primary-500 stroke-primary-500 text-primary-500",
                  )}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    "group flex items-center rounded-md p-0 text-base font-medium text-greyscale-900 hover:bg-primary-100 md:text-sm",
                  )}
                >
                  {item.name}
                </span>
              </div>
            ) : null
          ) : (
            <Disclosure
              as="div"
              key={item.name}
              className="space-y-1"
              defaultOpen={!!isActive}
            >
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={classNames(
                      "text-greyscale-900 hover:bg-primary-100 ",
                      "group flex w-full items-center rounded-md px-1 py-4 text-left text-sm font-medium",
                    )}
                  >
                    <item.icon
                      className={classNames(
                        "mr-2 flex-shrink-0",
                        "text-greyscale-500",
                      )}
                      aria-hidden="true"
                    />
                    <span className="flex-1">{item.name}</span>
                    <ChevronOutline
                      className={classNames(
                        open
                          ? "text-greyscale-900"
                          : "-rotate-90 text-greyscale-900",
                        "ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out",
                      )}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="space-y-1">
                    {item.children?.map((subItem) => (
                      <NavLink
                        key={subItem.name}
                        to={subItem.href}
                        className={({ isActive }) =>
                          classNames(
                            isActive
                              ? "bg-primary-100 text-greyscale-900"
                              : "text-greyscale-900 hover:bg-primary-100",
                            "group flex w-full items-center rounded-md py-2 pl-11 pr-2 text-sm font-medium",
                          )
                        }
                        onClick={onClick}
                      >
                        {subItem.name}
                      </NavLink>
                    ))}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          );
        })
      ) : (
        <>
          <div
            className={classNames(
              "group flex cursor-pointer items-center rounded-md px-1 py-4 text-base font-medium  hover:bg-primary-100 md:text-sm",
            )}
            onClick={() => setShowSettingsMenu(false)}
          >
            <ChevronOutline
              className={classNames(
                "mr-2 flex-shrink-0 rotate-90 text-greyscale-500",
              )}
            />
            <span
              className={classNames(
                "group flex items-center rounded-md p-0 text-base font-medium text-greyscale-500 hover:bg-primary-100 md:text-sm",
              )}
            >
              {t("tenantSwitcher.workspaceSettings") as string}
            </span>
          </div>

          {settingsMenu.map((item) => (
            <NavLink
              key={item.name}
              to={item.href}
              className={() =>
                classNames(
                  "group flex items-center rounded-md px-4 py-4 text-base font-medium text-greyscale-900 transition duration-300 ease-in-out hover:bg-primary-100  md:text-sm",
                )
              }
              onClick={onClick}
            >
              {item.name}
            </NavLink>
          ))}
        </>
      )}
    </div>
  );
};
