import {
  CareGiverByIdQuery,
  CareRecipientByIdQuery,
  ResourceType,
  RoleType,
  ViewerQuery,
} from "../api/generated/graphql";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { Layout, NotFound } from "../components/core";
import { Error } from "@frontend/lyng/common/Error";
import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";

import AbsenceModal from "../pages/absenceModal/absenceModal";
import {
  CareRecipientDetails,
  careRecipientDetailsLoader,
} from "../pages/careRecipientDetails/CareRecipientDetails";
import { CareRecipientList } from "../pages/careRecipientList/CareRecipientList";
import {
  CaregiverDetails,
  caregiverDetailsLoader,
} from "../pages/caregiverDetails/CaregiverDetails";
import { CaregiverList } from "../pages/caregiverList/CaregiverList";
import ContactModal from "../pages/contactModal/contactModal";
import { Home } from "../pages/home/Home";
import CaregiverWorkloadReport from "../pages/reports/caregiverWorkload/caregiverWorkload";
import VisitLogsReport from "../pages/reports/visitLogs/visitLogs";
import { SchedulePage } from "../pages/schedulePage/SchedulePage";
import Members from "../pages/settings/members/Members";
import Organization from "../pages/settings/organization/Organization";
import {
  WorkspaceSettings,
  tenantSettingsLoader,
} from "../pages/settings/workspaceSettings/WorkspaceSettings";
import { NewVisit } from "../pages/visitDetails/NewVisit";
import { VisitDetailsContainer } from "../pages/visitDetails/VisitDetailsContainer";
import * as Sentry from "@sentry/react";
import { VisitTypes } from "../pages/settings/workspaceSettings/billing/visitTypes";
import { MemberModal } from "../pages/settings/members/MemberModal";
import { NewMemberModal } from "../pages/settings/members/NewMemberModal";
import { NewVisitTypeModal } from "../pages/settings/workspaceSettings/billing/newVisitTypeModal";
import { VisitTypeModal } from "../pages/settings/workspaceSettings/billing/visitTypeModal";
import Users from "../pages/settings/users/Users";
import { AccessLogs } from "../pages/settings/accessLogs/AccessLogs";
import { VisitTypeReport } from "../pages/reports/visitTypeReport/VisitTypeReport";
import { Labels } from "../pages/settings/workspaceSettings/labels/labels";
import { NewLabelModal } from "../pages/settings/workspaceSettings/labels/newLabelModal";
import { LabelModal } from "../pages/settings/workspaceSettings/labels/labelModal";
import { UserModal } from "../pages/settings/users/UserModal";
import OldUsers from "../pages/settings/members/OldUsers";

export const router = (
  viewer: ViewerQuery["viewer"],
  client: ApolloClient<NormalizedCacheObject>,
) => {
  const sentryCreateBrowserRouter: typeof createBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);

  const isTenantAdmin = viewer?.roles.some(
    ({ deactivateAt, roleType, resourceType }) =>
      !deactivateAt &&
      resourceType === ResourceType.Tenant &&
      roleType === RoleType.Admin,
  );

  return sentryCreateBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      errorElement: (
        <div className="h-screen w-screen">
          <Error />
        </div>
      ),
      children: [
        {
          // Try to render error page inside layout
          errorElement: <Error />,
          children: [
            {
              index: true,
              path: "/",
              element: <Home />,
            },
            {
              path: "schedule/:tab?",
              element: (
                <>
                  <SchedulePage />
                  <Outlet /> {/* Allow routed modals */}
                </>
              ),
              handle: {
                crumb: () => ({
                  name: "router.schedule",
                  href: "/schedule/care-recipients",
                }),
              },
              children: [
                {
                  path: "visits/:visitInstanceId",
                  element: <VisitDetailsContainer />,
                },
                {
                  path: "absence/:id",
                  element: <AbsenceModal />,
                },
              ],
            },
            {
              path: "care-recipients",
              element: <Outlet />,
              handle: {
                crumb: () => ({
                  name: "router.careRecipients",
                  href: "/care-recipients",
                }),
              },
              children: [
                {
                  index: true,
                  id: "care-recipient-list",
                  path: "",
                  element: (
                    <>
                      <CareRecipientList />
                      <Outlet /> {/* Allow routed modals */}
                    </>
                  ),
                },
                {
                  id: "care-recipient-details",
                  path: ":careRecipientId",
                  element: (
                    <>
                      <CareRecipientDetails />
                      <Outlet /> {/* Allow routed modals */}
                    </>
                  ),
                  loader: ({ params }) =>
                    careRecipientDetailsLoader(client, params.careRecipientId),
                  handle: {
                    crumb: (data: CareRecipientByIdQuery | undefined) =>
                      data?.careRecipientById
                        ? {
                            name: `${data.careRecipientById.firstName} ${data.careRecipientById.lastName}`,
                            href: `/care-recipients/${data.careRecipientById.id}`,
                          }
                        : null,
                  },
                  children: [
                    {
                      path: "visits/:visitInstanceId",
                      element: <VisitDetailsContainer />,
                    },
                    {
                      path: "visits/new/:timeSlug",
                      element: <NewVisit />,
                    },
                    {
                      path: "absence/:id?",
                      element: <AbsenceModal />,
                    },
                    {
                      path: "contacts/:contactId?",
                      element: <ContactModal />,
                    },
                  ],
                },
              ],
            },
            {
              path: "caregivers",
              element: <Outlet />,
              handle: {
                crumb: () => ({
                  name: "router.caregivers",
                  href: "/caregivers",
                }),
              },
              children: [
                {
                  index: true,
                  id: "caregiver-list",
                  path: "",
                  element: (
                    <>
                      <CaregiverList />
                      <Outlet /> {/* Allow routed modals */}
                    </>
                  ),
                },
                {
                  id: "caregiver-details",
                  path: ":caregiverId",
                  element: (
                    <>
                      <CaregiverDetails />
                      <Outlet /> {/* Allow routed modals */}
                    </>
                  ),
                  loader: ({ params }) =>
                    caregiverDetailsLoader(client, params.caregiverId),
                  handle: {
                    crumb: (data: CareGiverByIdQuery | undefined) =>
                      data
                        ? {
                            name: `${data.careGiverById.firstName} ${data.careGiverById.lastName}`,
                            href: `/caregivers/${data.careGiverById.id}`,
                          }
                        : null,
                  },
                  children: [
                    {
                      path: "visits/:visitInstanceId",
                      element: <VisitDetailsContainer />,
                    },
                    {
                      path: "absence/:id?",
                      element: <AbsenceModal />,
                    },
                  ],
                },
              ],
            },
            {
              path: "settings",
              element: <Outlet />,
              children: [
                {
                  index: true,
                  element: <Navigate to="/settings/workspace" replace />,
                },
                {
                  path: "workspace",
                  element: <WorkspaceSettings />,
                  loader: () => tenantSettingsLoader(client),
                  handle: {
                    crumb: () => ({
                      name: "router.workspaceSettings",
                      href: "/settings/workspace",
                    }),
                  },
                },
                {
                  path: "organization",
                  element: <Organization />,
                  handle: {
                    crumb: () => ({
                      name: "router.organization",
                      href: "/settings/organization",
                    }),
                  },
                },
                {
                  path: "members",
                  element: (
                    <>
                      <Members />
                      <Outlet /> {/* Allow routed modals */}
                    </>
                  ),
                  handle: {
                    crumb: () => ({
                      name: "router.members",
                      href: "/settings/members",
                    }),
                  },
                  children: [
                    {
                      path: "new",
                      element: <NewMemberModal />,
                    },
                    {
                      path: ":memberId",
                      element: <MemberModal />,
                    },
                  ],
                },
                {
                  path: "userlist",
                  element: <OldUsers />,
                  handle: {
                    crumb: () => ({
                      name: "router.userList",
                      href: "/settings/userlist",
                    }),
                  },
                },
                {
                  path: "users",
                  element: (
                    <>
                      <Users />
                      <Outlet /> {/* Allow routed modals */}
                    </>
                  ),
                  handle: {
                    crumb: () => ({
                      name: "router.users",
                      href: "/settings/users",
                    }),
                  },
                  children: [
                    {
                      path: ":userId",
                      element: <UserModal />,
                    },
                  ],
                },
                {
                  path: "labels",
                  element: (
                    <>
                      <Labels />
                      <Outlet /> {/* Allow routed modals */}
                    </>
                  ),
                  handle: {
                    crumb: () => ({
                      name: "router.labels",
                      href: "/settings/labels",
                    }),
                  },
                  children: [
                    {
                      path: "labels/new",
                      element: <NewLabelModal />,
                    },
                    {
                      path: "label/:labelId",
                      element: <LabelModal />,
                    },
                  ],
                },
                {
                  path: "billing",
                  element: (
                    <>
                      <VisitTypes />
                      <Outlet /> {/* Allow routed modals */}
                    </>
                  ),
                  handle: {
                    crumb: () => ({
                      name: "router.billing",
                      href: "/settings/billing",
                    }),
                  },
                  children: [
                    {
                      path: "visit-types/new",
                      element: <NewVisitTypeModal />,
                    },
                    {
                      path: "visit-types/:visitTypeId",
                      element: <VisitTypeModal />,
                    },
                  ],
                },
                {
                  path: "access-logs",
                  element: <AccessLogs />,
                  handle: {
                    crumb: () => ({
                      name: "router.accessLogs",
                      href: "/settings/access-logs",
                    }),
                  },
                },
              ],
            },
            {
              path: "reports",
              handle: {
                crumr: () => ({
                  name: "router.reports",
                  href: "/reports",
                }),
              },
              element: <Outlet />,
              children: [
                {
                  index: true,
                  element: <Navigate to="/reports/workload" />,
                },
                {
                  path: "workload",
                  element: <CaregiverWorkloadReport />,
                  handle: {
                    crumb: () => ({
                      name: "router.caregiverWorkload",
                      href: "/reports/workload",
                    }),
                  },
                },
                {
                  index: true,
                  element: <Navigate to="/reports/visit-logs" />,
                },
                {
                  path: "visit-logs",
                  element: (
                    <>
                      <VisitLogsReport />
                      <Outlet />
                    </>
                  ),
                  handle: {
                    crumb: () => ({
                      name: "router.visitLogs",
                      href: "/reports/visit-logs",
                    }),
                  },
                  children: [
                    {
                      path: "visits/:visitInstanceId",
                      element: <VisitDetailsContainer />,
                    },
                  ],
                },
                ...(isTenantAdmin
                  ? [
                      {
                        path: "billing",
                        element: <VisitTypeReport />,
                        handle: {
                          crumb: () => ({
                            name: "router.billing",
                            href: "/reports/billing",
                          }),
                        },
                      },
                    ]
                  : []),
              ],
            },
            {
              path: "reset-password",
              element: <Navigate to="/" />,
            },
            { path: "*", element: <NotFound /> },
          ],
        },
      ],
    },
  ]);
};
