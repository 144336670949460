import { useCallback, useEffect } from "react";
import {
  RouterProvider,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";
import { Toaster } from "react-hot-toast";

import { RoleType, ViewerQuery, useViewerQuery } from "./api/generated/graphql";
import { UserActions } from "./providers/CareActions";
import { useCareContext } from "./providers/CareProvider";

import { router } from "./router/Router";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { TolgeeInstance, useTolgee } from "@tolgee/react";
import { Settings as DateTimeSettings } from "luxon";
import * as Sentry from "@sentry/react";
import { I18nProvider } from "@react-aria/i18n";
import { FeatureFlagSidebar } from "./components/common";
import { Splash } from "@frontend/lyng/common/Splash";
import { NotificationProvider } from "@frontend/lyng/notifications";
import { analytics } from "./analytics";
import segment from "./typewriter/segment";
import { NoRoleHome } from "./pages/home/NoRolesHome";

function setTenantGlobals(
  tenantSettings: ViewerQuery["viewer"]["tenantSettings"],
  tolgee: TolgeeInstance,
) {
  if (tenantSettings.language.toLowerCase() !== tolgee.getLanguage()) {
    tolgee.changeLanguage(tenantSettings.language.toLowerCase());

    if ("serviceWorker" in navigator) {
      // send a message to service worker with new language
      if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({
          type: "language",
          language: tenantSettings.language.toLowerCase(),
        });
      }
    }
  }
  if (tenantSettings.timezone !== DateTimeSettings.defaultZone.name) {
    DateTimeSettings.defaultZone = tenantSettings.timezone;
  }
}

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  environment: import.meta.env.VITE_TIER,

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const AppRoot = ({
  client,
}: {
  client: ApolloClient<NormalizedCacheObject>;
}) => {
  const isDev = import.meta.env.VITE_TIER === "dev";
  const isProd = import.meta.env.VITE_TIER === "prod";

  const tolgee = useTolgee();

  const { state, dispatch } = useCareContext();
  const { data, error } = useViewerQuery();

  const country = data?.viewer?.tenantSettings.country;
  const subscriptionData = data?.viewer?.novuSubscriberIds?.office;
  const getRedirectUri = useCallback((uri = import.meta.env.VITE_DOMAIN) => {
    return uri.includes("dev")
      ? "https://app.dev.dala.care"
      : "http://app.dala.care";
  }, []);

  const isBackoffice = useCallback((viewer: ViewerQuery["viewer"]): boolean => {
    return !viewer.roles || viewer.roles.length === 0
      ? true
      : viewer.roles.some((role) => {
          return (
            role.roleType === RoleType.Admin ||
            role.roleType === RoleType.SchedulingManager ||
            role.roleType === RoleType.SuccessManager
          );
        });
  }, []);

  const { selectedTenant } = state;

  useEffect(() => {
    const viewer = data?.viewer;

    if (viewer) {
      //Redirect from team if user does not have relevant role
      if (!isBackoffice(viewer)) {
        localStorage.clear();
        window.location.replace(getRedirectUri());
        return;
      }

      const tenant =
        viewer.tenants.find((tenant) => tenant.active) ?? viewer.tenants[0];
      dispatch(UserActions.setViewer({ viewer, tenant }));
      localStorage.setItem("tenantId", tenant.id);

      Sentry.setUser({
        id: viewer.id,
      });

      setTenantGlobals(viewer.tenantSettings, tolgee);

      analytics.identify(viewer.id, {
        tenantId: tenant.id,
        tenantName: tenant.name,
      });

      analytics.group(tenant.id, {
        tenantName: tenant.name,
      });
    }

    if (error) dispatch(UserActions.setViewerError(error));
  }, [dispatch, data, error, tolgee, isBackoffice, getRedirectUri]);

  const hasNoRole = !state?.viewer?.roles || !state?.viewer.roles.length;

  return !state.viewer ? (
    <Splash />
  ) : hasNoRole ? (
    <NoRoleHome />
  ) : (
    <IntercomProvider
      appId={import.meta.env.VITE_INTERCOM_APP_ID}
      shouldInitialize={isProd}
      autoBoot={true}
      autoBootProps={{
        email: state.viewer?.email ?? "",
        name: `${state.viewer.firstName} ${state.viewer.lastName}`,
        userId: state.viewer?.id,
        userHash: state.viewer?.intercomId,

        customAttributes: {
          has_default_settings: state.viewer.tenantSettings.isDefault,
          isAdmin: state.viewer.roles.some(
            (r) => r.roleType === RoleType.Admin,
          ),
        },

        company: selectedTenant && {
          companyId: selectedTenant.id,
          name: selectedTenant.name,
          createdAt: selectedTenant.createdAt,
        },
      }}
    >
      {/* Overrides locale for react-aria components */}
      <I18nProvider locale={country}>
        <NotificationProvider
          subscriptionData={subscriptionData}
          active={true}
          feedId="office"
          trackingFunctions={{
            pushNotificationEnabled: () => {
              segment.pushNotificationsEnabled({});
            },
            notificationsAllRead: () => {
              segment.notificationsAllMarkedRead({});
            },
            notificationClicked: (count, type) => {
              segment.notificationClicked({ Count: count, Type: type });
            },
          }}
        >
          <RouterProvider router={router(state.viewer, client)} />
        </NotificationProvider>
        <Toaster />
        {isDev && <FeatureFlagSidebar />}
      </I18nProvider>
    </IntercomProvider>
  );
};

export default AppRoot;
