import {
  DecimalSeparator,
  TenantSettingsQuery,
  TenantSettingsQueryVariables,
  useTenantSettingsUpdateMutation,
} from "../../../api/generated/graphql";
import { tenantSettings } from "../../../api/operations/TenantSettings";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { useLoaderData } from "react-router-dom";

import { WorkspaceSettingsForm } from "./workspaceSettingsForm/WorkspaceSettingsForm";
import toast from "react-hot-toast";
import { useTranslate } from "@tolgee/react";
import { errorToToastMessage } from "../../../utils/toastUtils";

export const tenantSettingsLoader = async (
  client: ApolloClient<NormalizedCacheObject>,
): Promise<TenantSettingsQuery> => {
  const res = await client.query<
    TenantSettingsQuery,
    TenantSettingsQueryVariables
  >({
    query: tenantSettings,
    fetchPolicy: "cache-first",
  });

  return res.data;
};

export const WorkspaceSettings = () => {
  const { t } = useTranslate();
  const tenantSettings = (useLoaderData() as TenantSettingsQuery).viewer
    .tenantSettings;

  const [tenantSettingsUpdate] = useTenantSettingsUpdateMutation({
    refetchQueries: ["Viewer"],
  });

  const onSubmit = async (
    values: TenantSettingsQuery["viewer"]["tenantSettings"],
  ) => {
    const promise = tenantSettingsUpdate({
      variables: {
        input: {
          ...values,
          // TODO: remove when server is updated
          decimalSeparator: DecimalSeparator.Comma,
        },
      },
    });

    await toast.promise(promise, {
      loading: t("workspaceSettings.updating"),
      success: t("workspaceSettings.updated"),
      error: (err_1) => errorToToastMessage(err_1),
    });
  };

  return (
    <div className="p-5 md:p-0">
      <WorkspaceSettingsForm
        tenantSettings={tenantSettings}
        onSubmit={onSubmit}
      />
    </div>
  );
};
