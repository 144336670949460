import {
  CareTeamMembersQuery,
  useCareTeamMembersQuery,
} from "../../../api/generated/graphql";
import { Button, Status, Table } from "../../../components/common";
import { useCareContext } from "../../../providers/CareProvider";
import { ColumnDef } from "@tanstack/react-table";
import {
  flattenedOrganization,
  groupOrganizationUnits,
} from "../../../utils/organizationUtils";
import { useMemo, useCallback } from "react";
import { useTranslate } from "@tolgee/react";
import { Member } from "./MemberForm";
import { useSortingOptions } from "../../../utils/hooks/useSortingOptions";
import { useNavigate } from "react-router-dom";
import { Headline, HeadlineContainer } from "@frontend/lyng/typography";
import { useTranslateCareTeamMemberRole } from "./hook";

type CareTeamMemberRow = CareTeamMembersQuery["careTeamMembers"][number];

const Members = () => {
  const navigate = useNavigate();
  const {
    state: { viewer },
  } = useCareContext();
  const country = viewer?.tenantSettings.country;
  const { nameOrderFn } = useSortingOptions();

  const { t } = useTranslate();

  const { data, loading, error } = useCareTeamMembersQuery({});

  const organizationUnits: flattenedOrganization = useMemo(() => {
    return (data && groupOrganizationUnits(data.organization)) || [[], [], []];
  }, [data]);

  const openModalForm = useCallback(
    (member?: Partial<Member> | undefined) => {
      if (member) {
        navigate(`./${member.id}`, {
          state: {
            member: member,
          },
        });
      } else {
        navigate("./new", {
          state: {
            member: {},
          },
        });
      }
    },
    [navigate],
  );

  const organizationMap = useMemo(() => {
    if (data) {
      const [divisions, regions, offices] = organizationUnits;
      const map = new Map<string, string>();
      divisions.forEach((division) => map.set(division.id, division.name));
      regions.forEach((region) => map.set(region.id, region.name));
      offices.forEach((office) => map.set(office.id, office.name));
      map.set(data.organization.id, data.organization.name);
      return map;
    } else return new Map<string, string>();
  }, [data, organizationUnits]);

  const translateCareTeamMemberRoleType = useTranslateCareTeamMemberRole(t);

  const columns = useMemo<ColumnDef<CareTeamMemberRow>[]>(() => {
    const collator = new Intl.Collator(country);
    return [
      {
        id: "name",
        header: t("name") ?? "",
        accessorFn: (row) => nameOrderFn(row),
        sortingFn: (a, b, cId) =>
          collator.compare(a.getValue(cId), b.getValue(cId)),
      },
      {
        header: t("phone") ?? "",
        accessorFn: (row) => row.phone,
        cell: (row) => (
          <a href={`tel:${row.getValue<string>()}`}>{row.getValue<string>()}</a>
        ),
      },
      {
        header: t("role") ?? "",
        accessorFn: (row) =>
          translateCareTeamMemberRoleType(row.careTeamMemberRoles[0].roleType),
      },
      {
        header: t("organization.label") ?? "",
        accessorFn: (row) =>
          row.careTeamMemberRoles[0]?.resourceId &&
          organizationMap.get(row.careTeamMemberRoles[0]?.resourceId),
      },
      {
        header: t("email") ?? "",
        accessorFn: (row) => row.email,
        cell: (row) => (
          <a href={`mailto:${row.getValue<string>()}`}>
            {row.getValue<string>()}
          </a>
        ),
      },
      {
        header: t("status") ?? "",
        accessorFn: (row) => row.careTeamMemberRoles[0]?.deactivatedAt,
        enableSorting: false,
        cell: (row) => <Status active={!row.getValue<string>()} />,
      },
      {
        header: "",
        id: "edit",
        accessorFn: (row) => row,
        enableSorting: false,
        cell: (row) => (
          <Button
            type="button"
            variant="tertiary"
            text={t("edit") ?? ""}
            onClick={() =>
              openModalForm({
                id: row.row.original.id,
                firstName: row.row.original.firstName ?? "",
                lastName: row.row.original.lastName ?? "",
                email: row.row.original.email ?? "",
                phone: row.row.original.phone,
                homePhone: row.row.original.homePhone,
                birthDate: row.row.original.birthDate,
                pid: row.row.original.pid,
                deactivatedAt:
                  row.row.original.careTeamMemberRoles[0].deactivatedAt,
                role: row.row.original.careTeamMemberRoles[0].roleType,
                resourceId: row.row.original.careTeamMemberRoles[0].resourceId,
              })
            }
          />
        ),
      },
    ];
  }, [
    country,
    t,
    nameOrderFn,
    organizationMap,
    openModalForm,
    translateCareTeamMemberRoleType,
  ]);

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div className="p-5 md:p-0">
      <HeadlineContainer>
        <Headline size="l">{t("teamMembers.title")}</Headline>
        <Button
          className="ml-auto"
          variant="primary"
          text={t("teamMembers.add").toString()}
          onClick={() => openModalForm(undefined)}
        />
      </HeadlineContainer>
      <Table
        columns={columns}
        data={data?.careTeamMembers ?? []}
        defaultSorting={[{ id: "name", desc: false }]}
        loading={loading}
      />
    </div>
  );
};

export default Members;
