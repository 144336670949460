import { DistanceUnit } from "../../api/generated/graphql";
import { useCareContext } from "../../providers/CareProvider";
import { useMemo } from "react";

export const useNumberFormatter = () => {
  const {
    state: { viewer },
  } = useCareContext();

  return useMemo(() => {
    const country = viewer?.tenantSettings?.country;
    const currencyCode = viewer?.tenantSettings?.currency || "";
    const [distanceUnit, distanceUnitShort] =
      viewer?.tenantSettings?.distanceUnit === DistanceUnit.Kilometers
        ? ["kilometer", "km"]
        : ["mile", "mi"];

    const formatNumber = (value: number) =>
      Intl.NumberFormat(country).format(value);
    const formatCurrency = (value: number) =>
      Intl.NumberFormat(country, {
        style: "currency",
        currency: currencyCode,
      }).format(value);
    const formatDistance = (value: number) =>
      Intl.NumberFormat(country, { style: "unit", unit: distanceUnit }).format(
        value
      );

    const currencyFormatParts = country
      ? Intl.NumberFormat(country, {
          style: "currency",
          currency: currencyCode,
          currencyDisplay: "narrowSymbol",
        }).formatToParts(0)
      : [];

    const currencySymbol =
      currencyFormatParts.find((part) => part.type === "currency")?.value ?? "";
    const currencySide =
      currencyFormatParts.findIndex((part) => part.type === "currency") === 0
        ? "left"
        : "right";

    return {
      formatNumber,
      formatCurrency,
      formatDistance,
      country,
      currencyCode,
      currencySide,
      distanceUnit,
      distanceUnitShort,
      currencySymbol,
    };
  }, [viewer]);
};
