import classNames from "classnames";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslate } from "@tolgee/react";
import Button from "../button";
import { ListCheckBoxes } from "../listCheckBoxes/ListCheckBoxes";
import Modal from "../modal/Modal";

type Props = {
  className?: string;
  data: string[][] | (() => string[][]);
  name?: string;
  onClick?: () => void;
};

export const ExportCSV = ({ className, data, name, onClick }: Props) => {
  const { t } = useTranslate();

  return (
    <CSVLink
      className={classNames(
        className,
        "relative inline-flex items-center justify-center rounded-xl bg-primary-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2",
      )}
      data={data}
      filename={`${name ?? "exported_csv"}.csv`}
      onClick={onClick}
    >
      {t("exportCsv")}
    </CSVLink>
  );
};

type ColumnPickerProps = {
  show: boolean;
  onClose: () => void;
  name?: string;
  defaultSelectedColumns: Record<string, boolean>;
  columns: { id: string; label: string }[];
  getData: (selectedColumns: string[]) => string[][];
};
export const CSVExportColumnPickerModal = ({
  show,
  onClose,
  name,
  defaultSelectedColumns,
  columns,
  getData,
}: ColumnPickerProps) => {
  const { t } = useTranslate();
  const [selectedColumns, setSelectedColumns] = useState(
    defaultSelectedColumns,
  );

  useEffect(() => {
    setSelectedColumns(defaultSelectedColumns);
  }, [defaultSelectedColumns]);

  return (
    <Modal show={show} onClose={onClose}>
      <Modal.Title>{t("exportCsv")}</Modal.Title>
      <ListCheckBoxes
        id="export-csv"
        items={columns.map((column) => ({
          key: column.id,
          label: column.label,
          checked: selectedColumns[column.id] !== false, // undefined is true
          onChange: (e) => {
            setSelectedColumns((prev) => ({
              ...prev,
              [column.id]: e.target.checked,
            }));
          },
        }))}
      />
      <Modal.Footer>
        <Button
          className="w-full"
          onClick={() => onClose()}
          text={t("cancel").toString()}
          variant="secondary"
        />
        <ExportCSV
          className="w-full"
          data={getData(
            columns
              .filter((column) => selectedColumns[column.id] !== false)
              .map((column) => column.id),
          )}
          name={name}
          onClick={() => onClose()}
        />
      </Modal.Footer>
    </Modal>
  );
};
