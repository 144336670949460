import classNames from "classnames";

import {
  ColumnDef,
  OnChangeFn,
  SortingState,
  Table as TanTable,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { ChevronOutline } from "@frontend/lyng/assets/svg/Chevron";
import { Popover, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import Skeleton from "react-loading-skeleton";
import Button from "../button";
import { useTranslate } from "@tolgee/react";
import {
  ListCheckBoxes,
  ListCheckboxesItem,
} from "../listCheckBoxes/ListCheckBoxes";
import { EyeOpen } from "@frontend/lyng/assets/svg/Eye";

const TableRoot = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      // relative is needed for the column picker popover to work
      className="relative"
    >
      <div className="-mx-4 overflow-y-scroll shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          {children}
        </table>
      </div>
    </div>
  );
};

const TableHeader = ({ children }: { children: React.ReactNode }) => {
  return <thead className="bg-gray-50">{children}</thead>;
};

const TableBody = ({ children }: { children: React.ReactNode }) => {
  return (
    <tbody className="divide-y divide-gray-200 bg-white [&>*:nth-child(even)]:bg-gray-50">
      {children}
    </tbody>
  );
};

const TableHeaderCell = ({ children }: { children: React.ReactNode }) => {
  return (
    <th
      scope="col"
      className="py-3.5 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:px-6"
    >
      {children}
    </th>
  );
};

const TableRow = ({ children }: { children: React.ReactNode }) => {
  return <tr>{children}</tr>;
};

const TableCell = ({
  children,
  topAlign,
}: {
  children: React.ReactNode;
  topAlign: boolean;
}) => {
  return (
    <td
      className={`w-full max-w-0 py-4 pl-4 pr-3 text-sm font-normal text-gray-900 sm:w-auto sm:max-w-none sm:px-6 ${
        topAlign ? "align-top" : ""
      }`}
    >
      {children}
    </td>
  );
};

export function ColumnVisibilityPopover<TData>({
  table,
}: {
  table: TanTable<TData>;
}) {
  const { t } = useTranslate();
  const checkBoxItems = table.getAllLeafColumns().reduce((acc, column) => {
    if (typeof column.columnDef.header !== "string" || !column.getCanHide()) {
      return acc;
    }
    return [
      ...acc,
      {
        key: column.id,
        label: column.columnDef.header,
        checked: column.getIsVisible(),
        onChange: column.getToggleVisibilityHandler(),
      },
    ];
  }, [] as ListCheckboxesItem[]);

  return (
    <Popover className="justify-end">
      <Popover.Button
        as={Button}
        className={"!p-0"}
        variant="tertiary"
        text={t("columns")}
        leftIcon={EyeOpen}
      />

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute right-4 z-10 mt-1 w-screen max-w-min">
          <div className="max-h-[688px] w-80 overflow-auto rounded-xl bg-white p-1 text-sm font-semibold normal-case leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
            <ListCheckBoxes id="column-visibility" items={checkBoxItems} />
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

type Props<TData> = {
  data: TData[];
  columns: ColumnDef<TData>[];
  defaultSorting?: SortingState;
  columnVisibility?: VisibilityState;
  onColumnVisibilityChange?: OnChangeFn<VisibilityState>;
  loading?: boolean;
  loadingRowCount?: number;
  topAlign?: boolean;
};
export function Table<TData>({
  data,
  columns,
  defaultSorting,
  columnVisibility,
  onColumnVisibilityChange,
  loading = false,
  loadingRowCount = 10,
  topAlign = false,
}: Props<TData>) {
  const [sorting, setSorting] = useState<SortingState>(defaultSorting ?? []);
  const table = useReactTable<TData>({
    data: data,
    columns,
    state: {
      sorting,
      columnVisibility,
    },
    enableSortingRemoval: false,
    onSortingChange: setSorting,
    onColumnVisibilityChange,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <TableRoot>
      <TableHeader>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableHeaderCell key={header.id}>
                {header.isPlaceholder ? null : (
                  <div
                    onClick={header.column.getToggleSortingHandler()}
                    className={classNames({
                      "flex cursor-pointer select-none items-center hover:text-greyscale-700":
                        header.column.getCanSort(),
                      "text-greyscale-500": !header.column.getCanSort(),
                    })}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                    {header.column.getCanSort() && (
                      <ChevronOutline
                        height={16}
                        width={16}
                        className={classNames("ml-3", {
                          invisible: !header.column.getIsSorted(),
                          "rotate-180": header.column.getIsSorted() === "asc",
                        })}
                      />
                    )}
                  </div>
                )}
              </TableHeaderCell>
            ))}
          </TableRow>
        ))}
      </TableHeader>

      <TableBody>
        <>
          {table.getRowModel().rows.map((row) => {
            return (
              <TableRow key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <TableCell key={cell.id} topAlign={topAlign}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
          {loading &&
            [...Array(loadingRowCount)].map((_, i) => (
              <TableRow key={i}>
                {table.getVisibleFlatColumns().map((column) => (
                  <TableCell key={column.id} topAlign={topAlign}>
                    <Skeleton />
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </>
      </TableBody>
    </TableRoot>
  );
}
