import React, { useRef, useEffect } from "react";
import mapboxgl from "mapbox-gl";

mapboxgl.accessToken = import.meta.env.VITE_MAP_BOX_TOKEN;

export interface Marker {
  lng: number;
  lat: number;
  title: string;
}

interface MapProps {
  center: Exclude<Marker, "title">;
  markers: Marker[];
}

const defaultZoom = 16;

const MapBox: React.FC<MapProps> = ({ center, markers }) => {
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);

  useEffect(() => {
    let localMap: mapboxgl.Map | null = null;

    if (mapContainerRef.current && !mapRef.current) {
      localMap = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/dalafinnur/clnkf1whm001a01pg5d397m2g",
        center: center,
        zoom: defaultZoom,
        scrollZoom: false,
      });
      localMap.addControl(new mapboxgl.NavigationControl());
    }

    mapRef.current = localMap;

    localMap?.on("load", function () {
      if (mapRef.current) {
        // Add markers to the map
        markers.forEach((marker, i) => {
          // style the first marker in green pastel
          // and the second marker in red pastel
          const { lng, lat, title } = marker;
          new mapboxgl.Marker({
            color: i === 0 ? "#00C86E" : "#FF6B72",
          })
            .setLngLat([lng, lat])
            .setPopup(new mapboxgl.Popup({ offset: 25 }).setText(title))
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .addTo(localMap!);
        });
      }
    });

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [center, markers]);

  return (
    <div ref={mapContainerRef} style={{ width: "100%", height: "450px" }} />
  );
};

export default MapBox;
