type Props = {
  className?: string;
  width?: number;
  height?: number;
};
export const TrashIcon = ({ className, width = 16, height = 16 }: Props) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.6001 3.59995H14.4001M5.6001 1.19995H10.4001M6.4001 11.6V6.79995M9.6001 11.6V6.79995M10.8001 14.8H5.2001C4.31644 14.8 3.6001 14.0836 3.6001 13.2L3.23482 4.43326C3.21588 3.97876 3.57923 3.59995 4.03412 3.59995H11.9661C12.421 3.59995 12.7843 3.97876 12.7654 4.43326L12.4001 13.2C12.4001 14.0836 11.6838 14.8 10.8001 14.8Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
