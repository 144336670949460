import classNames from "classnames";
import { match } from "ts-pattern";

type Props = {
  id?: string;
  size?: "m" | "s" | "xs" | "xxs";
  htmlFor: string;
  children: React.ReactNode;
};
/**
 * @deprecated use typography component
 */
const Label = ({ id, children, htmlFor, size = "xs" }: Props) => {
  const sizeClass = match(size)
    .with("m", () => "text-lg")
    .with("s", () => "text-base")
    .with("xs", () => "text-sm")
    .with("xxs", () => "text-xs");
  return (
    <label
      id={id}
      htmlFor={htmlFor}
      className={classNames(
        sizeClass,
        "block font-bold text-gray-700 sm:row-span-2 sm:mt-px"
      )}
    >
      {children}
    </label>
  );
};

export default Label;
