import {
  useDeleteVisitMutation,
  useVisitCancelMutation,
  useVisitCreateMutation,
  useVisitLogCreateMutation,
  useVisitUpdateFollowingMutation,
  useVisitUpdateMutation,
  useVisitReactivateMutation,
} from "../../api/generated/graphql";

export const useVisitMutations = () => {
  const [visitCreateMutation, { loading: visitCreateLoading }] =
    useVisitCreateMutation({
      refetchQueries: ["EventsByCareRecipientId", "EventsByCaregiverId"],
    });

  const [visitLogCreateMutation, { loading: visitLogCreateLoading }] =
    useVisitLogCreateMutation({
      refetchQueries: ["EventsByCareRecipientId", "EventsByCaregiverId"],
    });
  const [visitUpdateMutation, { loading: visitUpdateLoading }] =
    useVisitUpdateMutation({
      refetchQueries: [
        "EventsByCareRecipientId",
        "EventsByCaregiverId",
        "ScheduleTimeline",
      ],
    });

  const [
    visitUpdateFollowingMutation,
    { loading: visitUpdateFollowingLoading, reset: visitUpdateFollowingReset },
  ] = useVisitUpdateFollowingMutation({
    refetchQueries: [
      "EventsByCareRecipientId",
      "EventsByCaregiverId",
      "ScheduleTimeline",
    ],
  });
  const [
    visitDeleteMutation,
    { loading: deleteVisitLoading, reset: deleteVisitReset },
  ] = useDeleteVisitMutation({
    refetchQueries: [
      "EventsByCareRecipientId",
      "EventsByCaregiverId",
      "ScheduleTimeline",
    ],
  });
  const [
    visitCancelMutation,
    { loading: visitCancelLoading, reset: visitCancelReset },
  ] = useVisitCancelMutation({
    refetchQueries: [
      "VisitById",
      "EventsByCareRecipientId",
      "EventsByCaregiverId",
      "ScheduleTimeline",
    ],
  });

  const [
    visitReactivateMutation,
    { loading: visitReactivateLoading, reset: visitReactivateReset },
  ] = useVisitReactivateMutation({
    refetchQueries: [
      "VisitById",
      "EventsByCareRecipientId",
      "EventsByCaregiverId",
      "ScheduleTimeline",
    ],
  });

  return {
    visitCreateMutation,
    visitCreateLoading,

    visitLogCreateMutation,
    visitLogCreateLoading,

    visitUpdateMutation,
    visitUpdateLoading,

    visitUpdateFollowingMutation,
    visitUpdateFollowingLoading,
    visitUpdateFollowingReset,

    visitDeleteMutation,

    deleteVisitLoading,
    deleteVisitReset,

    visitCancelMutation,
    visitCancelLoading,
    visitCancelReset,

    visitReactivateMutation,
    visitReactivateLoading,
    visitReactivateReset,
  };
};
