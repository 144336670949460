import { LoadingSpinner, SlideOver } from "../../../components/common";
import { MemberForm, Member } from "./MemberForm";
import { useTranslate } from "@tolgee/react";
import { useNavigate, useParams } from "react-router-dom";
import { useCareTeamMembersQuery } from "../../../api/generated/graphql";
import { useLocation } from "react-router-dom";
import { DeactivatedMember } from "./DeactivatedMember";
import { errorToToastMessage } from "../../../utils/toastUtils";
import { toast } from "react-hot-toast";
import { useTeamMemberMutations } from "./hook";
import { Type, userUpdated } from "../../../typewriter/segment";

export const MemberModal = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const { memberId } = useParams();
  const { update } = useTeamMemberMutations();

  const { data, loading, error } = useCareTeamMembersQuery({});

  const { state } = useLocation();

  const memberData = data?.careTeamMembers.find(
    (member) => member.id === memberId,
  );

  const loadMemberData = { member: memberData };

  const handleClose = () => navigate("..");

  const { member } = state ?? loadMemberData;

  const handleSubmit: (values: Member) => Promise<void> = (values) => {
    if (values.id) {
      const id = values.id;
      const promise = update(id, {
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        homePhone: values.homePhone,
        birthDate: values.birthDate,
        pid: values.pid,
      }).then(() => {
        handleClose();
        userUpdated({
          user_id: id,
          type: Type.TeamMember,
        });
      });

      return toast.promise(promise, {
        loading: "Updating team member...",
        success: () => {
          const { firstName, lastName } = values;
          return `Successfully saved ${firstName} ${lastName}`;
        },
        error: (err) => errorToToastMessage(err),
      });
    }

    return Promise.resolve();
  };

  return (
    <SlideOver
      title={`${memberId ? t("teamMembers.update") : t("teamMembers.add")}`}
      onClose={() => handleClose()}
    >
      {member?.id &&
      member.deactivatedAt &&
      member.role &&
      member.resourceId &&
      !loading ? (
        <DeactivatedMember
          id={member.id}
          role={member.role}
          resourceId={member.resourceId}
          name={`${member.firstName} ${member.lastName}`}
          deactivatedAt={member.deactivatedAt}
          onClose={() => handleClose()}
        />
      ) : (
        <>
          {!data && loading && (
            <div className="flex h-full items-center justify-center">
              <LoadingSpinner />
            </div>
          )}
          {error && <p>{error.message}</p>}
          {data && (
            <MemberForm
              member={member}
              onSubmit={(values: Member) => handleSubmit(values)}
            />
          )}
        </>
      )}
    </SlideOver>
  );
};
