import { NameOrder } from "../../api/generated/graphql";
import { useCareContext } from "../../providers/CareProvider";
import { useMemo } from "react";
import { match } from "ts-pattern";

export const useSortingOptions = () => {
  const {
    state: { viewer },
  } = useCareContext();

  return useMemo(() => {
    const nameOrder = viewer?.tenantSettings?.nameOrder || NameOrder.LastFirst;
    const nameOrderFn = ({
      firstName,
      lastName,
    }: {
      firstName: string | null;
      lastName: string | null;
    }) =>
      match(nameOrder)
        .with(NameOrder.FirstLast, () => `${firstName ?? ""} ${lastName ?? ""}`)
        .with(
          NameOrder.LastFirst,
          () => `${lastName ?? ""}, ${firstName ?? ""}`
        )
        .exhaustive();

    const country = viewer?.tenantSettings?.country;
    const collator = new Intl.Collator(country);

    return { nameOrder, nameOrderFn, collator };
  }, [viewer?.tenantSettings?.nameOrder, viewer?.tenantSettings?.country]);
};
