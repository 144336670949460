import classNames from "classnames";
type Props = {
  className?: string;
  children: React.ReactNode;
};

export const Card = ({ className, children }: Props) => {
  return (
    <div
      className={classNames(
        className,
        "relative w-full overflow-hidden rounded-2xl bg-white px-7 py-6 shadow"
      )}
    >
      {children}
    </div>
  );
};

type ColumnCardProps = { children: React.ReactNode };
export const ColumnCard = ({ children }: ColumnCardProps) => {
  return (
    <Card className="flex-grow basis-auto lg:basis-64 xl:flex-grow-0 xl:basis-auto">
      {children}
    </Card>
  );
};
