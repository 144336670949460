import classNames from "classnames";
import { FormEventHandler } from "react";

type BodyProps = {
  children: React.ReactNode;
  className?: string;
};
const Body = ({ className, children }: BodyProps) => {
  return (
    <div className={classNames("space-y-6 sm:space-y-5", className)}>
      {children}
    </div>
  );
};
type FooterProps = {
  children: React.ReactNode;
};
const Footer = ({ children }: FooterProps) => {
  return (
    <div className="pt-5">
      <div className="flex justify-end gap-3">{children}</div>
    </div>
  );
};
const StickyFooter = ({ children }: FooterProps) => {
  return (
    <div className="sticky bottom-0 z-40 border-t border-greyscale-200 bg-white px-3 py-2">
      <div className="flex justify-between">{children}</div>
    </div>
  );
};
type SectionProps = {
  title: string;
  description?: string;
  children: React.ReactNode;
};
const Section = ({ title, description, children }: SectionProps) => {
  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
      <div className="px-4 sm:px-0">
        <div className="sticky top-0 bg-white">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            {title}
          </h2>
          {description && (
            <p className="mt-1 text-sm leading-6 text-gray-600">
              {description}
            </p>
          )}
        </div>
      </div>
      <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
        <div className="overflow-hidden px-4 py-6 sm:p-8">
          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

type Props = {
  className?: string;
  children: React.ReactNode;
  onSubmit?: FormEventHandler<HTMLFormElement>;
};
const Root = ({ className, children, onSubmit }: Props) => {
  return (
    <form
      // TODO remove spacing? Talk to Berglind.
      className={classNames("space-y-8 divide-y divide-gray-200", className)}
      onSubmit={onSubmit}
    >
      {children}
    </form>
  );
};

const Form = Object.assign(Root, { Footer, StickyFooter, Body, Section });

export default Form;
