import { Label } from "../../typography";

type Group<T> = {
  label: string;
  options: T[];
};
export function GroupLabel<T>({ data }: { data: Group<T> }) {
  return (
    <div className="flex items-center justify-between font-normal py-3 pl-3 pr-4 my-2 bg-greyscale-50">
      <Label size="xs" className="text-greyscale-700">
        {data.label}
      </Label>
      <span className="inline-bloc rounded-full bg-greyscale-200 px-2 text-xs text-greyscale-700">
        {data.options.length}
      </span>
    </div>
  );
}
