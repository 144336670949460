import classNames from "classnames";
import { match, P } from "ts-pattern";

import { LoadingSpinner } from "@frontend/lyng/assets/svg";
import { forwardRef } from "react";

export type Variant =
  | "primary"
  | "secondary"
  | "tertiary"
  | "subdued"
  | "danger";
type Size = "xs" | "sm" | "md" | "lg" | "xl";
type Props = {
  id?: string;
  variant?: Variant;
  size?: Size;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  leftIcon?: React.ElementType;
  rightIcon?: React.ElementType;
  text?: string;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  type?: "submit" | "reset" | "button" | undefined;
  ariaLabel?: string;
};

const Button = forwardRef<HTMLButtonElement, Props>(function Button(
  {
    id,
    variant = "primary",
    size = "md",
    onClick,
    leftIcon: LeftIcon,
    rightIcon: RightIcon,
    text,
    className,
    loading = false,
    disabled = false,
    type = "button",
    ariaLabel,
  },
  ref,
) {
  const commonClassNames =
    "relative inline-flex items-center justify-center font-medium focus:outline-none";
  const sizeClassNames = match(size)
    .with("xs", () => "rounded-full h-8 w-8 text-xs")
    .with("sm", () => "rounded-2xl text-sm leading-4")
    .with("md", () => "rounded-xl text-base")
    .with("lg", () => "rounded-md y-2 text-xl")
    .with("xl", () => "rounded-md text-xl")
    .exhaustive();

  const paddingClassNames = match([variant, size, text])
    .with(["tertiary", P._, P._], () => "") // No padding for tertiary buttons because they look like links
    .with([P._, "xs", undefined], () => "") // No padding if button has no text
    .with([P._, "xs", P._], () => "px-3")
    .with([P._, "sm", P._], () => "px-3 py-2")
    .with([P._, "md", P._], () => "px-4 py-2")
    .with([P._, "lg", P._], () => "px-4 py-2")
    .with([P._, "xl", P._], () => "px-6 py-3")
    .exhaustive();

  const variantClassNames = match([variant, disabled] as const)
    .with(["tertiary", true], () => "text-greyscale-300")
    .with([P._, true], () => "bg-gray-100 text-gray-700")
    .with(
      ["primary", false],
      () =>
        "bg-primary-500 text-white hover:bg-gradient-274 hover:from-primary-600 hover:to-primary-300 focus:ring-primary-500 focus:ring-2 focus:ring-offset-2",
    )
    .with(
      ["secondary", false],
      () =>
        "bg-primary-100 text-primary-700 hover:bg-primary-200 active:ring-primary-500 active:ring-2 active:ring-offset-2",
    )
    .with(
      ["tertiary", false],
      () =>
        "text-primary-700 hover:text-primary-600 active:ring-0 active:text-primary-600",
    )
    .with(
      ["subdued", false],
      () =>
        "bg-gray-100 text-gray-700 hover:bg-gray-200 active:ring-primary-500 active:ring-2 active:ring-offset-2",
    )
    .with(
      ["danger", false],
      () =>
        "border-2 border-red-400 text-red-400 shadow-sm hover:bg-red-400 hover:text-white active:ring-red-400 active:ring-2 active:ring-offset-2",
    )
    .exhaustive();

  const leftIconClassName = match(size)
    .with("xs", () => `${text && LeftIcon ? "-ml-0.5 mr-2" : ""}  h-4 w-4`) // TODO: Check fit
    .with("sm", () => `${text && LeftIcon ? "-ml-0.5 mr-2" : ""}  h-4 w-4`)
    .with("md", () => `${text && LeftIcon ? "-ml-1 mr-2" : ""} h-5 w-5`)
    .with("lg", () => `${text && LeftIcon ? "-ml-1 mr-3" : ""} h-5 w-5`)
    .with("xl", () => `${text && LeftIcon ? "-ml-1 mr-3" : ""} h-5 w-5`)
    .exhaustive();

  const rightIconClassName = match(size)
    .with("xs", () => `${text && RightIcon ? "-mr-0.5 ml-2" : ""} h-4 w-4`) // TODO: Check fit
    .with("sm", () => `${text && RightIcon ? "-mr-0.5 ml-2" : ""} h-4 w-4`)
    .with("md", () => `${text && RightIcon ? "-mr-1 ml-2" : ""} h-5 w-5`)
    .with("lg", () => `${text && RightIcon ? "-mr-1 ml-3" : ""} h-5 w-5`)
    .with("xl", () => `${text && RightIcon ? "-mr-1 ml-3" : ""} h-5 w-5`)
    .exhaustive();

  return (
    <button
      id={id}
      type={type}
      ref={ref}
      className={classNames(
        commonClassNames,
        sizeClassNames,
        paddingClassNames,
        variantClassNames,
        className,
      )}
      onClick={onClick}
      disabled={disabled}
      aria-label={ariaLabel}
    >
      {loading && (
        <LoadingSpinner
          // center spinner if it's not replacing icon
          className={classNames(
            leftIconClassName,
            !LeftIcon && !RightIcon ? "absolute" : "",
          )}
        />
      )}
      {!loading && LeftIcon && <LeftIcon className={leftIconClassName} />}{" "}
      {/* Use LeftIcon here */}
      {text && (
        <span
          className={classNames(
            { "text-transparent": loading && !LeftIcon && !RightIcon },
            "whitespace-pre",
          )}
        >
          {text}
        </span>
      )}
      {!loading && RightIcon && <RightIcon className={rightIconClassName} />}{" "}
      {/* Use RightIcon here */}
    </button>
  );
});

export default Button;
