import { Headline } from "@frontend/lyng/typography";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

type TitleProps = {
  children: React.ReactNode;
};
const Title = ({ children }: TitleProps) => {
  return (
    <Dialog.Title as={Headline} size="m" className="mb-2 w-full text-center">
      {children}
    </Dialog.Title>
  );
};

type FooterProps = {
  children: React.ReactNode;
};
const Footer = ({ children }: FooterProps) => {
  return (
    <div className="mt-5 grid grid-cols-1 gap-3 sm:mt-6 sm:grid-flow-row-dense sm:grid-cols-2">
      {children}{" "}
    </div>
  );
};

type Props = {
  show: boolean;
  onClose?: () => void;
  initialFocus?: React.MutableRefObject<HTMLElement | null> | undefined;
  children: React.ReactNode;
};
const Root = ({
  show,
  onClose = () => undefined,
  initialFocus,
  children,
}: Props) => {
  return (
    <Transition.Root show={show} appear as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={initialFocus}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const Modal = Object.assign(Root, { Title, Footer });
export default Modal;
