import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import "@frontend/lyng/index.css";
import "./index.css";
import { CareProvider } from "./providers";
import reportWebVitals from "./reportWebVitals";
import "./utils/intlOverride";

import { Authenticator } from "@frontend/lyng/Authenticator";
import "react-loading-skeleton/dist/skeleton.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Authenticator app="office">
      <CareProvider>
        <App />
      </CareProvider>
    </Authenticator>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// src/main.js or src/main.ts
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register(
      import.meta.env.MODE === "production"
        ? "/service-worker.js"
        : "/dev-sw.js?dev-sw",
      { type: import.meta.env.MODE === "production" ? "classic" : "module" },
    )
    .catch((err) => {
      console.error("Service worker registration failed:", err);
    });
} else {
  console.log("Service workers are not supported in this browser.");
}
