import { LoadingSpinner } from "../../../components/common";
import { FormInput, UserForm } from "./UserForm";
import { useTranslate } from "@tolgee/react";
import { useNavigate, useParams } from "react-router-dom";
import { SlideOver } from "@frontend/lyng/slideOver";
import {
  useUserByIdQuery,
  ResourceType,
  RoleType,
} from "../../../api/generated/graphql";
import { match, P } from "ts-pattern";
import toast from "react-hot-toast";
import { errorToToastMessage } from "../../../utils/toastUtils";
import { roleTypeToCareTeamMemberRoleType } from "./utils";
import { useState } from "react";
import Modal from "@frontend/lyng/modal/Modal";
import { Paragraph } from "@frontend/lyng/typography";
import { Button } from "@frontend/lyng/button";
import { useUserMutationsWithCache } from "./hooks";

export const UserModal = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const { userId } = useParams();
  const [modalTitle, setModalTitle] = useState(t("users.addUser"));
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const {
    userCreateMutation,
    createLoading,
    userRoleReactivate,
    userRoleDeactivate,
    addCaregiverRole,
    addCareRecipientRole,
    addCareTeamMemberRole,
    userDelete,
  } = useUserMutationsWithCache();

  const { data, loading, error } = useUserByIdQuery({
    skip: userId === "new",
    fetchPolicy: "cache-and-network",
    variables: { id: userId ?? "" },
  });

  const user = userId !== "new" ? data?.userById : undefined;
  const handleClose = () => {
    navigate("..");
  };

  const onConfirmDelete = () => {
    if (!user) return;

    const promise = userDelete({
      variables: { userId: user.id ?? "" },
      refetchQueries: ["loginUsers"],
    }).then(() => {
      handleClose();
      setShowDeleteModal(false);
    });

    toast.promise(promise, {
      loading: t("users.deleting"),
      success: t("users.successDelete"),
      error: (err) => errorToToastMessage(err),
    });
  };

  const handleShowDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const handleSubmit: (values: FormInput) => Promise<void> = async (values) => {
    try {
      let userIdToUse = user?.id;

      if (!user) {
        const createUserPromise = userCreateMutation({
          variables: {
            input: {
              firstName: values.firstName || "",
              lastName: values.lastName || "",
              phone: values.phone || null,
              homePhone: values.homePhone || null,
              birthDate: values.birthDate || null,
              pid: values.pid || null,
              email: values.email || null,
            },
          },
        });

        const response = await toast.promise(createUserPromise, {
          loading: t("users.creating"),
          success: (res) => {
            const { firstName, lastName } = values;
            userIdToUse = res.data?.userCreate.id;
            return t("users.created", { name: `${firstName} ${lastName}` });
          },
          error: (err) => errorToToastMessage(err),
        });

        if (!response.data?.userCreate.id) {
          throw new Error("Failed to create user.");
        }

        userIdToUse = response.data.userCreate.id;
        navigate(`../${userIdToUse}`, { preventScrollReset: true });
      }

      if (!userIdToUse) {
        throw new Error("User ID is missing.");
      }

      const rolePromises = [
        ...values.careGiverRoles.map(async (role) => {
          const existingRole = user?.caregiverRoles?.find(
            (r) => r.office.id === role.office.id,
          );

          if (existingRole) {
            if (existingRole.deactivatedAt && role.status) {
              return userRoleReactivate({
                variables: {
                  resourceId: role.office.id,
                  resourceType: ResourceType.Office,
                  roleType: RoleType.Caregiver,
                  userId: userIdToUse || "",
                },
              });
            } else if (!existingRole.deactivatedAt && !role.status) {
              return userRoleDeactivate({
                variables: {
                  resourceId: role.office.id,
                  resourceType: ResourceType.Office,
                  roleType: RoleType.Caregiver,
                  userId: userIdToUse || "",
                },
              });
            }
          } else {
            return addCaregiverRole({
              variables: {
                userId: userIdToUse || "",
                input: { officeId: role.office.id },
              },
            });
          }
          handleClose();
        }),

        ...values.careRecipientRoles.map(async (role) => {
          const existingRole = user?.careRecipientRoles?.find(
            (r) => r.office.id === role.office.id,
          );

          if (existingRole) {
            if (existingRole.deactivatedAt && role.status) {
              return userRoleReactivate({
                variables: {
                  resourceId: role.office.id,
                  resourceType: ResourceType.Office,
                  roleType: RoleType.CareRecipient,
                  userId: userIdToUse || "",
                },
              });
            } else if (!existingRole.deactivatedAt && !role.status) {
              return userRoleDeactivate({
                variables: {
                  resourceId: role.office.id,
                  resourceType: ResourceType.Office,
                  roleType: RoleType.CareRecipient,
                  userId: userIdToUse || "",
                },
              });
            }
          } else {
            return addCareRecipientRole({
              variables: {
                userId: userIdToUse || "",
                input: { officeId: role.office.id },
              },
            });
          }
          handleClose();
        }),

        ...values.careTeamRoles.map(async (role) => {
          const existingRole = user?.careTeamMemberRoles?.find(
            (r) =>
              r.organizationUnit.id === role.organizationUnit?.id &&
              r.roleType === roleTypeToCareTeamMemberRoleType(role.role.value),
          );

          const careTeamRoleType = roleTypeToCareTeamMemberRoleType(
            role.role.value,
          );

          if (!careTeamRoleType) {
            throw new Error("Invalid Care Team Role Type.");
          }

          if (existingRole) {
            if (existingRole.deactivatedAt && role.status) {
              return userRoleReactivate({
                variables: {
                  resourceId: role.organizationUnit?.id ?? "",
                  resourceType:
                    role.organizationUnit?.type ?? ResourceType.Office,
                  roleType: role.role.value,
                  userId: userIdToUse || "",
                },
              });
            } else if (!existingRole.deactivatedAt && !role.status) {
              return userRoleDeactivate({
                variables: {
                  resourceId: role.organizationUnit?.id ?? "",
                  resourceType:
                    role.organizationUnit?.type ?? ResourceType.Office,
                  roleType: role.role.value,
                  userId: userIdToUse || "",
                },
              });
            }
          } else {
            return addCareTeamMemberRole({
              variables: {
                userId: userIdToUse || "",
                input: {
                  resourceType:
                    role.organizationUnit?.type ?? ResourceType.Office,
                  resourceId: role.organizationUnit?.id ?? "",
                  roleType: careTeamRoleType,
                },
              },
            });
          }
          handleClose();
        }),
      ];

      await Promise.all(rolePromises);
      toast.success(t("users.savedSuccessfully"));
    } catch (err) {
      toast.error(errorToToastMessage(err));
    }
  };

  return (
    <SlideOver show={true} onClose={handleClose}>
      <SlideOver.Title>{modalTitle}</SlideOver.Title>
      {match({ loading, error })
        .with({ loading: true }, () => (
          <div className="flex h-full items-center justify-center">
            <LoadingSpinner />
          </div>
        ))
        .with({ error: P.not(P.nullish) }, () => <div>{error?.message}</div>)
        .otherwise(() => (
          <UserForm
            setModalTitle={setModalTitle}
            user={user}
            onDeleteUser={handleShowDeleteModal}
            onSubmit={handleSubmit}
            isUserSubmitting={createLoading}
          />
        ))}
      <Modal show={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
        <Modal.Title>{t("users.permanentlyDeleteUser")}</Modal.Title>
        <Paragraph className="text-center" size="m">
          {t("users.permanentlyDeleteUserDescription", {})}
        </Paragraph>
        <Modal.Footer>
          <Button
            variant="critical"
            text={t("users.permanentlyDelete").toString()}
            className="col-start-2"
            onClick={onConfirmDelete}
          />
          <Button
            variant="secondary"
            text={t("cancel").toString()}
            className="col-start-1"
            onClick={() => setShowDeleteModal(false)}
            disabled={false}
          />
        </Modal.Footer>
      </Modal>
    </SlideOver>
  );
};
