import { Headline, Label, Paragraph } from "@frontend/lyng/typography";
import { Card } from "../card/Card";
import { ListVisit } from "./types";
import { DateTime } from "luxon";
import { useTranslate } from "@tolgee/react";
import { SmallPill, SmallPillColors } from "@frontend/lyng/pill";
import { Users } from "@frontend/lyng/assets/icons/16/outline/index";
import classNames from "classnames";
import {
  getHumanReadableRecurrence,
  getVisitState,
  VisitStates,
} from "../../../utils/visitUtils";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { match } from "ts-pattern";
import { useSortingOptions } from "../../../utils/hooks/useSortingOptions";
import { useCareContext } from "../../../providers";

type Props = {
  dayStr: string;
  visits: ListVisit[];
  visitClick: (arg: string) => string;
};

const ListDay = ({ visits, visitClick, dayStr }: Props) => {
  const date = DateTime.fromISO(dayStr);
  const day = date.day;
  const month = date.monthShort;
  const weekday = date.weekdayShort;
  const watchStart: DateTime | null = DateTime.local();
  const { t } = useTranslate();
  const { viewer } = useCareContext().state;

  const GetPills = (v: ListVisit) => {
    const visitState = getVisitState(v);

    const { color, text } = match<
      VisitStates,
      { color: SmallPillColors | null; text: string }
    >(visitState)
      .with("open", () => ({
        color: "yellow",
        text: t("schedule.open") ?? "",
      }))
      .with("cancelled", () => ({
        color: "gray",
        text: t("cancelled") ?? "",
      }))
      .with("active", () => ({
        color: "green",
        text: t("now") ?? "",
      }))
      .with("late-clockin", () => ({
        color: "red",
        text: t("filters.late") ?? "",
      }))
      .with("completed", () => ({
        color: "blue",
        text: t("done") ?? "",
      }))
      .with("missed", () => ({
        color: "red",
        text: t("missed") ?? "",
      }))
      .otherwise(() => ({ color: null, text: "" }));

    return (
      <div className="flex justify-end sm:justify-start 2xl:justify-end 2xl:px-2 gap-2">
        {color && <SmallPill color={color} text={text}></SmallPill>}
      </div>
    );
  };

  const GetLeftSide = ({ visit }: { visit: ListVisit }) => {
    const start = DateTime.fromISO(visit.start);
    const end = start.plus({ minutes: visit.durationMinutes });
    const isRecurring = visit.recurrence?.rRule;
    const clockInTime = visit.clockInTime;
    const visitState = getVisitState(visit);

    return (
      <div className="grid 2xl:grid-cols-2 sm:grid-cols-1 grid-cols-2 p-1 gap-1">
        <div className="flex items-center gap-1 flex-wrap">
          <Label
            size="s"
            className={classNames(
              visitState === "late-clockin" ? "!text-critical-800" : "",
              "whitespace-nowrap",
            )}
          >
            {visitState === "active" && clockInTime ? (
              <>
                <span>{DateTime.fromISO(clockInTime).toFormat("HH:mm")}</span>
                <span> - </span>
                <span className="opacity-30">{end.toFormat("HH:mm")}</span>
              </>
            ) : (
              `${start.toFormat("HH:mm")} - ${end.toFormat("HH:mm")}`
            )}
          </Label>
          <Paragraph className="sm:hidden" size="xs">
            {isRecurring &&
              getHumanReadableRecurrence(
                isRecurring,
                watchStart?.toJSDate(),
                t,
              )}
          </Paragraph>
        </div>

        {GetPills(visit)}
        <Paragraph size="xs" className="text-greyscale-700 hidden sm:block">
          {visitState === "active" &&
            `${start.toFormat("HH:mm")} - ${end.toFormat("HH:mm")}`}
          {isRecurring
            ? getHumanReadableRecurrence(isRecurring, watchStart?.toJSDate(), t)
            : ""}
        </Paragraph>
      </div>
    );
  };

  const GetRightSide = ({ visit }: { visit: ListVisit }) => {
    const { nameOrderFn } = useSortingOptions();

    const careRecipientName = nameOrderFn({
      firstName: visit.careRecipient.firstName,
      lastName: visit.careRecipient.lastName,
    });
    const careRecipientAddress = `${visit.careRecipient.address?.addressLine1}`;
    const hasVisitors = visit.visitors[0];
    const visitorName = nameOrderFn({
      firstName: visit.visitors[0]?.firstName ?? "",
      lastName: visit.visitors[0]?.lastName ?? "",
    });
    const isCancelled = visit.cancelledAt;

    return (
      <div className="w-full grid lg:grid-cols-2 xl:grid-cols-3 grid-cols-1 p-2 gap-3">
        <div className="col-span-1">
          <Label size="m">{careRecipientName}</Label>
          {visit.careRecipient.address && (
            <Paragraph size="xs">{careRecipientAddress}</Paragraph>
          )}
        </div>

        {hasVisitors ? (
          <div>
            <Label
              size="s"
              className={classNames(
                "text-grayscale-700 flex items-center gap-2",
                isCancelled ? "line-through" : "",
              )}
            >
              <Users></Users>
              {visitorName}
            </Label>
          </div>
        ) : (
          <div />
        )}
        <div className="flex xl:justify-end items-start gap-2 flex-wrap">
          {viewer?.tenantSettings.enableLabels &&
            visit.labels &&
            visit.labels.map((label) => {
              return (
                <SmallPill color="gray" text={label.name} key={label.id} />
              );
            })}
        </div>
      </div>
    );
  };

  return (
    <div className="flex">
      <div className="flex flex-col border-r-2 pt-2 lg:mt-2 items-center lg:min-w-16 min-w-10">
        <Headline size="l">{day}</Headline>
        <Label size="xs">{month}</Label>
        <Paragraph size="xs">{weekday}</Paragraph>
      </div>
      <div className="w-full">
        {visits.map((visit) => (
          <Link to={visitClick(visit.id)} key={visit.id} preventScrollReset>
            <Card
              className={classNames(
                "m-2 !p-4 grid lg:grid-cols-5 sm:grid-cols-3 grid-cols-1",
                visit.cancelledAt ? "!bg-greyscale-50" : "",
              )}
            >
              <div className="m-2 sm:border-r-2 sm:col-span-1 ">
                <GetLeftSide visit={visit}></GetLeftSide>
              </div>
              <div className="lg:col-span-4 sm:col-span-2">
                <GetRightSide visit={visit} />
              </div>
            </Card>
          </Link>
        ))}
      </div>
    </div>
  );
};

type ScheduleListProps = {
  listVisits: ListVisit[];
  loading: boolean;
  visitClick: (arg: string) => string;
};

export const ScheduleList = ({
  listVisits,
  loading,
  visitClick,
}: ScheduleListProps) => {
  const groupedVisits: Record<string, ListVisit[]> = {};

  if (loading && !listVisits.length) {
    return (
      <div className="flex">
        <div className="flex flex-col border-r-2 pt-2 lg:mt-2 items-center lg:min-w-16 min-w-10">
          <Headline size="l">
            <Skeleton height={32} width={24} />
          </Headline>
          <Label size="xs">
            <Skeleton height={20} width={24} />
          </Label>
          <Paragraph size="xs">
            <Skeleton height={20} width={24} />
          </Paragraph>
        </div>
        <div className="w-full">
          {Array.from({ length: 10 }).map((_, index) => (
            <Card key={index} className="m-2 !p-4 grid  grid-cols-1">
              <Skeleton />
            </Card>
          ))}
        </div>
      </div>
    );
  }
  listVisits.forEach((visit) => {
    const dt = DateTime.fromISO(visit.start);
    if (!dt.isValid) {
      return;
    }
    const key = dt.startOf("day").toISO();
    const arr = groupedVisits[key] ?? [];
    groupedVisits[key] = [...arr, visit];
  });

  return (
    <div className="flex flex-col gap-8">
      {Object.entries(groupedVisits).map(([dayStr, visits]) => (
        <ListDay
          key={dayStr}
          visits={visits}
          visitClick={visitClick}
          dayStr={dayStr}
        ></ListDay>
      ))}
    </div>
  );
};
