type Props = {
  children: React.ReactNode;
};

const TwoColumnWrapper = ({ children }: Props) => {
  return <div className="min-w-0 flex-1 gap-4 xl:flex">{children}</div>;
};

const TwoColumnMainArea = ({ children }: Props) => {
  return <div className="lg:min-w-0 lg:flex-1">{children}</div>;
};

export const TwoColumnLayout = {
  Wrapper: TwoColumnWrapper,
  MainArea: TwoColumnMainArea,
};

export const ThreeColumnLayout = ({ children }: Props) => {
  return (
    <div className="flex flex-col gap-4 2xl:grid 2xl:grid-cols-[minmax(auto,_18rem),minmax(30%,_60%),_minmax(auto,_14rem)]">
      {children}
    </div>
  );
};

export const CardColumn = ({ children }: Props) => {
  return (
    <div className="flex flex-row gap-4 pb-4 2xl:flex-shrink-0 2xl:flex-col 2xl:pb-0">
      {children}
    </div>
  );
};
