import {
  useCareRecipientCreateMutation,
  useCareRecipientUpdateMutation,
  useCareRecipientDeactivateMutation,
  useCareRecipientReactivateMutation,
} from "../../api/generated/graphql";

type BaseInput = {
  firstName: string;
  lastName: string;
  phone: string | null;
  homePhone: string | null;
  officeId: string;
};

type Update = BaseInput & {
  id: string;
};

type Create = BaseInput & { email: string | null };

type Status = {
  id: string;
  officeId: string;
};

export const useCareRecipientMutations = () => {
  const [createMutation] = useCareRecipientCreateMutation({
    refetchQueries: ["CareRecipients"],
  });

  const create = (input: Create) =>
    createMutation({
      variables: { input },
    });

  const [updateMutation] = useCareRecipientUpdateMutation({
    refetchQueries: ["CareRecipients"],
  });

  const update = (vals: Update) => {
    const { id, ...input } = vals;
    return updateMutation({
      variables: { input, id },
    });
  };

  const [deactivateMutation] = useCareRecipientDeactivateMutation({
    refetchQueries: ["CareRecipients"],
  });

  const deactivate = ({ id, officeId }: Status) =>
    deactivateMutation({
      variables: { id, officeId },
    });

  const [activateMutation] = useCareRecipientReactivateMutation({
    refetchQueries: ["CareRecipients"],
  });

  const reactivate = ({ id, officeId }: Status) =>
    activateMutation({
      variables: { id, officeId },
    });

  return { create, update, deactivate, reactivate };
};
