import { useTranslate } from "@tolgee/react";
import { useCareContext } from "../../../providers";
import { useEffect, useMemo } from "react";
import { ListBox } from "@frontend/lyng/forms/listBox/ListBox";
import { RoleType } from "../../../api/generated/graphql";
import { useSortingOptions } from "../../../utils/hooks/useSortingOptions";
import { match } from "ts-pattern";

// Only display offices where the user has one of the following roles
const allowedOfficeRoles = [
  RoleType.Admin,
  RoleType.SuccessManager,
  RoleType.SchedulingManager,
];

type Props = {
  selectedOfficeId: string | null;
  setSelectedOfficeId: (value: string | null) => void;
  showAllOption?: boolean;
};
export const OfficeFilter = ({
  selectedOfficeId,
  setSelectedOfficeId,
  showAllOption = true,
}: Props) => {
  const { t } = useTranslate();
  const { collator } = useSortingOptions();
  const {
    state: { viewer },
  } = useCareContext();

  const offices = useMemo(() => {
    return (
      viewer?.tenantAccess.offices
        .filter((office) => {
          return office.roles.some((role) =>
            allowedOfficeRoles.includes(role.roleType),
          );
        })
        .sort((a, b) => collator.compare(a.name, b.name)) ?? []
    );
  }, [viewer, collator]);

  useEffect(() => {
    if (!showAllOption && selectedOfficeId === null && offices.length > 0) {
      setSelectedOfficeId(offices[0].id);
    }
  }, [offices, selectedOfficeId, setSelectedOfficeId, showAllOption]);

  const allOfficesOption = { id: null, name: t("office.all") };

  const selectedOffice = match({ showAllOption, selectedOfficeId })
    .with(
      { showAllOption: true, selectedOfficeId: null },
      () => allOfficesOption,
    )
    .with(
      { showAllOption: false, selectedOfficeId: null },
      () => offices[0] ?? null,
    )
    .otherwise(
      () => offices.find((office) => office.id === selectedOfficeId) ?? null,
    );

  return (
    <ListBox
      options={showAllOption ? [allOfficesOption, ...offices] : offices}
      value={selectedOffice}
      getOptionLabel={(office) => office.name}
      getOptionKey={(office) => office.id ?? ""}
      onChange={(newValue) => {
        setSelectedOfficeId(newValue?.id ?? null);
      }}
    />
  );
};
