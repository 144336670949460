import {
  useAbsenceCreateForCareRecipientMutation,
  useAbsenceCreateForCaregiverMutation,
  useAbsenceUpdateForCareRecipientMutation,
  useAbsenceUpdateForCaregiverMutation,
  useAbsenceDeleteMutation,
} from "../../api/generated/graphql";

export const useAbsenceMutations = () => {
  const [createAbsenceForCaregiver] = useAbsenceCreateForCaregiverMutation({
    refetchQueries: ["EventsByCaregiverId"],
  });
  const [createAbsenceForCareRecipient] =
    useAbsenceCreateForCareRecipientMutation({
      refetchQueries: ["EventsByCareRecipientId"],
    });

  const [updateAbsenceForCaregiver] = useAbsenceUpdateForCaregiverMutation({
    refetchQueries: ["EventsByCaregiverId", "CareGiversTimeline"],
  });
  const [updateAbsenceForCareRecipient] =
    useAbsenceUpdateForCareRecipientMutation({
      refetchQueries: ["EventsByCareRecipientId", "CareRecipientsTimeline"],
    });
  const [deleteAbsence] = useAbsenceDeleteMutation({
    refetchQueries: [
      "EventsByCareRecipientId",
      "EventsByCaregiverId",
      "CareRecipientsTimeline",
      "CareGiversTimeline",
    ],
  });

  return {
    createAbsenceForCaregiver,
    createAbsenceForCareRecipient,
    updateAbsenceForCaregiver,
    updateAbsenceForCareRecipient,
    deleteAbsence,
  };
};
