import classNames from "classnames";

type FormTableHeaderProps = {
  children: React.ReactNode;
  gridCols: string;
  className?: string;
};
const FormTableHeader = ({
  children,
  gridCols,
  className,
}: FormTableHeaderProps) => {
  return (
    <div className={classNames("grid pb-3", gridCols, className)}>
      {children}
    </div>
  );
};
const FormTableBody = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={classNames("rounded-2xl bg-primary-100 px-3 py-2", className)}
    >
      {children}
    </div>
  );
};
type FormTableRowProps = {
  children: React.ReactNode;
  gridCols: string;
  className?: string;
};

const FormTableRow = ({ children, gridCols, className }: FormTableRowProps) => {
  return (
    <div
      className={classNames(
        "grid auto-rows-[minmax(56px,_auto)] border-primary-200 [&:not(:last-child)]:border-b",
        gridCols,
        className,
      )}
    >
      {children}
    </div>
  );
};

type FormTableCellProps = {
  children?: React.ReactNode;
  className?: string;
  borderRight?: boolean;
  justifyEnd?: boolean;
};
const FormTableCell = ({
  children,
  className,
  borderRight,
  justifyEnd,
}: FormTableCellProps) => {
  return (
    <div
      className={classNames("flex items-center border-primary-200", className, {
        "border-r": borderRight,
        "justify-end": justifyEnd,
      })}
    >
      {children}
    </div>
  );
};

export const FormTable = {
  Header: FormTableHeader,
  Body: FormTableBody,
  Row: FormTableRow,
  Cell: FormTableCell,
};
