type Group<T> = {
  label: string;
  options: T[];
};
export function GroupLabel<T>({ data }: { data: Group<T> }) {
  return (
    <div className="flex items-center justify-between font-normal">
      <span className="text-gray-400">{data.label}</span>
      <span className="inline-bloc rounded-full bg-gray-300 px-2 py-1 text-xs text-black">
        {data.options.length}
      </span>
    </div>
  );
}
