import classNames from "classnames";
import { match } from "ts-pattern";

export type Variant = "primary" | "success" | "warning" | "subdued" | "danger";
type Props = {
  className?: string;
  variant: Variant;
  text: string;
};
export const Badge = ({ variant, text, className }: Props) => {
  const [variantClasses, variantDotClasses] = match<Variant, [string, string]>(
    variant
  )
    .with("primary", () => [
      "bg-primary-100 text-primary-800",
      "fill-primary-500",
    ])
    .with("success", () => ["bg-green-100 text-green-800", "fill-green-500"])
    .with("warning", () => ["bg-yellow-100 text-yellow-800", "fill-yellow-500"])
    .with("subdued", () => ["bg-gray-100 text-gray-800", "fill-gray-500"])
    .with("danger", () => ["bg-red-100 text-red-800", "fill-red-500"])
    .exhaustive();
  return (
    <span
      className={classNames(
        "inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium",
        variantClasses,
        className
      )}
    >
      <svg
        className={classNames("h-1.5 w-1.5", variantDotClasses)}
        viewBox="0 0 6 6"
        aria-hidden="true"
      >
        <circle cx={3} cy={3} r={3} />
      </svg>
      {text}
    </span>
  );
};
