import {
  useCaregiverCreateMutation,
  useCaregiverUpdateMutation,
  useCaregiverDeactivateMutation,
  useCaregiverReactivateMutation,
} from "../../api/generated/graphql";

type BaseInput = {
  firstName: string;
  lastName: string;
  phone: string;
  homePhone: string | null;
  birthDate: string | null;
  pid: string | null;
  hireDate: string;
  officeId: string;
};

type Update = BaseInput & {
  id: string;
};

type Create = BaseInput & { email: string | null };

type Status = {
  id: string;
  officeId: string;
};

export const useCaregiverMutations = () => {
  const [createMutation] = useCaregiverCreateMutation({
    refetchQueries: ["CareGivers"],
  });

  const create = (input: Create) =>
    createMutation({
      variables: { input },
    });

  const [updateMutation] = useCaregiverUpdateMutation({
    refetchQueries: ["CareGivers"],
  });

  const update = (vals: Update) => {
    const { id, ...input } = vals;
    return updateMutation({
      variables: { input, id },
    });
  };

  const [deactivateMutation] = useCaregiverDeactivateMutation({
    refetchQueries: ["CareGivers"],
  });

  const deactivate = ({ id, officeId }: Status) =>
    deactivateMutation({
      variables: { id, officeId },
    });

  const [activateMutation] = useCaregiverReactivateMutation({
    refetchQueries: ["CareGivers"],
  });

  const reactivate = ({ id, officeId }: Status) =>
    activateMutation({
      variables: { id, officeId },
    });

  return { create, update, deactivate, reactivate };
};
