type Props = {
  children: React.ReactNode;
};
const FormGroup = ({ children }: Props) => {
  return (
    <div className="sm:grid sm:grid-cols-[1fr_2fr] sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
      {children}
    </div>
  );
};

const FormGroupStacked = ({ children }: Props) => {
  return <div className="flex flex-col gap-y-3">{children}</div>;
};

export { FormGroup, FormGroupStacked };
