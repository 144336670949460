/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DateTime } from "luxon";
import { useTranslate } from "@tolgee/react";
import { useNavigate, useParams, useRouteLoaderData } from "react-router-dom";
import { CareRecipientByIdQuery } from "../../api/generated/graphql";
import { VisitDetailForm } from "./VisitDetailsForm";
import { SlideOver } from "@frontend/lyng/slideOver";

const DEFAULT_DURATION = 60;

export const NewVisit = () => {
  const { timeSlug } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslate();

  const [start, end] = timeSlug!.split("--");

  const durationMinutes = end
    ? DateTime.fromISO(end).diff(DateTime.fromISO(start), "minutes").minutes
    : DEFAULT_DURATION;

  const careRecipient = (
    useRouteLoaderData("care-recipient-details") as CareRecipientByIdQuery
  ).careRecipientById;

  if (!careRecipient) {
    return null;
  }
  const newVisit = {
    careRecipient: careRecipient,
    start: start,
    durationMinutes: durationMinutes,
    visitors: [],
    recurrences: null,
    recurrenceEnd: null,
    exceptionId: null,
    residentialCare: null,
    visitType: null,
    cancelledAt: null,
    cancelledReason: null,
    labelIds: [],
  };

  const title =
    DateTime.fromISO(start) > DateTime.now()
      ? t("visitDetails.newVisit").toString()
      : t("visitDetails.newVisitLog").toString();
  return (
    <SlideOver.WithIntercomHider show onClose={() => navigate("..")}>
      <SlideOver.Title>{title}</SlideOver.Title>
      <VisitDetailForm visit={newVisit} onClose={() => navigate("..")} />
    </SlideOver.WithIntercomHider>
  );
};
