import classNames from "classnames";

export type ListCheckboxesItem = {
  key: string;
  label: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};
type Props = {
  id?: string;
  items: ListCheckboxesItem[];
};
export const ListCheckBoxes = ({ id, items }: Props) => {
  return (
    <div id={id} className="flex flex-col gap-1">
      {items.map(({ key, label, checked, onChange }) => (
        <label
          key={`${id}-${key}`}
          className={classNames(
            { "bg-primary-100": checked },
            "text-grey-900 flex cursor-pointer items-center gap-3 rounded-lg p-[11px] text-base font-medium"
          )}
        >
          <input
            type="checkbox"
            className={
              "h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
            }
            checked={checked}
            onChange={onChange}
          />
          <span className="">{label}</span>
        </label>
      ))}
    </div>
  );
};
