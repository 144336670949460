import { useTranslate } from "@tolgee/react";
import { z } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-hot-toast";

import { Form, Input } from "../../../components/formfields";
import { Button, SlideOver } from "../../../components/common";
import { FieldLabel } from "../../../pages/careRecipientDetails/CareRecipientProfileFormModal";
import { errorToToastMessage } from "../../../utils/toastUtils";
import {
  OrganizationDocument,
  useOrganizationUpdateMutation,
} from "../../../api/generated/graphql";

const validationSchema = z.object({
  name: z.string().min(1),
});

type FormInput = z.infer<typeof validationSchema>;
export type Organization = FormInput;

type Props = {
  organization: Organization;
  onClose: () => void;
};

export const OrganizationFormModal = ({ organization, onClose }: Props) => {
  const { t } = useTranslate();

  const {
    register,
    handleSubmit,
    formState: { isValid, isDirty, isLoading },
  } = useForm<FormInput>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      name: organization.name,
    },
  });

  const [organizationUpdateMutation] = useOrganizationUpdateMutation({
    refetchQueries: [OrganizationDocument],
  });
  const onSubmit: SubmitHandler<FormInput> = (value: FormInput) => {
    const updatePromise = organizationUpdateMutation({
      variables: {
        input: {
          name: value.name,
        },
      },
    }).then(() => {
      onClose();
    });

    toast.promise(updatePromise, {
      loading: t("organization.updating"),
      success: t("organization.updated"),
      error: (error) => errorToToastMessage(error),
    });
  };

  return (
    <SlideOver
      title={`${t("edit").toString()} ${t("organization.label").toString()}`}
      onClose={onClose}
    >
      <Form className="mt-10" onSubmit={handleSubmit(onSubmit)}>
        <Form.Body>
          <FieldLabel htmlFor="organization.name">
            {t("organization.name")}
          </FieldLabel>
          <Input {...register("name")} id="organization.name" type="text" />
        </Form.Body>
        <Form.Footer>
          <Button
            variant="secondary"
            text={t("cancel").toString()}
            onClick={() => {
              onClose();
            }}
          />
          <Button
            variant="primary"
            text={t("save").toString()}
            type="submit"
            disabled={!isValid || !isDirty || isLoading}
          />
        </Form.Footer>
      </Form>
    </SlideOver>
  );
};
