import { CancelledReason } from "../../../api/generated/graphql";
import { Button, Modal } from "../../../components/common";
import {
  CancelledReasonSelect,
  FormGroupStacked,
} from "../../../components/formfields";
import { Label, Paragraph } from "@frontend/lyng/typography";
import { zodResolver } from "@hookform/resolvers/zod";
import { DateTime } from "luxon";
import { Controller, useForm } from "react-hook-form";
import { useTranslate } from "@tolgee/react";
import { z } from "zod";
import { DatePicker } from "@frontend/lyng/forms";
import { useCareContext } from "../../../providers";

const schema = z.object({
  reason: z.nativeEnum(CancelledReason),
  cancelledOnDate: z.custom<DateTime>(
    (value) => DateTime.isDateTime(value),
    "Invalid date  format.",
  ),
});

type FormValues = z.infer<typeof schema>;

type Props = {
  show: boolean;
  onConfirm: (values: {
    cancelledAt: DateTime<true>;
    reason: CancelledReason;
  }) => void;
  onCancel: () => void;
};
export const CancelVisitModal = ({ show, onConfirm, onCancel }: Props) => {
  const { t } = useTranslate();
  const {
    state: { viewer },
  } = useCareContext();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      cancelledOnDate: DateTime.now(),
    },
  });

  const onSubmit = (data: FormValues) => {
    onConfirm({
      cancelledAt: data.cancelledOnDate.isValid
        ? data.cancelledOnDate
        : DateTime.now(),
      reason: data.reason,
    });
  };

  return (
    <Modal show={show} onClose={onCancel}>
      <Modal.Title>{t("visitDetails.cancelVisit")}</Modal.Title>
      <form className="flex flex-col gap-8" onSubmit={handleSubmit(onSubmit)}>
        <Paragraph size="m" className="text-center">
          {t("visitDetails.cancelVisitMessage")}
        </Paragraph>

        <FormGroupStacked>
          <Label id="reason-label">
            {t("visitDetails.cancelVisitAddReasonLabel")}
          </Label>
          <Controller
            control={control}
            name="reason"
            render={({ field }) => (
              <CancelledReasonSelect
                id="reason"
                value={field.value}
                name={field.name}
                onChange={field.onChange}
                onBlur={field.onBlur}
                invalid={!!errors.reason}
                aria-labelledby="reason-label"
              />
            )}
          />
        </FormGroupStacked>

        <FormGroupStacked>
          <Label id="cancelledOnDate-label">
            {t("visitDetails.cancelVisitDateLabel")}
          </Label>
          <Controller
            control={control}
            name="cancelledOnDate"
            render={({ field }) => (
              <DatePicker
                // TODO: Remove !max-w-none when design overhaul of formfields is done
                dateSettings={viewer?.tenantSettings}
                className="!max-w-none"
                aria-labelledby="cancelledOnDate-label"
                showCalendar="noCalendar"
                withTime
                name={field.name}
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value}
                isInvalid={!!errors.cancelledOnDate}
              />
            )}
          />
        </FormGroupStacked>

        <Modal.Footer>
          <Button
            variant="secondary"
            text={t("visitDetails.cancelVisitCancel") ?? ""}
            onClick={onCancel}
          />
          <Button
            type="submit"
            variant="primary"
            text={t("visitDetails.cancelVisitConfirm") ?? ""}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};
