import classNames from "classnames";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { forwardRef, HTMLInputTypeAttribute } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

type Props = {
  id?: string;
  className?: string;
  type: HTMLInputTypeAttribute;
  value?: string | number | undefined;
  readOnly?: boolean;
  editView?: boolean;
  invalid?: boolean;
  placeholder?: string;
  autoComplete?: string;
  onClick?: () => void;
};

const Input = forwardRef<
  HTMLInputElement,
  Props & UseFormRegisterReturn<string>
>(function Input(
  {
    type,
    onChange,
    onBlur,
    name,
    className,
    id,
    value,
    disabled,
    readOnly,
    editView = true,
    invalid,
    autoComplete,
    placeholder,
    onClick,
    min,
    ...props
  },
  ref
) {
  return (
    <div
      className={classNames(
        "relative mx-1 w-full max-w-lg rounded-xl sm:max-w-xs",
        {
          "shadow-sm": editView,
        },
        className
      )}
    >
      <input
        {...props}
        ref={ref}
        id={id}
        className={classNames(
          "flex h-12 w-full items-center rounded-xl border border-gray-300 px-4  transition-colors",
          {
            "border-gray-300 focus:border-primary-500 focus:ring-primary-500":
              !invalid && editView,
            "border-red-300 pr-10 text-red-900 placeholder-red-300 focus-within:border-red-500 focus-within:outline-none focus-within:ring-red-500":
              invalid && editView,
            "border-transparent bg-transparent placeholder-greyscale-900 hover:border-transparent":
              !editView,
            "bg-white": editView,
          }
        )}
        type={type}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        min={min}
        value={value}
        disabled={disabled || !editView}
        readOnly={readOnly}
        placeholder={placeholder}
        autoComplete={autoComplete}
        onClick={onClick}
        aria-invalid={invalid ? "true" : "false"}
      />
      {invalid && (
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <ExclamationCircleIcon
            className="h-5 w-5 text-red-500"
            aria-hidden="true"
          />
        </div>
      )}
    </div>
  );
});

export default Input;
